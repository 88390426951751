import { Button, ButtonVariant, TextField } from "@supplyon/ui-components";
import React, { Component } from "react";
import Color from "../../libraries/SupplyOnUiConstants";
import NotificationBackground from "../NotificationBackground";
import QuestionnaireService from "../../services/QuestionnaireService";
import { Save, Cancel } from "@material-ui/icons/";

class QuestionnaireEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsRaised: false,
      IsNew: true,
      Questionnaire: null,
      HasChanges: false,
    };
  }

  Raise = (questionnaire, isNew) => {
    this.setState({ IsRaised: true, IsNew: isNew, Questionnaire: questionnaire }, () => {
      this.NotificationBackground.Raise();
    });
  };

  Collapse = () => {
    this.setState({ Questionnaire: null, HasChanges: false, IsNew: true, IsRaised: false });      
  };

  OnChangeTitle = (newValue) => {
    let questionnaire = this.state.Questionnaire;
    questionnaire.title = newValue;
    this.setState({ Questionnaire: questionnaire, HasChanges: true });
  };

  OnChangeExternalQuestionnaireId = (newValue) => {
    let questionnaire = this.state.Questionnaire;
    questionnaire.externalQuestionnaireId = newValue;
    this.setState({ Questionnaire: questionnaire, HasChanges: true });
  };

  OnSaveQuestionnaire = () => {
    if (this.state.HasChanges) {
      if (this.state.IsNew) {
        QuestionnaireService.SaveQuestionnaire(this.state.Questionnaire).then(this.props.OnSave);
      } else {
        QuestionnaireService.UpdateQuestionnaire(this.state.Questionnaire).then(this.props.OnSave);
      }
    }
    else{
      this.props.OnCancel();
    }
  };

  IsFormFilled = () => {
    const questionnaire = this.state.Questionnaire;
    return questionnaire.title?.length > 0 && questionnaire.externalQuestionnaireId?.length > 0 && !isNaN(questionnaire.externalQuestionnaireId)? true : false;
  }

  render = () => {
    return (
      this.state.IsRaised && (
        <>
          <NotificationBackground
            ref={(notificationBackground) => {
              this.NotificationBackground = notificationBackground;
            }}
          ></NotificationBackground>
          <div className="QuestionnairesFormEdit_Node">
            <div className="QuestionnairesFormEdit_Header">
              {!this.state.IsNew && <label className="QuestionnairesFormEdit_HeaderLabel">Edit Questionnaire</label>}
              {this.state.IsNew && <label className="QuestionnairesFormEdit_HeaderLabel">Create Questionnaire</label>}
            </div>
            <div className="QuestionnairesFormEdit_Body">
              <div className="QuestionnairesFormEdit_TextField">
                <TextField key="title" label="Title" onChange={(newValue) => this.OnChangeTitle(newValue)} placeholder="Enter Title" value={this.state.Questionnaire.title} />
              </div>
              <div className="QuestionnairesFormEdit_TextField">
                <TextField key="externalQuestionnaireId" label="Questionnaire ID" onChange={(newValue) => this.OnChangeExternalQuestionnaireId(newValue)} placeholder="Enter Questionnaire ID" value={this.state.Questionnaire.externalQuestionnaireId} />
              </div>
            </div>
            <div className="QuestionnairesFormEdit_Footer">
              <div className="QuestionnairesFormEdit_FooterButtonBar">
                <Button onClick={this.props.OnCancel} className="QuestionnairesFormEdit_FooterCancelButton" variant={ButtonVariant.Page}>
                  <Cancel className="QuestionnairesFormEdit_CancelIcon" />
                  Cancel
                </Button>
                <Button onClick={this.OnSaveQuestionnaire} className="QuestionnairesFormEdit_FooterSaveButton" variant={ButtonVariant.PageHighlight} disabled={!this.IsFormFilled()}>
                  <Save className="QuestionnairesFormEdit_SaveIcon" />
                  Save
                </Button>
              </div>
            </div>
          </div>
          <style jsx>
            {`
              .QuestionnairesFormEdit_FooterCancelButton {
                margin-left: 25px;
              }
              .QuestionnairesFormEdit_FooterSaveButton {
                float: right;
                margin-right: 25px;
              }
              .QuestionnairesFormEdit_FooterButtonBar {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .QuestionnairesFormEdit_CancelIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .QuestionnairesFormEdit_SaveIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .QuestionnairesFormEdit_Body {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 70px;
                line-height: 70px;
                padding-top: 30px;
              }
              .QuestionnairesFormEdit_Node {
                width: 40%;
                height: 330px;
                background-color: white;
                position: absolute;
                border: 1px solid grey;
                border-radius: 5px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50);
                z-index: 99;
              }
              .QuestionnairesFormEdit_HeaderLabel {
                font-size: 18px;
                font-weight: bold;
                margin-left: 25px;
              }
              .QuestionnairesFormEdit_TextField {
                margin-left: 25px;
                margin-right: 25px;
              }
              .QuestionnairesFormEdit_Header {
                border-bottom: 1px solid ${Color.Grey3};
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 68px;
                line-height: 70px;
              }
              .QuestionnairesFormEdit_Footer {
                border-top: 1px solid ${Color.Grey3};
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 64px;
              }
            `}
          </style>
        </>
      )
    );
  };
}
export default QuestionnaireEditForm;
