import { Add, FilterList, Layers, LocationOn, MoreHoriz, TouchApp } from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import { BreadcrumbNavigation, Button, ButtonVariant, DropdownButton, breadcrumb } from "@supplyon/ui-components";
import { BsCircle, BsCircleFill, BsPentagon, BsPentagonFill } from "react-icons/bs";
import { Link, ListItemIcon, ListItemText, MenuItem, Tooltip } from "@material-ui/core";
import React, { Component } from "react";
import { MdFileUpload } from "react-icons/md";
import Color from "../libraries/SupplyOnUiConstants";
import MenuBarSearchBox from "./MenuBarSearchBox";
import RiskManagementConstants from "../libraries/RiskManagementConstants";
import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";
import FeatureConstants from "../libraries/FeatureConstants";

/*  Props: 
        events:
 
            RaiseEvent: raises event for the context menu buttons.
*/

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: Color.Grey5,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: Color.Grey5,
  },
}))(Tooltip);

class MenuBar extends Component {
  render = () => {
    return (
      <>
        <div className="MenuBar_NodeContainer">
          <div className="MenuBar_Navigation">
            <BreadcrumbNavigation
              breadcrumbs={[
                breadcrumb({
                  label: "SupplyOn",
                  Link,
                  LinkProps: {
                    href: "https://www.supplyon.com",
                  },
                }),
                breadcrumb("Strategic Risk Management", Link, {
                  href: "/",
                }),
              ]}
            />
          </div>
          {(RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner) && <div className="MenuBar_SearchBox">{typeof google !== "undefined" && <MenuBarSearchBox OnPlaceChanged={this.props.OnPlaceChangedSearchbox}></MenuBarSearchBox>}</div>}
          <div className="MenuBar_ButtonBar">
            {(RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_CategoryManager) && (
              <div className="MenuBar_Button">
                <DropdownButton label="Select" startIcon={<TouchApp></TouchApp>} variant={ButtonVariant.Contextual}>
                  {" "}
                  <MenuItem
                    onClick={() => {
                      this.props.RaiseEvent(RiskManagementConstants.SelectPolygonSML);
                    }}
                  >
                    <ListItemIcon>
                      <BsPentagon></BsPentagon>
                    </ListItemIcon>
                    <ListItemText primary="Polygonal area" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      this.props.RaiseEvent(RiskManagementConstants.SelectCircleSML);
                    }}
                  >
                    <ListItemIcon>
                      <BsCircle></BsCircle>
                    </ListItemIcon>
                    <ListItemText primary="Circular area" />
                  </MenuItem>
                </DropdownButton>
              </div>
            )}
            {(RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner) && (
              <div className="MenuBar_Button">
                <DropdownButton label="Add" startIcon={<Add></Add>} variant={ButtonVariant.Contextual}>
                  <>
                    <MenuItem
                      onClick={() => {
                        this.props.RaiseEvent(RiskManagementConstants.SupplierManufacturingLocationNew);
                      }}
                    >
                      <ListItemIcon>
                        <LocationOn></LocationOn>
                      </ListItemIcon>
                      <ListItemText primary="Location" />
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        this.props.RaiseEvent(RiskManagementConstants.UploadSupplierManufacturingLocationFile);
                      }}
                    >
                      <ListItemIcon>
                        <MdFileUpload style={{ width: "20px", height: "20px" }}></MdFileUpload>
                      </ListItemIcon>
                      <ListItemText primary="Upload locations" />
                    </MenuItem>
                    <div className="MenuBar_ContextMenu_Separator"></div>
                  </>

                  <>
                    <MenuItem
                      onClick={() => {
                        this.props.RaiseEvent(RiskManagementConstants.CustomRiskLayerNewPolygon);
                      }}
                    >
                      <ListItemIcon>
                        <BsPentagonFill></BsPentagonFill>
                      </ListItemIcon>
                      <ListItemText primary="Polygonal risk layer" />
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        this.props.RaiseEvent(RiskManagementConstants.CustomRiskLayerNewCircle);
                      }}
                    >
                      <ListItemIcon>
                        <BsCircleFill></BsCircleFill>
                      </ListItemIcon>
                      <ListItemText primary="Circle risk layer" />
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        this.props.RaiseEvent(RiskManagementConstants.UploadRiskLayerFile);
                      }}
                    >
                      <ListItemIcon>
                        <MdFileUpload style={{ width: "20px", height: "20px" }}></MdFileUpload>
                      </ListItemIcon>
                      <ListItemText primary="Upload risk layers" />
                    </MenuItem>
                  </>
                </DropdownButton>
              </div>
            )}
            {(RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_Visitor || RiskManagementKeycloak.Has_Role_CategoryManager) && (
              <div className="MenuBar_Button">
                <Button
                  style={{ color: this.props.HighlightFilterIcon ? Color.Blue1 : "" }}
                  startIcon={<FilterList></FilterList>}
                  variant={ButtonVariant.Contextual}
                  onClick={() => {
                    this.props.RaiseEvent(RiskManagementConstants.FilterContextMenu);
                  }}
                >
                  Filter
                </Button>
              </div>
            )}
            <div className="MenuBar_Button">
              <Button
                startIcon={<Layers></Layers>}
                variant={ButtonVariant.Contextual}
                onClick={() => {
                  this.props.RaiseEvent(RiskManagementConstants.ViewContextMenu);
                }}
              >
                View
              </Button>
            </div>
            {(RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner) && (
              <div className="MenuBar_Button">
                <DropdownButton label="More" startIcon={<MoreHoriz></MoreHoriz>} variant={ButtonVariant.Contextual}>
                  <MenuItem
                    onClick={() => {
                      this.props.RaiseEvent(RiskManagementConstants.UploadedSupplierManufacturingFileList);
                    }}
                  >
                    <ListItemText primary="View uploaded files" />
                  </MenuItem>
                  {RiskManagementKeycloak.Has_Role_RiskManager && (
                    <LightTooltip title={this.props.OngoingUpdates.length !== 0 ? "Update ongoing" : ""} arrow>
                      <span>
                        <MenuItem
                          disabled={this.props.OngoingUpdates.length !== 0}
                          onClick={() => {
                            this.props.RaiseEvent(RiskManagementConstants.RetrieveRiskData);
                          }}
                        >
                          <ListItemText primary="Initiate revaluation" />
                        </MenuItem>
                      </span>
                    </LightTooltip>
                  )}
                  {RiskManagementKeycloak.Has_Role_RiskManager && (
                    <MenuItem
                      onClick={() => {
                        this.props.RaiseEvent(RiskManagementConstants.RiskDataProviderRequestCount);
                      }}
                    >
                      <ListItemText primary="Show count of data provider request" />
                    </MenuItem>
                  )}
                  {RiskManagementKeycloak.Has_Role_RiskManager && this.props.EnabledFeatures.find((x) => x === FeatureConstants.Survey) && (
                    <MenuItem
                      onClick={() => {
                        this.props.RaiseEvent(RiskManagementConstants.SurveyOverview);
                      }}
                    >
                      <ListItemText primary="Survey Overview" />
                    </MenuItem>
                  )}
                  {RiskManagementKeycloak.Has_Role_RiskManager && this.props.EnabledFeatures.find((x) => x === FeatureConstants.Survey) && (
                    <MenuItem
                      onClick={() => {
                        this.props.RaiseEvent(RiskManagementConstants.ManageQuestionnairies);
                      }}
                    >
                      <ListItemText primary="Manage Questionnaries" />
                    </MenuItem>
                  )}
                </DropdownButton>
              </div>
            )}
          </div>
        </div>
        <style jsx>
          {`
            .MenuBar_NavigationLabel {
              margin-right: 10px;
              font-size: 12px;
            }
            .MenuBar_Navigation {
              position: absolute;
              left: 40px;
              top: 50%;
              transform: translateY(-50%);
              -ms-transform: translateY(-50%);
            }
            .MenuBar_ContextMenu_Separator {
              border-bottom: 1px solid ${Color.Grey3};
              width: 85%;
              left: 50%;
              transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              position: relative;
              margin-bottom: 8px;
              margin-top: 8px;
            }
            .MenuBar_MenuBar {
              top: 51px;
              position: absolute;
              transform: translateX(-25%);
              -ms-transform: translateX(-25%);
            }
            .MenuBar_ButtonWithContext {
            }
            .MenuBar_ButtonBar {
              width: 400px;
              display: flex;
              justify-content: flex-end;
              flex-wrap: wrap;
              height: 100%;
              float: right;
              margin-right: 20px;
            }
            .MenuBar_NodeContainer {
              width: 100%;
              height: 52px;
              position: absolute;
              z-index: 50;
              box-shadow: 0px 0px 2px ${Color.Grey4};
            }
            .MenuBar_Button {
              width: 80px;
              height: 30px;
              top: 50%;
              transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              position: relative;
            }
            .MenuBar_Button_Tooltip {
              backgroundColor: theme.palette.common.white,
              color: 'rgba(0, 0, 0, 0.87)',
              boxShadow: theme.shadows[1],
              fontSize: 11,
            }
            .MenuBar_SearchBox {
              width: 400px;
              height: 30px;
              top: 50%;
              transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              position: absolute;
              right: 444px;
            }
          `}
        </style>
      </>
    );
  };
}

export default MenuBar;
