import XLSX from "xlsx";

export function DownloadSmlsAsExcel(selectedSmls) {
  /* add to workbook */
  let smlData = [];
  let subRisks = [];
  let gpn = [];

  selectedSmls.forEach((sml) => {
    //worksheet GPN
    const gpnForSml = sml.supplyRelations?.map((supRelation) => {
      return {
        SML_ID: sml.supplierManufacturingLocationKey,
        Supplier_Key: supRelation.supplierKey,
        Mat: supRelation.materialKey,
      };
    });
    gpn.push(...gpnForSml);

    // worksheet SubRisks
    const smlRisks = sml.supplierManufacturingLocationRisks?.map((smlRisk) => {
      const colorCoding = smlRisk.riskCategory?.riskCategoryColorCodingThresholdModels?.find((obj) => obj.upperThreshold >= smlRisk.score)?.colorCoding?.colorCodingKey;
      return {
        "SML Name": sml.name,
        "SML Id": sml.supplierManufacturingLocationKey,
        "Internal Supplier id": "", // not in Sml at the moment
        "Risk Category": smlRisk.riskCategory?.name,
        "Risk Score": smlRisk.score,
        "Traffic light color": colorCoding,
      };
    });

    subRisks.push(...smlRisks);

    //worksheet SML
    const smlRow = {
      "SML Name": sml.name,
      "SML Id": sml.supplierManufacturingLocationKey,
      "Lifecycle Status": sml.lifecycleStatus,
      "Material Quantity": sml.materialQuantity,
      Country: sml.country,
      "Region Id": sml.regionId,
      "Region Name": sml.regionName,
      Zip: sml.zip,
      City: sml.city,
      Street: sml.street,
      Latitude: sml.latitude,
      Longitude: sml.longitude,
      "Mitigated risk color key": sml.mitigatedRiskColorCodingKey,
      "Supplier Id": sml.supplier ? sml.supplier.supplierKey : "",
      "Supplier Name": sml.supplier ? sml.supplier.name : "",
    };

    smlData.push(smlRow);

    if (sml.supplyRelations?.length > 0) {
      const supplyRelations = sml.supplyRelations.filter((obj, index, arr) => index === arr.findIndex((supplyRelation) => supplyRelation.supplierKey === obj.supplierKey && supplyRelation.supplierKey !== smlRow["Supplier Id"]));

      // create sml row in sml sheet
      supplyRelations.forEach((supplyRelation) => {
        let smlWithSupplier = Object.assign({}, smlRow);
        smlWithSupplier["Supplier Id"] = supplyRelation.supplierKey;
        smlWithSupplier["Supplier Name"] = supplyRelation.supplier?.name;
        smlData.push(smlWithSupplier);
      });
    }
  });

  if (subRisks.length === 0) {
    subRisks.push({ "SML Name": "", "SML Id": "", "Internal Supplier id": "", "Risk Name": "", "Risk Score": "", "Traffic light color": "" });
  }

  if (gpn.length === 0) {
    gpn.push({ "SML Id": "", "Supplier Key": "", Mat: "" });
  }

  const worksheetSml = XLSX.utils.json_to_sheet(smlData);
  const worksheetSubrisks = XLSX.utils.json_to_sheet(subRisks);
  const worksheetGpn = XLSX.utils.json_to_sheet(gpn);
  const wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, worksheetSml, "SML");
  XLSX.utils.book_append_sheet(wb, worksheetSubrisks, "SubRisks");
  XLSX.utils.book_append_sheet(wb, worksheetGpn, "GPN");

  /* generate an XLSX file */
  const currentDate = new Date().toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" });
  XLSX.writeFile(wb, `SupplierManufacturingLocations_${currentDate}.xlsx`);
}

const removeKey = (key, { [key]: _, ...rest }) => rest;

export function DownloadRiskLayerJson(selectedRiskLayers) {
  const currentDate = new Date().toLocaleTimeString("en-US", { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" });
  const fileName = `RiskLayers_${currentDate}.json`;

  // Remove some object keys
  selectedRiskLayers = selectedRiskLayers.map((riskLayer) => {
    let { IsVisible, isFocused, belongsToUserId, customRiskLayerId, riskCategory, ...newRiskLayer } = riskLayer;

    newRiskLayer.coordinates = newRiskLayer.coordinates.map((coord) => {
      return removeKey("coordinateId", coord);
    });
    return newRiskLayer;
  });

  // convert to json
  const json = JSON.stringify(selectedRiskLayers);

  // Download json file;
  const blob = new Blob([json], { type: "application/json" });
  const href = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.download = fileName;
  link.href = href;
  link.click();
  link.remove();
}
