import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";

class CdmSmaService {
  static GetAllCdmSmas = () => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/cdmsma", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
      }).then((response) => response.json());
    });
  };
}

export default CdmSmaService;
