import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";

class CustomRiskLayerService {
  static GetAllCustomRiskLayer = () => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/CustomRiskLayer", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
      }).then((response) => response.json());
    });
  };

  static GetCustomRiskLayerById = (customRiskLayerId) => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/CustomRiskLayer/" + customRiskLayerId, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
      }).then((response) => response.json());
    });
  };

  static SaveCustomRiskLayer = (customRiskLayer) => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/CustomRiskLayer", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
        body: JSON.stringify(customRiskLayer),
      }).then((response) => response.json());
    });
  };

  static SaveCustomRiskLayerList = (customRiskLayerList) => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/CustomRiskLayer/List", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
        body: JSON.stringify(customRiskLayerList),
      }).then((response) => response.json());
    });
  };

  static DeleteCustomRiskLayer = (customRiskLayerIds) => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/CustomRiskLayer", {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
        body: JSON.stringify(customRiskLayerIds),
      }).then((response) => response.json());
    });
  };
}
export default CustomRiskLayerService;
