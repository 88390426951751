class RiskManagementConstants {
  static CustomRiskLayerNewPolygon = "CustomRiskLayerNewPolygon";
  static CustomRiskLayerNewCircle = "CustomRiskLayerNewCircle";
  static ERROR_Message = "ERROR_Message";
  static SupplierManufacturingLocationNew = "SupplierManufacturingLocationNew";
  static UploadSupplierManufacturingLocationFile = "UploadSupplierManufacturingLocationFile";
  static UploadRiskLayerFile = "UploadRiskLayerFile";
  static FilterContextMenu = "FilterContextMenu";
  static ViewContextMenu = "ViewContextMenu";
  static UploadedSupplierManufacturingFileList = "UploadedSupplierManufacturingFileList";
  static SelectPolygonSML = "SelectPolygonSML";
  static SelectCircleSML = "SelectCircleSML";
  static RetrieveRiskData = "RetrieveRiskData";
  static RiskDataProviderRequestCount = "RiskDataProviderRequestCount";
  static CustomRiskLayerList = "CustomRiskLayerList";
  static ViewQuestionnairies = "ViewQuestionnairies";
  static ManageQuestionnairies = "ManageQuestionnairies";
  static CreateSurvey = "CreateSurvey";
  static SurveyOverview = "SurveyOverview";
}
export default RiskManagementConstants;
