import { Card, CardContent, CardHeader, Collapse, Divider } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { IconButton, TextArea, TextField } from "@supplyon/ui-components";
import React, { Component } from "react";
import Color from "../libraries/SupplyOnUiConstants";

/*  Props: 
        events:
 
            OnChange:  raised when an information gets changed

        properties:

            SupplierManufacturingLocation: the Supplier Manufacturing Location object
            IsEditable: marks if the Supplier Manufacturing Location is editable (depends if an user is related to this SML -> belongsToUserID !== null)
*/

class SupplierManufacturingLocationDataEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LocationTypes: [],
    };
  }

  componentDidMount = () => {
    let locationTypes = [];
    if (this.props.SupplierManufacturingLocation.locationTypes) {
      for (let i = 0; i < this.props.SupplierManufacturingLocation.locationTypes.length; i++) {
        let item = this.props.SupplierManufacturingLocation.locationTypes[i];
        item.isRaised = false;
        locationTypes.push(item);
      }
    }

    this.setState({ LocationTypes: locationTypes });
  };

  OnBlurAddress = (event) => {
    const { name, value } = event.target;
    let item = this.props.SupplierManufacturingLocation;
    item[name] = value;

    let coordinatesChanged = false;
    let addressChanged = true;
    this.props.OnBlur(item, coordinatesChanged, addressChanged);
  };

  OnBlur = (event) => {
    const { name, value } = event.target;
    let item = this.props.SupplierManufacturingLocation;
    item[name] = value;

    let coordinatesChanged = false;
    let addressChanged = false;
    this.props.OnBlur(item, coordinatesChanged, addressChanged);
  };

  OnChange = (_, event) => {
    const { name, value } = event.target;
    let item = this.props.SupplierManufacturingLocation;
    item[name] = value;
    this.props.OnChange(item);
  };

  OnChangeCoordinates = (_, event) => {
    let { name, value } = event.target;
    if (value.slice(-1) !== ".") {
      value = parseFloat(value);
    }

    let item = this.props.SupplierManufacturingLocation;
    item[name] = value;

    const coordinatesChanged = true;
    const addressChanged = false;
    this.props.OnBlur(item, coordinatesChanged, addressChanged);
  };

  LocationTypeOnClick = (itemIndex) => {
    let item = Object.assign({}, this.state.LocationTypes[itemIndex]);
    let locationTypes = [...this.state.LocationTypes];
    item.isRaised = !item.isRaised;
    locationTypes[itemIndex] = item;
    this.setState({ LocationTypes: locationTypes });
  };

  render = () => {
    return (
      <>
        <div className="SupplierManufacturingLocationDataEdit_Node">
          <div className="SupplierManufacturingLocationDataEdit_GeneralDataContainer">
            <div className="SupplierManufacturingLocationDataEdit_GeneralDataContainerFirstRow">
              <TextField className="SupplierManufacturingLocationDataEdit_Textbox" label="Name" name="name" required={true} onChange={this.OnChange} onBlur={this.OnBlur} value={this.props.SupplierManufacturingLocation.name} disabled={!this.props.IsEditable} />
              <TextField className="SupplierManufacturingLocationDataEdit_Textbox" label="SML id" name="supplierManufacturingLocationKey" required={true} onChange={this.OnChange} onBlur={this.OnBlur} value={this.props.SupplierManufacturingLocation.supplierManufacturingLocationKey} disabled={!this.props.IsEditable} />
            </div>

            {!this.props.IsEditable && (
              <div className="SupplierManufacturingLocationDataEdit_GeneralDataContainerRow">
                <TextField className="SupplierManufacturingLocationDataEdit_Textbox" label="Lifecycle status" name="lifecycleStatus" required={true} onChange={this.OnChange} value={this.props.SupplierManufacturingLocation.lifecycleStatus} disabled={!this.props.IsEditable} />
                <TextField className="SupplierManufacturingLocationDataEdit_Textbox" label="Material quantity" name="materialQuantity" required={true} onChange={this.OnChange} value={this.props.SupplierManufacturingLocation.materialQuantity} disabled={!this.props.IsEditable} />
              </div>
            )}
            {!this.props.IsEditable && (
              <div className="SupplierManufacturingLocationDataEdit_GeneralDataContainerRow">
                <TextField className="SupplierManufacturingLocationDataEdit_Textbox" label="To be deleted" name="toBeDeletedFlag" required={true} onChange={this.OnChange} value={this.props.SupplierManufacturingLocation.toBeDeletedFlag} disabled={!this.props.IsEditable} />
                <TextField className="SupplierManufacturingLocationDataEdit_Textbox" label="Mitigated risk color key" name="mitigatedRiskColorCodingKey" required={true} onChange={this.OnChange} value={this.props.SupplierManufacturingLocation.mitigatedRiskColorCodingKey} disabled={!this.props.IsEditable} />
              </div>
            )}
          </div>
          <div className="SupplierManufacturingLocationDataEdit_AddressDataContainer">
            <label className="SupplierManufacturingLocationDataEdit_HeadLabel">Address</label>
            <div className="SupplierManufacturingLocationDataEdit_GeneralDataContainerFirstRow">
              <TextField className="SupplierManufacturingLocationDataEdit_TextboxFullSize" label="Street and housenumber" name="street" required={true} onChange={this.OnChange} onBlur={this.OnBlurAddress} value={this.props.SupplierManufacturingLocation.street} disabled={!this.props.IsEditable} />
            </div>
            <div className="SupplierManufacturingLocationDataEdit_GeneralDataContainerRow">
              <TextField className="SupplierManufacturingLocationDataEdit_Textbox" label="City" name="city" required={true} onChange={this.OnChange} onBlur={this.OnBlurAddress} value={this.props.SupplierManufacturingLocation.city} disabled={!this.props.IsEditable} />
              <TextField className="SupplierManufacturingLocationDataEdit_Textbox" label="ZIP code" name="zip" required={true} onChange={this.OnChange} onBlur={this.OnBlurAddress} value={this.props.SupplierManufacturingLocation.zip} disabled={!this.props.IsEditable} />
            </div>
            <div className="SupplierManufacturingLocationDataEdit_GeneralDataContainerRow">
              <TextField className="SupplierManufacturingLocationDataEdit_Textbox" label="Country" name="country" required={true} onChange={this.OnChange} onBlur={this.OnBlurAddress} value={this.props.SupplierManufacturingLocation.country} disabled={!this.props.IsEditable} />
              <TextField className="SupplierManufacturingLocationDataEdit_Textbox" label="State" name="regionName" required={true} onChange={this.OnChange} onBlur={this.OnBlurAddress} value={this.props.SupplierManufacturingLocation.regionName} disabled={!this.props.IsEditable} />
            </div>
          </div>
          <div className="SupplierManufacturingLocationDataEdit_GeocodeDataContainer" style={this.props.IsEditable || this.state.LocationTypes.length === 0 ? { borderBottom: "none" } : {}}>
            <label className="SupplierManufacturingLocationDataEdit_HeadLabel">Geocodes</label>
            <div className="SupplierManufacturingLocationDataEdit_GeneralDataContainerFirstRow">
              <TextField className="SupplierManufacturingLocationDataEdit_Textbox" label="Longitude" name="longitude" required={true} onChange={this.OnChangeCoordinates} value={this.props.SupplierManufacturingLocation.longitude} disabled={!this.props.IsEditable} />
              <TextField className="SupplierManufacturingLocationDataEdit_Textbox" label="Latitude" name="latitude" required={true} onChange={this.OnChangeCoordinates} value={this.props.SupplierManufacturingLocation.latitude} disabled={!this.props.IsEditable} />
            </div>
          </div>
          {!this.props.IsEditable && this.state.LocationTypes.length > 0 && (
            <div className="SupplierManufacturingLocationDataEdit_LocationTypes">
              <label className="SupplierManufacturingLocationDataEdit_HeadLabel">Location types</label>
              {this.state.LocationTypes.map((locationType) => (
                <Card className="SupplierManufacturingLocationDataEdit_RelationNode">
                  <CardHeader
                    action={
                      <>
                        <IconButton
                          onClick={() => {
                            this.LocationTypeOnClick(this.state.LocationTypes.indexOf(locationType));
                          }}
                        >
                          {locationType.isRaised ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                      </>
                    }
                    title={locationType.name}
                  ></CardHeader>
                  <Collapse in={locationType.isRaised}>
                    <Divider />
                    <CardContent style={{ height: "120px" }}>
                      <TextArea disabled={true} label="Description" required={true} value={locationType.description} />
                    </CardContent>
                  </Collapse>
                </Card>
              ))}
            </div>
          )}
        </div>

        <style jsx>
          {`
            .SupplierManufacturingLocationDataEdit_HeadLabel {
              font-size: 16px;
              font-weight: bold;
              top: 20px;
            }
            .SupplierManufacturingLocationDataEdit_Textbox {
              width: 255px;
            }

            .SupplierManufacturingLocationDataEdit_GeneralDataContainerFirstRow {
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: 20px;
            }

            .SupplierManufacturingLocationDataEdit_GeneralDataContainerRow {
              display: flex;
              justify-content: space-between;
              width: 100%;
            }
            .SupplierManufacturingLocationDataEdit_Node {
              width: 100%;
              height: 100%;
              overflow-y: scroll;
            }
            .SupplierManufacturingLocationDataEdit_GeneralDataContainer {
              position: relative;
              left: 50%;
              transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              width: 534px;
              height: auto;
              min-height: 80px;
              border-bottom: 1px solid ${Color.Grey3};
              margin-bottom: 40px;
              top: 10px;
            }
            .SupplierManufacturingLocationDataEdit_AddressDataContainer {
              position: relative;
              left: 50%;
              transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              width: 534px;
              height: 270px;
              border-bottom: 1px solid ${Color.Grey3};
              margin-bottom: 30px;
            }
            .SupplierManufacturingLocationDataEdit_GeocodeDataContainer {
              position: relative;
              left: 50%;
              transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              width: 534px;
              height: 110px;
              border-bottom: 1px solid ${Color.Grey3};
              margin-bottom: 30px;
            }
            .SupplierManufacturingLocationDataEdit_LocationTypes {
              position: relative;
              left: 50%;
              transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              width: 534px;
              height: 200px;
            }
            .SupplierManufacturingLocationDataEdit_RelationNode {
              width: 534px;
              position: relative;
              left: 50%;
              transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              margin-top: 20px;
            }
          `}
        </style>
      </>
    );
  };
}
export default SupplierManufacturingLocationDataEdit;
