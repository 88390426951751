import { Button, ButtonVariant } from "@supplyon/ui-components";
import { Checkbox, MuiThemeProvider, Paper, Table, TableBody, TableCell, TableHead, TableRow, createTheme } from "@material-ui/core/";
import React, { Component } from "react";
import Color from "../libraries/SupplyOnUiConstants";
import { Delete } from "@material-ui/icons/";

/*  Props: 
        events:
            OnDelete: raised by onClick of delete button
*/
const theme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        width: "100%",
        overflow: "auto",
        boxShadow: "none !important",
      },
    },
    MuiTableCell: {
      root: {
        padding: "0px",
        fontSize: "12px",
      },
      head: {
        color: Color.Grey5,
        backgroundColor: Color.Grey1,
        borderTop: "none",
      },
    },
    MuiSvgIcon: {
      root: {
        height: "16px",
        width: "16px",
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: "7.5px 7.5px 7.5px 7.5px",
        marginLeft: "16.5px",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: Color.Blue1,
        },
      },
    },
    MuiIconButton: {
      colorSecondary: {
        "&:hover": {
          color: Color.Blue1,
          backgroundColor: "rgba(50, 50, 50, 0.10)",
        },
      },
    },
  },
});

class UploadedSupplierManufacturingFileList extends Component {
  constructor(props) {
    super(props);
    this.state = { IsRaised: false, Files: [], AllFilesSelected: false };
  }

  Raise = (filesUploaded) => {
    filesUploaded.forEach((file) => {
      file.isDeleteFlag = false;
    });

    this.setState({ IsRaised: true, Files: filesUploaded });
  };

  OnRowItemChecked = (fileId) => {
    let files = this.state.Files;
    files.forEach((file) => {
      if (file.userFileId === fileId) {
        file.isDeleteFlag = !file.isDeleteFlag;
      }
    });
    this.setState({ Files: files });
  };

  OnSelectAllClick = () => {
    const currentAllFileSelected = this.state.AllFilesSelected;
    let files = this.state.Files;
    files.forEach((file) => {
      file.isDeleteFlag = !currentAllFileSelected;
    });
    this.setState({ AllFilesSelected: !currentAllFileSelected, Files: files });
  };

  OnDeleteClick = () => {
    let fileIdsToDelete = [];
    this.state.Files.forEach((file) => {
      if (file.isDeleteFlag) {
        fileIdsToDelete.push(file.userFileId);
      }
    });
    if (fileIdsToDelete.length > 0) {
      this.props.OnDelete(fileIdsToDelete);
    }
  };

  IsEmpty = () => {
    return this.state.Files.length === 0 ? true : false;
  };

  Collapse = () => {
    this.setState({ IsRaised: false, Files: [], AllFilesSelected: false });
  };

  render = () => {
    return (
      this.state.IsRaised && (
        <>
          <div className="UploadedSupplierManufacturingFileList_Node">
            <div className="UploadedSupplierManufacturingFileList_Header">
              <label className="UploadedSupplierManufacturingFileList_HeaderLabel">Uploaded files</label>
            </div>
            <div className="UploadedSupplierManufacturingFileList_Body">
              <div className="UploadedSupplierManufacturingFileList_TableButtons">
                <Button variant={ButtonVariant.Page} className="UploadedSupplierManufacturingFileList_DeleteButton" onClick={() => this.OnDeleteClick()} disabled={this.IsEmpty()}>
                  <Delete className="UploadedSupplierManufacturingFileList_DeleteIcon" />
                  Delete
                </Button>
              </div>
              <div className="UploadedSupplierManufacturingFileList_TableContainer">
                <MuiThemeProvider theme={theme}>
                  <Paper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Checkbox checked={this.state.AllFilesSelected} onClick={() => this.OnSelectAllClick()} inputProps={{ "aria-label": "select all" }} disabled={this.IsEmpty()} />
                          </TableCell>
                          <TableCell>File name</TableCell>
                          <TableCell>Date of upload (UTC)</TableCell>
                          <TableCell>File size in bytes</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.Files.map((files) => (
                          <TableRow hover key={files.userFileId}>
                            <TableCell>
                              <Checkbox checked={files.isDeleteFlag} onClick={() => this.OnRowItemChecked(files.userFileId)} inputProps={{ "aria-labelledby": files.userFileId }} />
                            </TableCell>
                            <TableCell component="th" scope="row" className="UploadedSupplierManufacturingFileList_BoldFont">
                              {files.name}
                            </TableCell>
                            <TableCell>{files.insertDate}</TableCell>
                            <TableCell>{files.byteSize}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </MuiThemeProvider>
              </div>
            </div>
            <div className="UploadedSupplierManufacturingFileList_Footer">
              <div className="UploadedSupplierManufacturingFileList_FooterButtonBar">
                <Button onClick={this.Collapse} className="UploadedSupplierManufacturingFileList_FooterButton" variant={ButtonVariant.Page}>
                  Close
                </Button>
              </div>
            </div>
          </div>
          <style jsx>
            {`
              .UploadedSupplierManufacturingFileList_TableContainer {
                position: relative;
                width: 100%;
                height: 300px;
              }
              .UploadedSupplierManufacturingFileList_DeleteIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .UploadedSupplierManufacturingFileList_DeleteButton {
                position: relative;
                top: 50%;
                margin-left: 24px;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .UploadedSupplierManufacturingFileList_TableButtons {
                width: 100%;
                height: 50px;
                border-bottom: 1px solid ${Color.Grey3};
              }
              .UploadedSupplierManufacturingFileList_BoldFont {
                font-weight: bold;
              }
              .UploadedSupplierManufacturingFileList_FooterButton {
                margin-left: 24px;
              }
              .UploadedSupplierManufacturingFileList_FooterButtonBar {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .UploadedSupplierManufacturingFileList_Body {
                position: relative;
                width: calc(100% - 7px);
                height: calc(100% - 128px);
                overflow: auto;
              }
              .UploadedSupplierManufacturingFileList_Footer {
                height: 64px;
                width: 100%;
                border-top: 1px solid ${Color.Grey3};
                line-height: 50px;
                bottom: 0;
                position: absolute;
              }
              .UploadedSupplierManufacturingFileList_Header {
                height: 64px;
                width: 100%;
                border-bottom: 1px solid ${Color.Grey3};
                padding-top: 24px;
              }
              .UploadedSupplierManufacturingFileList_HeaderLabel {
                font-size: 16px;
                font-weight: bold;
                margin-left: 24px;
              }
              .UploadedSupplierManufacturingFileList_Node {
                width: 600px;
                height: calc(100% - 132px);
                border-radius: 5px;
                background-color: white;
                position: absolute;
                top: 92px;
                left: 40px;
                z-index: 49;
                border: 0px 0px 2px ${Color.Grey4};
              }
            `}
          </style>
        </>
      )
    );
  };
}
export default UploadedSupplierManufacturingFileList;
