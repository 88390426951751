import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";

class RiskLayerService {
  static GetAllExternalRiskLayer = () => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/risklayer", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
      }).then((response) => response.json());
    });
  };

  static LoadMapTile = (signal, requestModel) => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/risklayer", {
        method: "POST",
        mode: "cors",
        signal: signal,
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
        body: JSON.stringify(requestModel),
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            return "aborted";
          }
        })
        .catch((err) => {
          return "aborted";
        });
    });
  };

  static LoadMultipleMapTiles = (signal, requestModels) => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/risklayer/multiple", {
        method: "POST",
        mode: "cors",
        signal: signal,
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
        body: JSON.stringify(requestModels),
      })
        .then((response) => response.json())
        .catch((err) => {
          return "aborted";
        });
    });
  };

  static LoadMapTileSwissRe = (signal, requestModel) => {
    let southWestModel = this.ConvertToGoogleMercator(requestModel.southWestLatitude, requestModel.southWestLongitude);
    let northEastModel = this.ConvertToGoogleMercator(requestModel.northEastLatitude, requestModel.northEastLongitude);
    let add = requestModel.zoomFactor < 10 ? "_Overview" : "";
    let uri = "https://swissre.azure-api.net/wms-trial/v2/rest/services/";

    uri = uri + requestModel.riskName + add + "/MapServer/export?bbox=";
    uri = uri + southWestModel.x + ",";
    uri = uri + southWestModel.y + ",";
    uri = uri + northEastModel.x + ",";
    uri = uri + northEastModel.y;
    uri = uri + "&bboxSR=102100&dpi=96&f=image&format=png8&imageSR=102100&size=";
    uri = uri + Math.round(requestModel.width) + "," + Math.round(requestModel.height);
    uri = uri + "&transparent=true&mapScale=12";

    return fetch(uri, {
      method: "GET",
      mode: "cors",
      headers: { "Ocp-Apim-Subscription-Key": "b2a2c22801fb44efbcfc950186c5f3dc" },
      signal: signal,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        }
      })
      .then((blobResponse) => {
        requestModel.picture = URL.createObjectURL(blobResponse);
        return requestModel;
      })
      .catch((err) => {
        return "cancel";
      });
  };

  static ConvertToGoogleMercator = (lat, lng) => {
    let returnValue = { x: 0, y: 0 };
    returnValue.x = (lng * 20037508.34) / 180;
    returnValue.y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
    returnValue.y = (returnValue.y * 20037508.34) / 180;
    return returnValue;
  };
}
export default RiskLayerService;
