import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";

class CompanyService {
  static GetCompanyApiKey = async () => {
    await RiskManagementKeycloak.RefreshToken();
    const response = await fetch(process.env.REACT_APP_SERVER_URL + "/api/Company", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Keycloak_Token: RiskManagementKeycloak.Access_Token,
        Keycloak_UserID: RiskManagementKeycloak.User_Id,
        User_Roles: RiskManagementKeycloak.UserRoles,
        Spin_ID: RiskManagementKeycloak.Spin_Id,
        Sma_ID: RiskManagementKeycloak.Sma_Id,
        Authorization: RiskManagementKeycloak.Access_Token,
      },
    });
    return await response.json();
  };
}
export default CompanyService;
