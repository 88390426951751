import { Button, ButtonVariant } from "@supplyon/ui-components";
import { Delete, GetAppRounded } from "@material-ui/icons/";
import React, { PureComponent } from "react";
import clsx from "clsx";
import Color from "../libraries/SupplyOnUiConstants";
import { Checkbox, TableCell, Paper } from "@material-ui/core/";
import { FiberManualRecord } from "@material-ui/icons/";
import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";
import VirtualizedTable from "./VirtualizedTable";
import { withStyles } from "@material-ui/core/styles";
import { DownloadRiskLayerJson } from "../libraries/DownloadHelper";

/*  Props:
        events:
            onDelete
            onRefresh
            SortList
        
        properties:

            data: list of risk layers with some overlap with selected area
*/

const styles = () => ({
  paper: {
    width: "100%",
    height: "calc(100% - 64px)",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  tableRow: {
    cursor: "pointer",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: Color.Grey2,
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: "initial",
  },
  buttonBar: {
    marginLeft: "24px",
    width: "100%",
    height: "64px",
  },
  downloadButton: {
    width: "112px",
    height: "32px",
    marginRight: "16px",
    marginTop: "16px",
  },
  downloadIcon: {
    marginRight: "10px",
    width: "18px",
    height: "20px",
  },
  deleteButton: {
    width: "92",
    height: "32px",
    marginRight: "16px",
    marginTop: "16px",
  },
  deleteIcon: {
    marginRight: "10px",
    width: "14px",
    height: "14px",
  },
});

class SelectionRiskLayerList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowIds: [],
    };
  }
  static defaultProps = {
    headerHeight: 33,
    rowHeight: 33,
  };

  columns = [
    {
      name: "checkbox",
      dataKey: "dataKey is a required prop but not used here",
      headerRenderer: () => this.HeaderRendererCheckbox(),
      cellRenderer: (rowData) => this.CellRendererCheckbox(rowData),
      cellProps: { style: { paddingRight: 0 } },
      width: 48,
      disableSort: true,
    },
    {
      width: 458,
      label: "Name",
      dataKey: "name",
      disableSort: false,
    },
    {
      width: 100,
      label: "Risk color",
      dataKey: "nested key see cellDataGetter",
      cellDataGetter: ({ rowData }) => rowData.colorCoding?.colorValue,
      cellRenderer: (cellData) => this.CellRendererRiskColor(cellData),
      disableSort: true,
    },
  ];

  HeaderRendererCheckbox = () => {
    const { headerHeight, classes } = this.props;

    return (
      <TableCell component="div" className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)} variant="head" style={{ height: headerHeight }} align={"left"}>
        <Checkbox indeterminate={this.state.selectedRowIds.length > 0 && this.state.selectedRowIds.length < this.props.data.length} checked={this.state.selectedRowIds.length === this.props.data.length} onChange={(event) => this.OnChangeHeaderCheckbox(event)} size="small" />
      </TableCell>
    );
  };

  CellRendererCheckbox = ({ rowData }) => {
    const { classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={"left"}
      >
        <Checkbox checked={this.state.selectedRowIds.some((id) => rowData.customRiskLayerId === id)} size="small" />
      </TableCell>
    );
  };

  CellRendererRiskColor = ({ cellData }) => {
    const { classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={"left"}
      >
        <FiberManualRecord style={{ color: cellData }}></FiberManualRecord>
      </TableCell>
    );
  };

  RowGetter = ({ index }) => this.props.data[index];

  OnChangeHeaderCheckbox = (event) => {
    this.setState({
      selectedRowIds: event.target.checked ? this.props.data.map((item) => item.customRiskLayerId) : [],
    });
  };

  // clicking anywhere in a row works as onChange for checkboxes
  OnRowClick = ({ rowData }) => {
    const clickedRowId = rowData.customRiskLayerId;

    this.setState((prevState) => ({
      selectedRowIds: prevState.selectedRowIds.includes(clickedRowId) ? prevState.selectedRowIds.filter((id) => id !== clickedRowId) : [...prevState.selectedRowIds, clickedRowId],
    }));
  };

  OnDeleteClick = () => {
    const riskLayersToDelete = this.props.data.filter((riskLayer) => (riskLayer.belongsToUserId !== null || riskLayer.belongsToSmaId !== null ) && this.state.selectedRowIds.includes(riskLayer.customRiskLayerId));
    if (riskLayersToDelete.length) {
      const idList = riskLayersToDelete.map((item) => item.customRiskLayerId);
      this.props.OnDelete(idList);
    }
  };

  OnDownloadClick = () => {
    let selectedRiskLayers = this.props.data.filter((riskLayer) => this.state.selectedRowIds.includes(riskLayer.customRiskLayerId));
    DownloadRiskLayerJson(selectedRiskLayers);
  };

  render() {
    const { classes } = this.props;
    const disableButton = !this.props.data.some((riskLayer) => this.state.selectedRowIds.includes(riskLayer.customRiskLayerId));
    return (
      <>
        <div className={classes.buttonBar}>
          <span>
            {( RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_CategoryManager) && (
              <Button onClick={this.OnDownloadClick} variant={ButtonVariant.Page} className={classes.downloadButton} disabled={disableButton}>
                <GetAppRounded className={classes.downloadIcon} />
                Download
              </Button>
            )}
            {( RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner) && (
              <Button variant={ButtonVariant.Page} className={classes.deleteButton} onClick={() => this.OnDeleteClick()} disabled={disableButton}>
                <Delete className={classes.deleteIcon} />
                Delete
              </Button>
            )}
          </span>
        </div>
        <Paper className={classes.paper}>
          <VirtualizedTable rowCount={this.props.data.length} rowGetter={this.RowGetter} columns={this.columns} selectedRowIds={this.state.selectedRowIds} onRowClick={this.OnRowClick} SortList={this.props.SortList} sortBy={this.props.sortBy} sortDirection={this.props.sortDirection} />
        </Paper>
      </>
    );
  }
}
export default withStyles(styles)(SelectionRiskLayerList);
