import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";

class UserFileUploadService {
  static SaveUploadedFileData = (fileData, isRetrieveRiskDataSet) => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/UserFile/" + isRetrieveRiskDataSet, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
        body: JSON.stringify(fileData),
      })
        .then((response) => {
          // first then()
          if (response !== undefined && response.ok) {
            return response.json();
          }

          throw new Error("Something went wrong.");
        })
        .then((response) => response)
        .catch((error) => {});
    });
  };

  static GetUploadedFiles = () => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/UserFile", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
      })
        .then((response) => {
          // first then()
          if (response !== undefined && response.ok) {
            return response.json();
          }

          throw new Error("Something went wrong.");
        })
        .then((response) => response)
        .catch((error) => {});
    });
  };

  static DeleteFiles = (fileIds) => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/UserFile", {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
        body: JSON.stringify(fileIds),
      })
        .catch((error) => error)
    });
  };
}
export default UserFileUploadService;
