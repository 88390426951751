import React, { Component } from "react";
import { Marker as GoogleMarker } from "@react-google-maps/api";
import Color from "../libraries/SupplyOnUiConstants";
import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";

/*  Props: 
        events:
 
            OnClick:  raised by onClick on the marker.
            OnMove: raised on drag/drop the marker


        properties:

            IsFocused: turns to true when the marker gets focused by onclick
            IsEditable: only markers (supplier manufacturing locations) witch are realted to an user (belongsToUserId) can be edited
            Latitude: latitude of the marker location
            Longitude: longitude of the marker location
            CalculatedHighColor: the display color of the marker
*/

const svgPathSelected = "M12,2c-4.2,0-8,3.22-8,8.2c0,3.18,2.45,6.92,7.34,11.23c0.38,0.33,0.95,0.33,1.33,0C17.55,17.12,20,13.38,20,10.2 C20,5.22,16.2,2,12,2z M12,12c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C14,11.1,13.1,12,12,12z";
const svgPathNormal = "M-7,0a7,7 0 1,0 14,0a7,7 0 1,0 -14,0";

class Marker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MouseDown: false,
      ClickedCoordinates: {},
    };
  }

  OnMouseUp = (e) => {
    if (this.state.MouseDown && this.props.IsFocused && this.props.IsEditable) {
      let latDifference = e.latLng.lat() - this.state.ClickedCoordinates.lat();
      let lngDifference = e.latLng.lng() - this.state.ClickedCoordinates.lng();
      this.props.OnMove(latDifference, lngDifference);
    }

    this.setState({ MouseDown: false, ClickedCoordinates: {} });
  };

  render = () => {
    return (
      <>
        <GoogleMarker
          clusterer={this.props.Clusterer}
          noClustererRedraw={this.props.NoClustererRedraw}
          zIndex={this.props.IsFocused ? 74 : 50}
          position={{ lat: this.props.Latitude, lng: this.props.Longitude }}
          onClick={this.props.OnClick}
          icon={{
            path: this.props.IsFocused ? svgPathSelected : svgPathNormal,
            fillColor: this.props.CalculatedHighColor,
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: Color.Grey6,
            scale: 1.2,
            anchor: this.props.IsFocused ? new window.google.maps.Point(12, 20) : new window.google.maps.Point(0, 0),
          }}
          options={{
            editable: this.props.IsFocused && this.props.IsEditable,
            clickable: true,
            draggable: (RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner) && this.props.IsFocused && this.props.IsEditable,
          }}
          onMouseDown={(event) => {
            if (this.props.IsFocused && this.props.IsEditable) {
              this.setState({ ClickedCoordinates: event.latLng, MouseDown: true });
            }
          }}
          onMouseUp={this.OnMouseUp}
        />
      </>
    );
  };
}
export default Marker;
