import { GoogleMap, LoadScript, MarkerClusterer, OverlayView } from "@react-google-maps/api";
import { IconButton, Snackbar, SnackbarContent } from "@material-ui/core";
import React, { Component } from "react";
import Circle from "./Circle";
import CircleSelection from "./CircleSelection";
import { Close } from "@material-ui/icons/";
import Color from "../libraries/SupplyOnUiConstants";
import CustomRiskLayerFormEdit from "./CustomRiskLayerFormEdit";
import CustomRiskLayerService from "../services/CustomRiskLayerService";
import FilterContextMenu from "./FilterContextMenu";
import { IsPointInCircle, generatePointsFromCircle, hasIntersections } from "../libraries/CollisionDetectionHelper";
import Marker from "./Marker";
import MenuBar from "./MenuBar";
import MessageBox from "./MessageBox";
import QuestionnariesManageForm from "./questionnaire/QuestionnariesManageForm";
import QuestionnaireEditForm from "./questionnaire/QuestionnaireEditForm";
import SurveyCreateForm from "./survey/SurveyCreateForm";
import SurveyCreateConfirmation from "./survey/SurveyCreateConfirmation";
import SurveyOverviewForm from "./survey/SurveyOverviewForm";
import NotificationBackground from "./NotificationBackground";
import Polygon from "./Polygon";
import PolygonSelection from "./PolygonSelection";
import ProgressBar from "./ProgressBar";
import RiskLayerService from "../services/RiskLayerService";
import QuestionnaireService from "../services/QuestionnaireService";
import SurveyService from "../services/SurveyService";
import CISUserAccountService from "../services/CISUserAccountService";
import CISCompanyService from "../services/CISCompanyService";
import CISFeatureService from "../services/CISFeatureService";
import CdmSmaService from "../services/CdmSmaService";
import FeatureConstants from "../libraries/FeatureConstants";
import RiskManagementConstants from "../libraries/RiskManagementConstants";
import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";
import SelectionMainForm from "./SelectionMainForm";
import SupplierManufacturingLocationMainForm from "./SupplierManufacturingLocationMainForm";
import SupplierManufacturingLocationService from "../services/SupplierManufacturingLocationService";
import RiskDataUpdateStatusService from "../services/RiskDataUpdateStatusService";
import UploadRiskLayerFileForm from "./UploadRiskLayerFileForm";
import UploadSupplierManufacturingFileForm from "./UploadSupplierManufacturingFileForm";
import UploadedSupplierManufacturingFileList from "./UploadedSupplierManufacturingFileList";
import UserFileUploadService from "../services/UserFileUploadService";
import ViewContextMenu from "./ViewContextMenu";
import RiskDataProviderService from "../services/RiskDataProviderService";
import RiskRequestCount from "./RiskRequestCount";

// one minute interval
const oneMinute = 1000 * 60;

// include places library for StandaloneSearchbox
const libraries = ["places", "geometry"];

//this is required because of the re-render bug of @react-google-maps/api/{GoogleMap}
const startCenter = {
  lat: 30.82211842926564,
  lng: 1.5941026837663808,
};

class MainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MapGestureHandling: "auto",
      MapReference: null,
      CurrentSelectedCustomRiskLayerIndex: -1,
      CurrentSelectedSMLIndex: -1,
      CustomRiskLayerItems: [],
      ReadonlySupplierManufacturingLocations: [],
      SupplierManufacturingLocations: [],
      RaiseSnackBar: false,
      SnackBarMessage: "",
      SnackBarVariantStyle: "",
      SnackBarSeverity: "",
      SnackBarAutoHideDuration: null,
      HighlightFilterIcon: false,
      PolygonalSelectionClicked: false,
      PolygonalSelectionObject: null,
      CircleSelectionClicked: false,
      CircleSelectionObject: null,
      DisplayMitigatedRisk: false,
      DisplayAggregatedRisks: true,
      DisplayedMapType: "roadmap",
      DisplayCustomRiskLayer: false,
      DisplayedRiskOverlays: [],
      DisplayedTiles: [],
      CachedTiles: [],
      mapMouseDown: false,
      centerChanged: false,
      LoadTilesRequests: [],
      IsFileUploadInProgress: false,
      Questionnaires: [],
      Features: [],
      OngoingUpdates: [],
      RunningInterval: null,
      ClusterStyles: [
        {
          textColor: Color.White,
          url: `data:image/svg+xml;base64,${this.GetClusterIcon(24, true)}`,
          height: 24,
          width: 24,
          textSize: 12,
        },
        {
          textColor: Color.White,
          url: `data:image/svg+xml;base64,${this.GetClusterIcon(32, true)}`,
          height: 24,
          width: 32,
          textSize: 12,
        },
        {
          textColor: Color.White,
          url: `data:image/svg+xml;base64,${this.GetClusterIcon(40, true)}`,
          height: 24,
          width: 40,
          textSize: 12,
        },
      ],
    };
  }

  componentDidMount = () => {
    this.NotificationBackground.Raise(true, "Loading risks...");
    CustomRiskLayerService.GetAllCustomRiskLayer().then((result) => {
      for (let i = 0; i < result.length; i++) {
        result[i].IsVisible = false;
      }
      this.setState({ CustomRiskLayerItems: result }, () => {
        this.NotificationBackground.Raise(true, "Loading all locations...");
        SupplierManufacturingLocationService.GetAllSupplierManufacturingLocations().then((result) => {
          this.setState({ SupplierManufacturingLocations: result, ReadonlySupplierManufacturingLocations: [...result] }, () => {
            this.NotificationBackground.Collapse();
          });
        });
      });
    });
    CISCompanyService.GetFeatureCompanyByCompanySpinId().then((featureCompanies) => {
      if (featureCompanies !== null) {
        let features = [];
        featureCompanies.forEach((featureCompany) => {
          CISFeatureService.GetFeatureByFeatureId(featureCompany.featureId).then((feature) => {
            features.push(feature.key);
          });
        });
        this.setState({ Features: features });
      }
    });
    CdmSmaService.GetAllCdmSmas().then((cdmSmas) => {
      let cdmSmaMapping = [];
      if (cdmSmas !== null) {
        cdmSmas.forEach((cdmSma) => {
          cdmSmaMapping.push(cdmSma);
        });
      }

      this.setState({ CdmSmaMapping: cdmSmaMapping });
    });
    RiskDataUpdateStatusService.GetAllRiskDataUpdateStatus().then((riskUpdateResult) => {
      if (riskUpdateResult !== undefined && riskUpdateResult !== "" && riskUpdateResult.length > 0) {
        let ongoingUpdates = riskUpdateResult.filter((x) => x.status === 1 || x.status === -1);
        if (ongoingUpdates.length > 0) {
          let runningInterval = setInterval(this.CheckDataRiskUpdates, oneMinute);
          this.setState({
            OngoingUpdates: ongoingUpdates,
            RunningInterval: runningInterval,
          });
        }
      }
    });
  };

  GetClusterIcon = (width, ligthTheme) => {
    return window.btoa(`<svg viewBox="0 0 ${width} 24" xmlns="http://www.w3.org/2000/svg"><rect x="0" y="0" width="${width - 2}" height="22" rx="12" style="stroke:${ligthTheme ? Color.White : Color.Grey6}; stroke-width:1px; fill:${ligthTheme ? Color.Grey6 : Color.White}"/></svg>`);
  };

  CustomRiskLayerItemOnClick = (index) => {
    if (!this.CollapseAll()) {
      return;
    }
    let itemList = this.state.CustomRiskLayerItems;

    if (this.state.CurrentSelectedCustomRiskLayerIndex > -1) {
      let previousSelectedItem = itemList[this.state.CurrentSelectedCustomRiskLayerIndex];

      previousSelectedItem.isFocused = false;
      itemList[this.state.CurrentSelectedCustomRiskLayerIndex] = previousSelectedItem;
    }

    let currentSelectedItem = itemList[index];
    currentSelectedItem.isFocused = true;

    itemList[index] = currentSelectedItem;

    this.setState({ CustomRiskLayerItems: itemList, CurrentSelectedCustomRiskLayerIndex: index });

    this.CustomRiskLayerFormEdit.Raise(currentSelectedItem);
  };

  DeleteCustomRiskLayer = (selectedRiskLayerIds) => {
    this.MessageBox.Show(
      "Delete",
      "Do you really want to delete this custom risk layer?",
      () => {},
      () => {
        this.CollapseAll(true);
        this.ProgressBar.Raise("Deleting risk layer");

        let currentRiskLayerList = [...this.state.CustomRiskLayerItems];

        if (selectedRiskLayerIds !== undefined) {
          CustomRiskLayerService.DeleteCustomRiskLayer(selectedRiskLayerIds).then((result) => {
            let currentSMLList = [...this.state.SupplierManufacturingLocations];
            let currentSMLListReadOnly = [...this.state.ReadonlySupplierManufacturingLocations];

            for (let i = 0; i < result.length; i++) {
              let newSml = result[i];
              let index = currentSMLList.findIndex((item) => {
                return item.supplierManufacturingLocationId === newSml.supplierManufacturingLocationId;
              });
              if (index > -1 && index < currentSMLList.length) {
                currentSMLList[index] = newSml;
              }

              let indexReadOnly = currentSMLListReadOnly.findIndex((item) => {
                return item.supplierManufacturingLocationId === newSml.supplierManufacturingLocationId;
              });
              if (indexReadOnly > -1 && indexReadOnly < currentSMLListReadOnly.length) {
                currentSMLListReadOnly[indexReadOnly] = newSml;
              }
            }
            this.ProgressBar.Collapse();
            this.setState({ SupplierManufacturingLocations: currentSMLList, ReadonlySupplierManufacturingLocations: currentSMLListReadOnly });
            this.setState({ RaiseSnackBar: true, SnackBarMessage: "Custom risk layer was deleted successfully!", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarSuccess", SnackBarSeverity: "success", SnackBarAutoHideDuration: 3000 });
          });

          selectedRiskLayerIds.forEach((id) => {
            const index = currentRiskLayerList.findIndex((item) => {
              return item.customRiskLayerId === id;
            });
            currentRiskLayerList.splice(index, 1);
          });
          this.setState({ CustomRiskLayerItems: currentRiskLayerList, CurrentSelectedCustomRiskLayerIndex: -1 });
        }
      }
    );
  };

  CustomRiskLayerEditCancel = (hasChanges) => {
    if (hasChanges) {
      this.MessageBox.Show(
        "Close risk layer",
        "Do you really want to close without saving?",
        () => {},
        () => {
          let currentList = this.state.CustomRiskLayerItems;
          let currentSelectedItem = currentList[this.state.CurrentSelectedCustomRiskLayerIndex];
          this.CustomRiskLayerFormEdit.Collapse();

          if (currentSelectedItem.customRiskLayerId !== undefined) {
            CustomRiskLayerService.GetCustomRiskLayerById(currentSelectedItem.customRiskLayerId).then((item) => {
              currentList.splice(this.state.CurrentSelectedCustomRiskLayerIndex, 1);
              item.IsVisible = true;
              currentList.push(item);
              this.setState({ CustomRiskLayerItems: currentList, CurrentSelectedCustomRiskLayerIndex: -1 }, () => {
                this.CollapseAll();
              });
            });
          } else {
            currentList.splice(this.state.CurrentSelectedCustomRiskLayerIndex, 1);
            this.setState({ CustomRiskLayerItems: currentList, CurrentSelectedCustomRiskLayerIndex: -1 }, () => {
              this.CollapseAll();
            });
          }
        }
      );
    } else {
      this.CollapseAll();
    }
  };

  SaveCustomRiskLayer = () => {
    let itemList = [...this.state.CustomRiskLayerItems];
    let index = this.state.CurrentSelectedCustomRiskLayerIndex;
    let currentItem = itemList[index];
    this.ProgressBar.Raise("Saving risk layer");

    CustomRiskLayerService.SaveCustomRiskLayer(currentItem).then((newItem) => {
      newItem.customRiskLayer.IsVisible = this.state.DisplayCustomRiskLayer;
      itemList[index] = newItem.customRiskLayer;
      let currentSMLList = [...this.state.SupplierManufacturingLocations];
      let currentSMLListReadOnly = [...this.state.ReadonlySupplierManufacturingLocations];

      for (let i = 0; i < newItem.affectedSml.length; i++) {
        let newSml = newItem.affectedSml[i];
        let index = currentSMLList.findIndex((item) => {
          return item.supplierManufacturingLocationId === newSml.supplierManufacturingLocationId;
        });
        if (index > -1 && index < currentSMLList.length) {
          currentSMLList[index] = newSml;
        }

        let indexReadOnly = currentSMLListReadOnly.findIndex((item) => {
          return item.supplierManufacturingLocationId === newSml.supplierManufacturingLocationId;
        });
        if (indexReadOnly > -1 && indexReadOnly < currentSMLListReadOnly.length) {
          currentSMLListReadOnly[indexReadOnly] = newSml;
        }
      }
      this.ProgressBar.Collapse();
      this.setState({
        SupplierManufacturingLocations: currentSMLList,
        CustomRiskLayerItems: itemList,
        ReadonlySupplierManufacturingLocations: currentSMLListReadOnly,
        CurrentSelectedCustomRiskLayerIndex: -1,
        RaiseSnackBar: true,
        SnackBarMessage: "Custom risk layer was saved successfully!",
        SnackBarVariantStyle: "SnackBarCommonStyle SnackBarSuccess",
        SnackBarSeverity: "success",
        SnackBarAutoHideDuration: 3000,
      });
    });
    this.CollapseAll(true);
  };

  SaveCustomRiskLayerList = (newRiskLayers) => {
    let currentRiskLayers = [...this.state.CustomRiskLayerItems];
    let currentSMLList = [...this.state.SupplierManufacturingLocations];
    let currentSMLListReadOnly = [...this.state.ReadonlySupplierManufacturingLocations];
    this.ProgressBar.Raise("Saving uploaded risk layers");

    CustomRiskLayerService.SaveCustomRiskLayerList(newRiskLayers).then((newItem) => {
      let resultRiskLayer = newItem.customRiskLayers.map((riskLayer) => {
        riskLayer.IsVisible = this.state.DisplayCustomRiskLayer;
        return riskLayer;
      });

      currentRiskLayers = [...currentRiskLayers, ...resultRiskLayer];

      newItem.affectedSml.forEach((newSml) => {
        let index = currentSMLList.findIndex((item) => {
          return item.supplierManufacturingLocationId === newSml.supplierManufacturingLocationId;
        });
        if (index !== -1) {
          currentSMLList[index] = newSml;
        }

        let indexReadOnly = currentSMLListReadOnly.findIndex((item) => {
          return item.supplierManufacturingLocationId === newSml.supplierManufacturingLocationId;
        });
        if (indexReadOnly !== -1) {
          currentSMLListReadOnly[indexReadOnly] = newSml;
        }
      });

      this.ProgressBar.Collapse();
      this.setState({
        CustomRiskLayerItems: currentRiskLayers,
        SupplierManufacturingLocations: currentSMLList,
        ReadonlySupplierManufacturingLocations: currentSMLListReadOnly,
        IsFileUploadInProgress: false,
        RaiseSnackBar: true,
        SnackBarMessage: "Custom risk layer was saved successfully!",
        SnackBarVariantStyle: "SnackBarCommonStyle SnackBarSuccess",
        SnackBarSeverity: "success",
        SnackBarAutoHideDuration: 3000,
      });
    });
    this.CollapseAll(true);
  };

  CustomRiskLayerOnChange = (item) => {
    let itemList = [...this.state.CustomRiskLayerItems];
    if (this.CustomRiskLayerFormEdit !== null) {
      this.CustomRiskLayerFormEdit.CurrentComponentChanged();
    }

    itemList[this.state.CurrentSelectedCustomRiskLayerIndex] = item;
    this.setState({ CustomRiskLayerItems: itemList }, () => {});
  };

  PolygonSelectionChange = (item) => {
    this.setState({ PolygonalSelectionObject: item }, () => {
      this.SetItemsInsidePolygonFlag();
    });
  };

  IsMarkerInsidePolygon = (testPoint, polygon) => {
    var result = false;
    var j = polygon.length - 1;
    for (var i = 0; i < polygon.length; i++) {
      if ((polygon[i].lat < testPoint.latitude && polygon[j].lat >= testPoint.latitude) || (polygon[j].lat < testPoint.latitude && polygon[i].lat >= testPoint.latitude)) {
        if (polygon[i].lng + ((testPoint.latitude - polygon[i].lat) / (polygon[j].lat - polygon[i].lat)) * (polygon[j].lng - polygon[i].lng) < testPoint.longitude) {
          result = !result;
        }
      }
      j = i;
    }
    return result;
  };

  CircleSelectionChange = (item) => {
    this.setState({ CircleSelectionObject: item }, () => {
      this.SetItemsInsideCircleFlag();
    });
  };

  SetItemsInsidePolygonFlag = () => {
    const selectionArea = this.state.PolygonalSelectionObject;
    let selectedSml = this.state.SupplierManufacturingLocations.filter((sml) => {
      return this.IsMarkerInsidePolygon({ latitude: sml.latitude, longitude: sml.longitude }, selectionArea.coordinates);
    });

    let selectedRiskLayers = this.state.CustomRiskLayerItems.filter((riskLayer) => {
      if (riskLayer.isCircle) {
        // check if circle center is in polygon
        if (this.IsMarkerInsidePolygon({ latitude: riskLayer.coordinates[0].lat, longitude: riskLayer.coordinates[0].lng }, selectionArea.coordinates)) {
          return true;
        } else {
          // check if points on the circumference of the circle are within the polygon
          const pointsArray = generatePointsFromCircle(riskLayer, 12);
          return pointsArray.some((point) => {
            return this.IsMarkerInsidePolygon({ latitude: point.lat, longitude: point.lng }, selectionArea.coordinates);
          });
        }
      } else {
        return riskLayer.coordinates.some((coords) => {
          return this.IsMarkerInsidePolygon({ latitude: coords.lat, longitude: coords.lng }, selectionArea.coordinates);
        });
      }
    });

    this.SelectionMainForm.Raise(selectedSml, selectedRiskLayers);
  };

  SetItemsInsideCircleFlag = () => {
    const selectionArea = this.state.CircleSelectionObject;
    let selectedSml = this.state.SupplierManufacturingLocations.filter((sml) => {
      return IsPointInCircle(sml, { latitude: selectionArea.coordinates[0].lat, longitude: selectionArea.coordinates[0].lng }, selectionArea.radius / 1000);
    });

    let selectedRiskLayers = this.state.CustomRiskLayerItems.filter((riskLayer) => {
      if (riskLayer.isCircle) {
        return hasIntersections(selectionArea, riskLayer);
      } else {
        return riskLayer.coordinates.some((coords) => {
          return IsPointInCircle({ latitude: coords.lat, longitude: coords.lng }, { latitude: selectionArea.coordinates[0].lat, longitude: selectionArea.coordinates[0].lng }, selectionArea.radius / 1000);
        });
      }
    });

    this.SelectionMainForm.Raise(selectedSml, selectedRiskLayers);
  };

  CreateNewCircle = () => {
    let mapCenter = this.state.MapReference.getCenter();
    let factor = (mapCenter.lat() - this.state.MapReference.getBounds().getSouthWest().lat()) / 4;

    let circle = {
      name: "",
      description: "",
      colorCoding: { colorValue: "grey", colorCodingKey: "new" },
      isFocused: true,
      isCircle: true,
      isPolygon: false,
      radius: factor * 200000,
      coordinates: [{ lat: mapCenter.lat(), lng: mapCenter.lng() }],
      IsVisible: true,
    };
    let itemList = this.state.CustomRiskLayerItems;
    itemList.push(circle);

    let index = itemList.indexOf(circle);

    this.setState({ CustomRiskLayerItems: itemList, CurrentSelectedCustomRiskLayerIndex: index });

    return circle;
  };

  CreateNewPolygon = () => {
    let mapCenter = this.state.MapReference.getCenter();
    let factor = (mapCenter.lat() - this.state.MapReference.getBounds().getSouthWest().lat()) / 4;

    let polygon = {
      name: "",
      description: "",
      colorCoding: { colorValue: "grey", colorCodingKey: "new" },
      isFocused: true,
      isCircle: false,
      isPolygon: true,
      IsVisible: true,
      radius: null,
      coordinates: [
        { lat: mapCenter.lat() + factor, lng: mapCenter.lng() - factor },
        { lat: mapCenter.lat() + factor, lng: mapCenter.lng() + factor },
        { lat: mapCenter.lat() - factor, lng: mapCenter.lng() + factor },
        { lat: mapCenter.lat() - factor, lng: mapCenter.lng() - factor },
      ],
    };

    let itemList = this.state.CustomRiskLayerItems;
    itemList.push(polygon);

    let index = itemList.indexOf(polygon);

    this.setState({ CustomRiskLayerItems: itemList, CurrentSelectedCustomRiskLayerIndex: index });

    return polygon;
  };

  CreateNewMarker = () => {
    let mapCenter = this.state.MapReference.getCenter();
    let newMarker = {
      IsNew: true,
      latitude: mapCenter.lat(),
      longitude: mapCenter.lng(),
      isFocused: true,
      calculatedHighColor: {
        colorValue: "white",
      },
      supplierManufacturingLocationRisks: [],
    };

    let smlList = this.state.SupplierManufacturingLocations;
    smlList.push(newMarker);
    let newIndex = smlList.indexOf(newMarker);
    this.setState({ SupplierManufacturingLocations: smlList, CurrentSelectedSMLIndex: newIndex });
    return newMarker;
  };

  SelectPolygon = () => {
    let mapCenter = this.state.MapReference.getCenter();
    let factor = (mapCenter.lat() - this.state.MapReference.getBounds().getSouthWest().lat()) / 4;

    let polygon = {
      coordinates: [
        { lat: mapCenter.lat() + factor, lng: mapCenter.lng() - factor },
        { lat: mapCenter.lat() + factor, lng: mapCenter.lng() + factor },
        { lat: mapCenter.lat() - factor, lng: mapCenter.lng() + factor },
        { lat: mapCenter.lat() - factor, lng: mapCenter.lng() - factor },
      ],
    };

    this.setState({ PolygonalSelectionObject: polygon, PolygonalSelectionClicked: true, CircleSelectionObject: null, CircleSelectionClicked: false }, () => this.SetItemsInsidePolygonFlag());
  };

  SelectCircle = () => {
    let mapCenter = this.state.MapReference.getCenter();
    let factor = (mapCenter.lat() - this.state.MapReference.getBounds().getSouthWest().lat()) / 4;

    let circle = {
      isFocused: true,
      isCircle: true,
      isPolygon: false,
      radius: factor * 100000,
      coordinates: [{ lat: mapCenter.lat(), lng: mapCenter.lng() }],
    };

    this.setState({ CircleSelectionObject: circle, CircleSelectionClicked: true, PolygonalSelectionObject: null, PolygonalSelectionClicked: false }, () => this.SetItemsInsideCircleFlag());
  };

  DeleteSelectedFiles = (fileIds) => {
    this.MessageBox.Show(
      "Delete",
      "Do you really want to delete the selected files?",
      () => {},
      () => {
        this.CollapseAll(true);
        if (fileIds !== undefined) {
          UserFileUploadService.DeleteFiles(fileIds)
            .then((result) => {
              if (result.status === 200) {
                this.setState({ RaiseSnackBar: true, SnackBarMessage: "Selected files were deleted successfully!", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarSuccess", SnackBarSeverity: "success", SnackBarAutoHideDuration: 3000 });
                return result.json();
              } else {
                this.setState({ RaiseSnackBar: true, SnackBarMessage: "Error occured while deleting the selected files, please try again.", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarError", SnackBarSeverity: "error", SnackBarAutoHideDuration: null });
                return undefined;
              }
            })
            .then((jsonresult) => {
              if (jsonresult !== undefined) {
                let curSml = this.state.SupplierManufacturingLocations;
                let readonlySml = this.state.ReadonlySupplierManufacturingLocations;

                for (let i = 0; i < jsonresult.length; i++) {
                  let currentItem = jsonresult[i];

                  let indexCur = curSml.findIndex((item) => {
                    return item.supplierManufacturingLocationId === currentItem.supplierManufacturingLocationId;
                  });
                  if (indexCur > -1 && indexCur < curSml.length) {
                    curSml.splice(indexCur, 1);
                  }

                  let indexReadOnly = readonlySml.findIndex((item) => {
                    return item.supplierManufacturingLocationId === currentItem.supplierManufacturingLocationId;
                  });
                  if (indexReadOnly > -1 && indexReadOnly < readonlySml.length) {
                    readonlySml.splice(indexReadOnly, 1);
                  }
                }

                this.setState({ SupplierManufacturingLocations: curSml, ReadonlySupplierManufacturingLocations: readonlySml });
                this.state.MapReference.setZoom(this.state.MapReference.getZoom());
              }
            });
        }
      }
    );
  };

  SupplierManufacturingLocationOnClick = (index) => {
    if (!this.CollapseAll()) {
      return;
    }
    let itemList = this.state.SupplierManufacturingLocations;

    if (this.state.CurrentSelectedSMLIndex > -1) {
      let previousSelectedItem = itemList[this.state.CurrentSelectedSMLIndex];

      previousSelectedItem.isFocused = false;
      itemList[this.state.CurrentSelectedSMLIndex] = previousSelectedItem;
    }

    let currentSelectedItem = itemList[index];
    currentSelectedItem.isFocused = true;

    itemList[index] = currentSelectedItem;

    this.setState({ SupplierManufacturingLocations: itemList, CurrentSelectedSMLIndex: index });

    this.SupplierManufacturingLocationMainForm.Raise(currentSelectedItem);
  };

  SetAllCustomRiskLayersVisibility = (visible) => {
    let customRiskLayers = this.state.CustomRiskLayerItems;
    for (let i = 0; i < customRiskLayers.length; i++) {
      customRiskLayers[i].IsVisible = visible;
    }
    this.setState({ CustomRiskLayerItems: customRiskLayers, DisplayCustomRiskLayer: visible });
  };

  RaiseSurveyCreateForm = (smlIds) => {
    let selectedSmls = this.state.SupplierManufacturingLocations.filter((x) => smlIds.find((y) => y === x.supplierManufacturingLocationId) != null);
    QuestionnaireService.GetAllQuestionnaires().then((questionnaires) => {
      if (questionnaires !== undefined && questionnaires !== "") {
        this.SurveyCreateForm.Raise(questionnaires, selectedSmls, this.state.CdmSmaMapping);
      } else {
        this.setState({ RaiseSnackBar: true, SnackBarMessage: "Error occured while retrieving the questionnaires, please try again", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarError", SnackBarSeverity: "error", SnackBarAutoHideDuration: null });
      }
    });
  };

  RaiseForm = (formName) => {
    if (formName === RiskManagementConstants.FilterContextMenu) {
      this.FilterContextMenu.Raise();
      this.ViewContextMenu.Collapse();
      this.RiskRequestCount.Collapse();
    } else if (formName === RiskManagementConstants.ViewContextMenu) {
      this.ViewContextMenu.Raise();
      this.FilterContextMenu.Collapse();
      this.RiskRequestCount.Collapse();
    } else if (formName === RiskManagementConstants.RiskDataProviderRequestCount) {
      RiskDataProviderService.GetMonthlyDataProviderRequestCount().then((result) => {
        if (result !== undefined && result !== "") {
          this.FilterContextMenu.Collapse();
          this.ViewContextMenu.Collapse();
          this.RiskRequestCount.Raise(result);
        } else {
          this.setState({ RaiseSnackBar: true, SnackBarMessage: "Error occured while retrieving the uploaded files, please try again", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarError", SnackBarSeverity: "error", SnackBarAutoHideDuration: null });
        }
      });
    } else if (formName === RiskManagementConstants.UploadSupplierManufacturingLocationFile) {
      if (!this.state.IsFileUploadInProgress) {
        if (this.CollapseAll()) {
          this.UploadSupplierManufacturingFileForm.Show();
        }
      }
    } else if (formName === RiskManagementConstants.UploadRiskLayerFile) {
      if (!this.state.IsFileUploadInProgress) {
        if (this.CollapseAll()) {
          this.UploadRiskLayerFileForm.Show();
        }
      }
    } else if (formName === RiskManagementConstants.ManageQuestionnairies) {
      QuestionnaireService.GetAllQuestionnaires().then((result) => {
        if (result !== undefined && result !== "") {
          this.QuestionnariesManageForm.Raise(result);
        } else {
          this.setState({ RaiseSnackBar: true, SnackBarMessage: "Error occured while retrieving the questionnaires, please try again", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarError", SnackBarSeverity: "error", SnackBarAutoHideDuration: null });
        }
      });
    } else if (formName === RiskManagementConstants.SurveyOverview) {
      SurveyService.GetAllSurveys().then((surveys) => {
        if (surveys !== undefined && surveys !== "") {
          CISUserAccountService.GetUserAccounts().then((userAccounts) => {
            if (userAccounts !== undefined && userAccounts !== "") {
              this.SurveyOverviewForm.Raise(surveys, userAccounts);
            } else {
              this.setState({ RaiseSnackBar: true, SnackBarMessage: "Error occured while retrieving the user accounts, please try again", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarError", SnackBarSeverity: "error", SnackBarAutoHideDuration: null });
            }
          });
        } else {
          this.setState({ RaiseSnackBar: true, SnackBarMessage: "Error occured while retrieving the surveys, please try again", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarError", SnackBarSeverity: "error", SnackBarAutoHideDuration: null });
        }
      });
    } else {
      if (this.CollapseAll()) {
        if (formName === RiskManagementConstants.CustomRiskLayerNewPolygon) {
          this.CustomRiskLayerFormEdit.Raise(this.CreateNewPolygon());
          this.CustomRiskLayerFormEdit.CurrentComponentChanged();
          this.SetAllCustomRiskLayersVisibility(true);
        } else if (formName === RiskManagementConstants.CustomRiskLayerNewCircle) {
          this.CustomRiskLayerFormEdit.Raise(this.CreateNewCircle());
          this.CustomRiskLayerFormEdit.CurrentComponentChanged();
          this.SetAllCustomRiskLayersVisibility(true);
        } else if (formName === RiskManagementConstants.SupplierManufacturingLocationNew) {
          this.SupplierManufacturingLocationMainForm.Raise(this.CreateNewMarker(), true);
        } else if (formName === RiskManagementConstants.SelectPolygonSML) {
          this.SelectPolygon();
        } else if (formName === RiskManagementConstants.SelectCircleSML) {
          this.SelectCircle();
        } else if (formName === RiskManagementConstants.RetrieveRiskData) {
          this.OnRefreshAllRisks();
        } else if (formName === RiskManagementConstants.UploadedSupplierManufacturingFileList) {
          UserFileUploadService.GetUploadedFiles().then((result) => {
            if (result !== undefined && result !== "") {
              this.UploadedSupplierManufacturingFileList.Raise(result);
            } else {
              this.setState({ RaiseSnackBar: true, SnackBarMessage: "Error occured while retrieving the uploaded files, please try again", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarError", SnackBarSeverity: "error", SnackBarAutoHideDuration: null });
            }
          });
        }
      }
    }
  };

  OnSaveDeletedQuestionnaries = (deletedQuestionnaires) => {
    deletedQuestionnaires.forEach((questionnaire) => {
      QuestionnaireService.DeleteQuestionnaire(questionnaire.questionnaireId);
    });
  };

  OnCreateQuestionnarie = () => {
    let questionnaire = { title: "", externalQuestionnaireId: "" };
    let isNew = true;
    this.QuestionnariesManageForm.Collapse();
    this.QuestionnairesEditForm.Raise(questionnaire, isNew);
  };

  OnEditQuestionnarie = (questionnaire) => {
    let isNew = false;
    this.QuestionnariesManageForm.Collapse();
    this.QuestionnairesEditForm.Raise(questionnaire, isNew);
  };

  OnSaveQuestionnarie = () => {
    QuestionnaireService.GetAllQuestionnaires().then((result) => {
      if (result !== undefined && result !== "") {
        this.QuestionnairesEditForm.Collapse();
        this.QuestionnariesManageForm.Raise(result);
      } else {
        this.setState({ RaiseSnackBar: true, SnackBarMessage: "Error occured while retrieving the questionnaires, please try again", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarError", SnackBarSeverity: "error", SnackBarAutoHideDuration: null });
      }
    });
  };

  OnCancelQuestionnarie = () => {
    QuestionnaireService.GetAllQuestionnaires().then((result) => {
      if (result !== undefined && result !== "") {
        this.QuestionnairesEditForm.Collapse();
        this.QuestionnariesManageForm.Raise(result);
      } else {
        this.setState({ RaiseSnackBar: true, SnackBarMessage: "Error occured while retrieving the questionnaires, please try again", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarError", SnackBarSeverity: "error", SnackBarAutoHideDuration: null });
      }
    });
  };

  OnSaveDeletedSurveys = (deletedSurveys) => {
    deletedSurveys.forEach((survey) => {
      SurveyService.DeleteSurvey(survey.surveyId);
    });
  };

  OnFileUploadError = (files) => {
    this.ProgressBar.Collapse();
    if (files !== "") {
      this.setState({
        RaiseSnackBar: true,
        SnackBarMessage: "Error occured while uploading the files: " + files,
        SnackBarVariantStyle: "SnackBarCommonStyle SnackBarError",
        SnackBarSeverity: "error",
        SnackBarAutoHideDuration: null,
        IsFileUploadInProgress: false,
      });
    }
  };

  OnSmlFileUploadSuccess = (savedSmls) => {
    let currentSmls = [...this.state.SupplierManufacturingLocations];
    let readonlySmls = [...this.state.ReadonlySupplierManufacturingLocations];
    currentSmls.push(...savedSmls);
    readonlySmls.push(...savedSmls);
    this.setState({ SupplierManufacturingLocations: currentSmls, ReadonlySupplierManufacturingLocations: readonlySmls, IsFileUploadInProgress: false });
    this.ProgressBar.Collapse();
    this.state.MapReference.setZoom(this.state.MapReference.getZoom());
  };

  OnFileUploadInProgress = () => {
    this.setState({ IsFileUploadInProgress: true });
    this.ProgressBar.Raise("File upload in progress");
  };

  CollapseAll = (ignoreOpenForms = false) => {
    if (this.CustomRiskLayerFormEdit.GetHasChanges() && !ignoreOpenForms) {
      this.CustomRiskLayerEditCancel(true);
      return false;
    }

    if (this.SupplierManufacturingLocationMainForm.GetHasChanges() && !ignoreOpenForms) {
      this.OnSmlCancel(true);
      return false;
    }

    this.CustomRiskLayerFormEdit.Collapse();
    this.SupplierManufacturingLocationMainForm.Collapse();
    this.ViewContextMenu.Collapse();
    this.FilterContextMenu.Collapse();
    this.UploadedSupplierManufacturingFileList.Collapse();

    if (this.state.CurrentSelectedCustomRiskLayerIndex > -1) {
      let currentItems = this.state.CustomRiskLayerItems;
      let selectedItem = currentItems[this.state.CurrentSelectedCustomRiskLayerIndex];
      selectedItem.isFocused = false;
      currentItems[this.state.CurrentSelectedCustomRiskLayerIndex] = selectedItem;
      this.setState({ CurrentSelectedCustomRiskLayerIndex: -1, CustomRiskLayerItems: currentItems });
    }

    this.UnfocusSelectedSml();
    this.SelectionMainForm.Collapse();
    this.setState({ PolygonalSelectionClicked: false, CircleSelectionClicked: false, PolygonalSelectionObject: null, CircleSelectionObject: null });
    return true;
  };

  UnfocusSelectedSml = () => {
    if (this.state.CurrentSelectedSMLIndex > -1) {
      let currentItems = this.state.SupplierManufacturingLocations;
      let selectedItem = currentItems[this.state.CurrentSelectedSMLIndex];
      selectedItem.isFocused = false;
      currentItems[this.state.CurrentSelectedSMLIndex] = selectedItem;
      this.setState({ CurrentSelectedSMLIndex: -1, SupplierManufacturingLocations: currentItems });
    }
  };

  OnSmlChanged = (sml) => {
    let currentSmlList = this.state.SupplierManufacturingLocations;
    currentSmlList[this.state.CurrentSelectedSMLIndex] = sml;
    this.setState({ SupplierManufacturingLocations: currentSmlList });
    this.state.MapReference.panTo({ lat: sml.latitude, lng: sml.longitude });
  };

  OnMarkerMoved = (newLat, newLng) => {
    let currentSML = this.state.SupplierManufacturingLocations[this.state.CurrentSelectedSMLIndex];
    currentSML.latitude = currentSML.latitude + newLat;
    currentSML.longitude = currentSML.longitude + newLng;
    this.SupplierManufacturingLocationMainForm.OnBlurSml(currentSML, true, false);
  };

  OnSmlCancel = (hasChanges) => {
    if (hasChanges) {
      this.MessageBox.Show(
        "Close supplier manufacturing location",
        "Do you really want to close without saving?",
        () => {},
        () => {
          let currentList = this.state.SupplierManufacturingLocations;
          let currentSelectedItem = currentList[this.state.CurrentSelectedSMLIndex];
          this.SupplierManufacturingLocationMainForm.Collapse();

          if (currentSelectedItem.supplierManufacturingLocationId !== undefined) {
            this.ProgressBar.Raise("Reversing changes to supplier manufacturing location");
            SupplierManufacturingLocationService.GetSupplierManufacturingLocationById(currentSelectedItem.supplierManufacturingLocationId).then((item) => {
              currentList.splice(this.state.CurrentSelectedSMLIndex, 1);
              currentList.push(item);
              this.setState({ SupplierManufacturingLocations: currentList, CurrentSelectedSMLIndex: -1 }, () => {
                this.CollapseAll();
                this.ProgressBar.Collapse();
              });
            });
          } else {
            currentList.splice(this.state.CurrentSelectedSMLIndex, 1);
            this.setState({ SupplierManufacturingLocations: currentList, CurrentSelectedSMLIndex: -1 }, () => {
              this.CollapseAll();
            });
          }
        }
      );
    } else {
      this.CollapseAll();
    }
  };

  OnSmlDelete = () => {
    this.MessageBox.Show(
      "Delete",
      "Do you really want to delete this supplier manufacturing location?",
      () => {},
      () => {
        this.ProgressBar.Raise("Deleting supplier manufacturing location");
        this.CollapseAll(true);

        let currentList = this.state.SupplierManufacturingLocations;
        let currentReadonlyList = this.state.ReadonlySupplierManufacturingLocations;
        let currentSelectedItem = currentList[this.state.CurrentSelectedSMLIndex];
        let smlList = [];

        if (currentSelectedItem.supplierManufacturingLocationId !== undefined) {
          smlList.push(currentSelectedItem.supplierManufacturingLocationId);
          SupplierManufacturingLocationService.DeleteSupplierManufacturingLocation(smlList).then(() => {
            this.ProgressBar.Collapse();
            this.setState({ RaiseSnackBar: true, SnackBarMessage: "SML was deleted successfully!", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarSuccess", SnackBarSeverity: "success", SnackBarAutoHideDuration: 3000 });
          });

          const currentItemIndex = currentReadonlyList.indexOf(currentSelectedItem);
          currentReadonlyList.splice(currentItemIndex, 1);
        }

        currentList.splice(this.state.CurrentSelectedSMLIndex, 1);

        this.setState({ SupplierManufacturingLocations: currentList, CurrentSelectedSMLIndex: -1, ReadonlySupplierManufacturingLocations: currentReadonlyList });
      }
    );
  };

  OnSmlListDelete = (selectedSMLIds) => {
    this.MessageBox.Show(
      "Delete",
      "Do you really want to delete these selected supplier manufacturing locations?",
      () => {},
      () => {
        this.ProgressBar.Raise("Deleting selected supplier manufacturing locations");
        this.CollapseAll(true);

        let currentSupplierManufacturingLocations = [...this.state.SupplierManufacturingLocations];
        let currentReadonlySupplierManufacturingLocations = [...this.state.ReadonlySupplierManufacturingLocations];

        if (selectedSMLIds !== undefined) {
          SupplierManufacturingLocationService.DeleteSupplierManufacturingLocation(selectedSMLIds).then((status) => {
            if (status === 200) {
              selectedSMLIds.forEach((smlId) => {
                let index = currentSupplierManufacturingLocations.findIndex((sml) => sml.supplierManufacturingLocationId === smlId);
                if (index !== undefined && index !== -1) {
                  currentSupplierManufacturingLocations.splice(index, 1);
                }
                let readonlyIndex = currentReadonlySupplierManufacturingLocations.findIndex((sml) => sml.supplierManufacturingLocationId === smlId);
                if (readonlyIndex !== undefined && index !== -1) {
                  currentReadonlySupplierManufacturingLocations.splice(index, 1);
                }
              });
              this.setState({
                SupplierManufacturingLocations: currentSupplierManufacturingLocations,
                ReadonlySupplierManufacturingLocations: currentReadonlySupplierManufacturingLocations,
                RaiseSnackBar: true,
                SnackBarMessage: "Selected supplier manufacturing locations were deleted successfully!",
                SnackBarVariantStyle: "SnackBarCommonStyle SnackBarSuccess",
                SnackBarSeverity: "success",
                SnackBarAutoHideDuration: 3000,
              });
            } else {
              this.setState({
                RaiseSnackBar: true,
                SnackBarMessage: "Error occured while deleting one or more selected supplier manufacturing locations, please try again.",
                SnackBarVariantStyle: "SnackBarCommonStyle SnackBarError",
                SnackBarSeverity: "error",
                SnackBarAutoHideDuration: null,
              });
            }
            this.ProgressBar.Collapse();
          });
        }
      }
    );
  };

  OnRefreshAllRisks = () => {
    this.MessageBox.Show(
      "Initiate revaluation",
      "Do you really want initiate a revaluation of the provider´s risk data?",
      () => {},
      () => {
        this.ProgressBar.Raise("Risk data refresh is in progress");

        SupplierManufacturingLocationService.UpdateAllSmlRiskDataFromDataProvider().then((result) => {
          if (result !== undefined && result !== "") {
            RiskDataUpdateStatusService.GetRiskDataUpdateStatusByID(result).then((riskUpdateResult) => {
              if (riskUpdateResult !== undefined && riskUpdateResult !== "") {
                let ongoingUpdates = [...this.state.OngoingUpdates];
                ongoingUpdates.push(riskUpdateResult);

                let runningInterval = setInterval(this.CheckDataRiskUpdates, oneMinute);
                this.setState({
                  OngoingUpdates: ongoingUpdates,
                  RunningInterval: runningInterval,
                  RaiseSnackBar: true,
                  SnackBarMessage: `Selected supplier manufacturing locations are updating.`,
                  SnackBarVariantStyle: "SnackBarCommonStyle SnackBarSuccess",
                  SnackBarSeverity: "success",
                  SnackBarAutoHideDuration: 3000,
                });
              }
            });
          } else {
            this.setState({
              RaiseSnackBar: true,
              SnackBarMessage: "Error occured while updating risk data for selected supplier manufacturing locations, please try again.",
              SnackBarVariantStyle: "SnackBarCommonStyle SnackBarError",
              SnackBarSeverity: "error",
              SnackBarAutoHideDuration: null,
            });
          }
          this.ProgressBar.Collapse();
        });
      }
    );
  };

  OnRefreshSelectedSmlRisk = (selectedSMLIds) => {
    this.MessageBox.Show(
      "Refresh selected risk data",
      `Do you really want to update the risk data of selected location${selectedSMLIds.length > 1 ? "s" : ""}?`,
      () => {},
      () => {
        this.ProgressBar.Raise("Risk data refresh is in progress");
        this.CollapseAll(true);

        if (selectedSMLIds !== undefined) {
          SupplierManufacturingLocationService.UpdateSmlRiskDataFromDataProvider(selectedSMLIds).then((result) => {
            if (result !== undefined && result !== "") {
              RiskDataUpdateStatusService.GetRiskDataUpdateStatusByID(result).then((riskUpdateResult) => {
                if (riskUpdateResult !== undefined && riskUpdateResult !== "") {
                  let ongoingUpdates = [...this.state.OngoingUpdates];
                  ongoingUpdates.push(riskUpdateResult);

                  let runningInterval = setInterval(this.CheckDataRiskUpdates, oneMinute);
                  this.setState({
                    OngoingUpdates: ongoingUpdates,
                    RunningInterval: runningInterval,
                    RaiseSnackBar: true,
                    SnackBarMessage: `Selected supplier manufacturing location${selectedSMLIds.length > 1 ? "s are" : " is"} updating.`,
                    SnackBarVariantStyle: "SnackBarCommonStyle SnackBarSuccess",
                    SnackBarSeverity: "success",
                    SnackBarAutoHideDuration: 3000,
                  });
                }
              });
            } else {
              this.setState({
                RaiseSnackBar: true,
                SnackBarMessage: `Error occured while updating risk data for selected supplier manufacturing location${selectedSMLIds.length > 1 ? "s" : ""}, please try again.`,
                SnackBarVariantStyle: "SnackBarCommonStyle SnackBarError",
                SnackBarSeverity: "error",
                SnackBarAutoHideDuration: null,
              });
            }
            this.ProgressBar.Collapse();
          });
        }
      }
    );
  };

  CheckDataRiskUpdates = async () => {
    let ongoingUpdates = [...this.state.OngoingUpdates];
    let currentSupplierManufacturingLocations = [...this.state.SupplierManufacturingLocations];
    let currentReadonlySupplierManufacturingLocations = [...this.state.ReadonlySupplierManufacturingLocations];

    let stillOngoingUpdates = [];
    await Promise.all(
      ongoingUpdates.map(async (ongoingUpdate) => {
        const riskDataUpdateStatusResult = await RiskDataUpdateStatusService.GetRiskDataUpdateStatusByID(ongoingUpdate.riskDataUpdateStatusId);
        if (riskDataUpdateStatusResult.status === 2) {
          SupplierManufacturingLocationService.GetSupplierManufacturingLocationsByRiskDataUpdateStatusId(riskDataUpdateStatusResult.riskDataUpdateStatusId).then((updatedSmls) => {
            updatedSmls.forEach((updatedSml) => {
              let index = currentSupplierManufacturingLocations.findIndex((sml) => sml.supplierManufacturingLocationId === updatedSml.supplierManufacturingLocationId);
              if (index !== undefined && index !== -1) {
                currentSupplierManufacturingLocations.splice(index, 1);
                currentSupplierManufacturingLocations.push(updatedSml);
              }
              let readonlyIndex = currentReadonlySupplierManufacturingLocations.findIndex((sml) => sml.supplierManufacturingLocationId === updatedSml.supplierManufacturingLocationId);
              if (readonlyIndex !== undefined && index !== -1) {
                currentReadonlySupplierManufacturingLocations.splice(index, 1);
                currentReadonlySupplierManufacturingLocations.push(updatedSml);
              }
            });
          });
        } else {
          if (riskDataUpdateStatusResult.status !== 3) {
            stillOngoingUpdates.push(riskDataUpdateStatusResult);
          }
        }
      })
    );
    if (stillOngoingUpdates.length === 0) {
      let runningInterval = this.state.RunningInterval;
      clearInterval(runningInterval);
      this.setState({
        RunningInterval: null,
        OngoingUpdates: [],
        SupplierManufacturingLocations: currentSupplierManufacturingLocations,
        ReadonlySupplierManufacturingLocations: currentReadonlySupplierManufacturingLocations,
        RaiseSnackBar: true,
        SnackBarMessage: `Supplier manufacturing locations were updated successfully!`,
        SnackBarVariantStyle: "SnackBarCommonStyle SnackBarSuccess",
        SnackBarSeverity: "success",
        SnackBarAutoHideDuration: 3000,
      });
    } else {
      this.setState({ OngoingUpdates: stillOngoingUpdates, SupplierManufacturingLocations: currentSupplierManufacturingLocations, ReadonlySupplierManufacturingLocations: currentReadonlySupplierManufacturingLocations });
    }
  };

  SaveSml = (RetrieveRiskData) => {
    let currentSML = { ...this.state.SupplierManufacturingLocations[this.state.CurrentSelectedSMLIndex] };
    let oldSelection = this.state.CurrentSelectedSMLIndex;
    currentSML.toBeDeletedFlag = currentSML.toBeDeletedFlag === "true" || currentSML.toBeDeletedFlag === true;

    this.SupplierManufacturingLocationMainForm.Collapse();

    currentSML.isFocused = false;
    let currentList = [...this.state.SupplierManufacturingLocations];
    let currentReadonlyList = [...this.state.ReadonlySupplierManufacturingLocations];
    currentList[oldSelection] = currentSML;
    this.setState({ SupplierManufacturingLocations: currentList, CurrentSelectedSMLIndex: -1 });

    if (currentSML.supplierManufacturingLocationId !== undefined) {
      this.ProgressBar.Raise("Saving changes to supplier manufacturing location");
      SupplierManufacturingLocationService.UpdateSml(currentSML).then((result) => {
        currentSML = result;
        currentSML.isFocused = false;
        currentList = [...this.state.SupplierManufacturingLocations];
        currentList[oldSelection] = currentSML;
        const currentItemIndex = currentReadonlyList.indexOf(currentSML);
        currentReadonlyList[currentItemIndex] = currentSML;
        this.ProgressBar.Collapse();
        this.setState({ SupplierManufacturingLocations: currentList, ReadonlySupplierManufacturingLocations: currentReadonlyList });
        this.setState({ RaiseSnackBar: true, SnackBarMessage: "SML was saved successfully!", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarSuccess", SnackBarSeverity: "success", SnackBarAutoHideDuration: 3000 });
      });
    } else {
      this.ProgressBar.Raise("Saving new supplier manufacturing location");
      SupplierManufacturingLocationService.SaveNewSML(currentSML, RetrieveRiskData).then((result) => {
        currentSML = result;
        currentSML.isFocused = false;
        currentList = [...this.state.SupplierManufacturingLocations];
        currentList[oldSelection] = currentSML;
        currentReadonlyList.push(currentSML);
        this.ProgressBar.Collapse();
        this.setState({ SupplierManufacturingLocations: currentList, ReadonlySupplierManufacturingLocations: currentReadonlyList });
        this.setState({ RaiseSnackBar: true, SnackBarMessage: "SML was saved successfully!", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarSuccess", SnackBarSeverity: "success", SnackBarAutoHideDuration: 3000 });
      });
    }
  };

  OnMapLoad = (mapreference) => {
    this.setState({
      MapReference: mapreference,
    });
  };

  OnSnackBarClose = () => {
    this.setState({
      RaiseSnackBar: false,
      SnackBarMessage: "",
      SnackBarVariantStyle: "",
      SnackBarAutoHideDuration: null,
    });
  };

  OnPlaceChangedSearchbox = ({ lat, lng }) => {
    this.state.MapReference.panTo({ lat, lng });
    this.state.MapReference.setZoom(12);
    this.SupplierManufacturingLocationMainForm.Raise(this.CreateNewMarker(), true);
  };

  OnPlaceChangedSearchboxRestricted = ({ lat, lng }) => {
    this.state.MapReference.panTo({ lat, lng });
    this.state.MapReference.setZoom(12);
  };

  OnFilterApply = (filteredSmls) => {
    // Collapse open windows
    if (this.SupplierManufacturingLocationMainForm.GetHasChanges()) {
      this.OnSmlCancel(true);
      this.FilterContextMenu.Raise();
      return false;
    }

    this.SupplierManufacturingLocationMainForm.Collapse();
    this.UnfocusSelectedSml();
    this.UploadedSupplierManufacturingFileList.Collapse();

    const highlight = !(filteredSmls.length === this.state.ReadonlySupplierManufacturingLocations.length);
    this.setState({ SupplierManufacturingLocations: [...filteredSmls], HighlightFilterIcon: highlight }, () => {
      // update SML selection list if open
      if (this.state.CircleSelectionClicked) {
        this.SetItemsInsideCircleFlag();
      } else if (this.state.PolygonalSelectionClicked) {
        this.SetItemsInsidePolygonFlag();
      }
    });
    this.fitMaptoBounds(filteredSmls);
  };

  OnFilterReset = () => {
    // Collapse open windows
    if (this.SupplierManufacturingLocationMainForm.GetHasChanges()) {
      this.OnSmlCancel(true);
      this.FilterContextMenu.Raise();
      return false;
    }

    this.SupplierManufacturingLocationMainForm.Collapse();
    this.UnfocusSelectedSml();
    this.UploadedSupplierManufacturingFileList.Collapse();

    this.setState(
      (prevState) => ({
        SupplierManufacturingLocations: prevState.ReadonlySupplierManufacturingLocations,
        HighlightFilterIcon: false,
      }),
      () => {
        // update SML selection list if open
        if (this.state.CircleSelectionClicked) {
          this.SetItemsInsideCircleFlag();
        } else if (this.state.PolygonalSelectionClicked) {
          this.SetItemsInsidePolygonFlag();
        }
      }
    );
    this.resetMapBounds();
  };

  fitMaptoBounds = (supplierManufacturingLocations) => {
    if (supplierManufacturingLocations.length) {
      if (supplierManufacturingLocations.length === this.state.ReadonlySupplierManufacturingLocations.length) {
        this.resetMapBounds();
      } else {
        // Prevents fitBounds from zooming in to close
        window.google.maps.event.addListenerOnce(this.state.MapReference, "bounds_changed", () => {
          if (this.state.MapReference.getZoom() > 15) {
            this.state.MapReference.setZoom(15);
          }
        });

        // Iterate loaded locations to zoom in, center, and size the map to include all locations.
        const bounds = new window.google.maps.LatLngBounds();
        supplierManufacturingLocations.forEach((smlItem) => {
          bounds.extend({ lat: smlItem.latitude, lng: smlItem.longitude });
        });
        this.state.MapReference.fitBounds(bounds);
      }
    } else {
      this.resetMapBounds();
    }
  };

  resetMapBounds = () => {
    this.state.MapReference.setCenter(startCenter);
    this.state.MapReference.setZoom(3);
  };

  OnSmlSelectionClose = () => {
    this.setState({ PolygonalSelectionObject: null, PolygonalSelectionClicked: false, CircleSelectionClicked: false, CircleSelectionObject: null });
  };

  ChangeMapType = (mapTypeName) => {
    let ligthTheme = mapTypeName === "roadmap";
    let clusterStyles = [
      {
        textColor: ligthTheme ? Color.White : Color.Grey6,
        url: `data:image/svg+xml;base64,${this.GetClusterIcon(24, ligthTheme)}`,
        height: 24,
        width: 24,
        textSize: 12,
      },
      {
        textColor: ligthTheme ? Color.White : Color.Grey6,
        url: `data:image/svg+xml;base64,${this.GetClusterIcon(32, ligthTheme)}`,
        height: 24,
        width: 32,
        textSize: 12,
      },
      {
        textColor: ligthTheme ? Color.White : Color.Grey6,
        url: `data:image/svg+xml;base64,${this.GetClusterIcon(40, ligthTheme)}`,
        height: 24,
        width: 40,
        textSize: 12,
      },
    ];

    this.setState({ ClusterStyles: clusterStyles, DisplayedMapType: mapTypeName });
    this.state.MapReference.setZoom(this.state.MapReference.getZoom());
  };

  ToogleRiskLayer = (riskLayer) => {
    let displayedRiskOverlays = this.state.DisplayedRiskOverlays;

    if (riskLayer.IsVisible) {
      displayedRiskOverlays.push(riskLayer);
    } else {
      displayedRiskOverlays.splice(displayedRiskOverlays.indexOf(riskLayer), 1);
    }

    this.setState({ DisplayedRiskOverlays: displayedRiskOverlays }, () => {
      if (riskLayer.IsVisible) {
        this.CalculateAndLoadTilesOfCurrentMap(riskLayer.Name);
        //render new added risklayer
      } else {
        // remove deleted risklayer from visible overlay list
        let removeInvisibleOverlays = async () => {
          let currentDisplayedTiles = this.state.DisplayedTiles;
          let indexes = [];

          for (let tileIndex = 0; tileIndex < currentDisplayedTiles.length; tileIndex++) {
            let currentTile = currentDisplayedTiles[tileIndex];
            if (currentTile.RiskName === riskLayer.Name) {
              indexes.push(tileIndex);
            }
          }

          indexes.sort(function (a, b) {
            return b - a;
          });

          for (let index = 0; index < indexes.length; index++) {
            currentDisplayedTiles.splice(indexes[index], 1);
          }

          this.setState({ DisplayedTiles: currentDisplayedTiles });
        };

        removeInvisibleOverlays();
      }
    });
  };

  CreateRiskOverlayRequestModel = (hazardLayer, swlng, swlat, nelng, nelat, zoom, row, column, lastItem) => {
    return {
      riskName: hazardLayer,
      southWestLongitude: swlng,
      southWestLatitude: swlat,
      northEastLongitude: nelng,
      northEastLatitude: nelat,
      zoomFactor: zoom,
      row: row,
      column: column,
      isLastForZoomFactor: lastItem,
    };
  };

  CalculateTileWidthAndHeight = (startZoom, zoomFactor) => {
    let tileWidth = 360;
    let tileHeight = 180;
    let size = 256;
    for (let i = startZoom; i < zoomFactor; i++) {
      tileWidth = tileWidth / 2;
      tileHeight = tileHeight / 2;
      size = size / 2;
    }
    return {
      tileWidth,
      tileHeight,
      size,
    };
  };

  CalculateAndLoadTilesOfCurrentMap = async (riskLayerName) => {
    let zoomFactor = this.state.MapReference.getZoom();
    let startZoom = 0;
    let { size } = this.CalculateTileWidthAndHeight(startZoom, zoomFactor);

    let nePoint = this.state.MapReference.getProjection().fromLatLngToPoint(this.state.MapReference.getBounds().getNorthEast());
    let swPoint = this.state.MapReference.getProjection().fromLatLngToPoint(this.state.MapReference.getBounds().getSouthWest());

    let startRow = Math.floor(nePoint.y / size) - 1;
    let endRow = Math.ceil(swPoint.y / size) + 1;

    let startColumn = Math.floor(swPoint.x / size) - 1;
    let endColumn = Math.ceil(nePoint.x / size) + 1;

    let cachedTiles = this.state.CachedTiles;
    let newTiles = this.state.DisplayedTiles;

    for (let row = startRow; row < endRow; row++) {
      for (let column = startColumn; column < endColumn; column++) {
        let coordinatesNE = this.state.MapReference.getProjection().fromPointToLatLng(new window.google.maps.Point(size * column + size, size * row)); //column, row
        let coordinatesSW = this.state.MapReference.getProjection().fromPointToLatLng(new window.google.maps.Point(size * column, size * row + size));

        let isLastItem = row === endRow - 1 && column === endColumn - 1;

        let cached = false;
        for (let i = 0; i < cachedTiles.length; i++) {
          let item = cachedTiles[i];

          if (item.RiskName === riskLayerName && item.ZoomFactor === zoomFactor && item.Row === row && item.Column === column) {
            if (this.state.DisplayedTiles.indexOf(item) < 0) {
              newTiles.push(item);
            }

            cached = true;
            break;
          }
        }

        if (!cached) {
          let controller = new AbortController();
          let request = {
            request: controller,
          };
          let requests = this.state.LoadTilesRequests;
          requests.push(request);
          let signal = controller.signal;
          this.setState({ LoadTilesRequests: requests }, () => {
            let model = this.CreateRiskOverlayRequestModel(riskLayerName, coordinatesSW.lng(), coordinatesSW.lat(), coordinatesNE.lng(), coordinatesNE.lat(), zoomFactor, row, column, isLastItem);
            RiskLayerService.LoadMapTile(signal, model).then((result) => {
              if (result !== "aborted") {
                const tileResult = this.CalculateTileWidthAndHeight(startZoom, zoomFactor);
                const newTileWidth = tileResult.tileWidth,
                  newTileHeight = tileResult.tileHeight;

                let newTile = {
                  Bounds: {
                    sw: { lat: result.northEastLatitude, lng: result.northEastLongitude },
                    ne: { lat: result.southWestLatitude, lng: result.southWestLongitude },
                  },
                  Image: "url('data:image/jpeg;base64," + result.picture + "')",
                  ZoomFactor: result.zoomFactor,
                  RiskName: result.riskName,
                  Row: result.row,
                  Column: result.column,
                };
                let currentDisplayedTiles = this.state.DisplayedTiles;
                let currentZoom = this.state.MapReference.getZoom();
                let alreadyContained = currentDisplayedTiles.findIndex((item) => item.Row === newTile.Row && item.Column === newTile.Column && item.ZoomFactor === newTile.ZoomFactor && item.RiskName === newTile.RiskName) < 0;

                if (currentZoom === newTile.ZoomFactor && this.IsInVisibleSector(newTile.Bounds.sw.lat, newTile.Bounds.sw.lng, newTileWidth, newTileHeight) && alreadyContained) {
                  currentDisplayedTiles.push(newTile);
                }

                cachedTiles.push(newTile);
                this.setState({ DisplayedTiles: currentDisplayedTiles, CachedTiles: cachedTiles });
                if (result.isLastForZoomFactor) {
                  this.RemoveInvisibleOverlays();
                }

                this.setState({ MapGestureHandling: "auto" });
              }
            });
          });
        } else {
          this.RemoveInvisibleOverlays();
          this.setState({ DisplayedTiles: newTiles, CachedTiles: cachedTiles, MapGestureHandling: "auto" });
        }
      }
    }
  };

  IsInVisibleSector = (lat, lng, tileWidth, tileHeight) => {
    let northEastLat = this.state.MapReference.getBounds().getNorthEast().lat();
    let northEastLng = this.state.MapReference.getBounds().getNorthEast().lng();
    let southWestLat = this.state.MapReference.getBounds().getSouthWest().lat();
    let southWestLng = this.state.MapReference.getBounds().getSouthWest().lng();

    if ((lat + tileHeight >= southWestLat && lng + tileWidth >= southWestLng) || (lat < northEastLat && lng < northEastLng)) {
      return true;
    }

    return false;
  };

  OnZoomChanged = () => {
    if (this.state.MapReference !== null && this.state.MapReference !== undefined) {
      if (this.state.DisplayedRiskOverlays.length > 0) {
        this.HandleZoomChanged();
      }
    }
  };

  HandleZoomChanged = async () => {
    let cancelOpenRequests = async () => {
      for (let i = 0; i < this.state.LoadTilesRequests.length; i++) {
        let request = this.state.LoadTilesRequests[i];
        if (request !== undefined && request.request !== undefined) {
          request.request.abort();
        }
      }
      this.setState({ LoadTilesRequests: [] });
    };

    cancelOpenRequests();

    let getTiles = async () => {
      for (let i = 0; i < this.state.DisplayedRiskOverlays.length; i++) {
        let riskOverlay = this.state.DisplayedRiskOverlays[i];
        this.CalculateAndLoadTilesOfCurrentMap(riskOverlay.Name);
      }
    };

    getTiles();
  };

  RemoveInvisibleOverlays = async () => {
    const currentZoom = this.state.MapReference.getZoom();
    const startZoom = 0;
    const { tileWidth, tileHeight } = this.CalculateTileWidthAndHeight(startZoom, currentZoom);

    let currentDisplayedTiles = this.state.DisplayedTiles;
    let indexes = [];

    for (let tileIndex = 0; tileIndex < currentDisplayedTiles.length; tileIndex++) {
      let currentTile = currentDisplayedTiles[tileIndex];
      if (currentZoom !== currentTile.ZoomFactor || !this.IsInVisibleSector(currentTile.Bounds.sw.lat, currentTile.Bounds.sw.lng, tileWidth, tileHeight)) {
        indexes.push(tileIndex);
      }
    }

    indexes.sort(function (a, b) {
      return b - a;
    });

    for (let index = 0; index < indexes.length; index++) {
      currentDisplayedTiles.splice(indexes[index], 1);
    }

    this.setState({ DisplayedTiles: currentDisplayedTiles });
  };

  OnMouseDown = () => {
    this.setState({ mapMouseDown: true });
  };

  OnMouseUp = () => {
    if (this.state.MapReference !== null && this.state.mapMouseDown) {
      this.setState({ centerChanged: false, mapMouseDown: false });
      let getTiles = async () => {
        for (let i = 0; i < this.state.DisplayedRiskOverlays.length; i++) {
          let riskOverlay = this.state.DisplayedRiskOverlays[i];
          this.CalculateAndLoadTilesOfCurrentMap(riskOverlay.Name);
        }
      };

      getTiles();
    }
  };

  OnRaiseSurveyCreateConfirmation = (survey) => {
    this.SurveyCreateConfirmation.Raise(survey);
  };

  OnCancelSurveyConfirmation = () => {
    this.SurveyCreateForm.Show();
  };

  OnSaveSurvey = (isSurveyCreated) => {
    if (isSurveyCreated) {
      this.setState({ RaiseSnackBar: true, SnackBarMessage: "Survey created successfully!", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarSuccess", SnackBarSeverity: "success", SnackBarAutoHideDuration: 3000 });
      // this.SurveyCreateForm.Collapse();
      // this.SurveyCreateConfirmation.Collapse();
    } else {
      this.setState({ RaiseSnackBar: true, SnackBarMessage: "Error occured while starting the survey, please try again!", SnackBarVariantStyle: "SnackBarCommonStyle SnackBarError", SnackBarSeverity: "error", SnackBarAutoHideDuration: 3000 });
    }
  };

  ToggleSingleCustomRiskLayer = (index) => {
    let customRiskLayers = this.state.CustomRiskLayerItems;
    customRiskLayers[index].IsVisible = !customRiskLayers[index].IsVisible;
    this.setState({ CustomRiskLayerItems: customRiskLayers }, () => {
      let res = true;
      for (let i = 0; i < customRiskLayers.length; i++) {
        if (customRiskLayers[i].IsVisible) {
          res = res && true;
        } else {
          res = res && false;
        }
      }
      this.ViewContextMenu.ChangeCustomRiskLayer(res);
      this.setState({ DisplayCustomRiskLayer: res });
    });
  };

  render = () => {
    return (
      <>
        <NotificationBackground
          ref={(notificationBackground) => {
            this.NotificationBackground = notificationBackground;
          }}
        />

        <MessageBox
          ref={(messageBox) => {
            this.MessageBox = messageBox;
          }}
          NotificationBackground={this.NotificationBackground}
        ></MessageBox>
        {this.state.Features.find((key) => key === FeatureConstants.Survey) && (
          <>
            <div>
              <QuestionnariesManageForm
                ref={(questionnariesManageForm) => {
                  this.QuestionnariesManageForm = questionnariesManageForm;
                }}
                NotificationBackground={this.NotificationBackground}
                OnEdit={this.OnEditQuestionnarie}
                OnCreate={this.OnCreateQuestionnarie}
                OnSave={this.OnSaveDeletedQuestionnaries}
              ></QuestionnariesManageForm>
              <QuestionnaireEditForm
                ref={(questionnairesEditForm) => {
                  this.QuestionnairesEditForm = questionnairesEditForm;
                }}
                NotificationBackground={this.NotificationBackground}
                OnCancel={this.OnCancelQuestionnarie}
                OnSave={this.OnSaveQuestionnarie}
              ></QuestionnaireEditForm>
              <SurveyCreateForm
                ref={(surveyCreateForm) => {
                  this.SurveyCreateForm = surveyCreateForm;
                }}
                NotificationBackground={this.NotificationBackground}
                OnShowSurveyCreateConfirmation={this.OnRaiseSurveyCreateConfirmation}
              ></SurveyCreateForm>
              <SurveyOverviewForm
                ref={(surveyOverviewForm) => {
                  this.SurveyOverviewForm = surveyOverviewForm;
                }}
                NotificationBackground={this.NotificationBackground}
                OnSave={this.OnSaveDeletedSurveys}
              ></SurveyOverviewForm>
            </div>
            <div>
              <SurveyCreateConfirmation
                ref={(surveyCreateConfirmation) => {
                  this.SurveyCreateConfirmation = surveyCreateConfirmation;
                }}
                NotificationBackground={this.NotificationBackground}
                OnCancel={this.OnCancelSurveyConfirmation}
                OnSave={this.OnSaveSurvey}
              ></SurveyCreateConfirmation>
            </div>
          </>
        )}
        <UploadSupplierManufacturingFileForm
          ref={(uploadFileForm) => {
            this.UploadSupplierManufacturingFileForm = uploadFileForm;
          }}
          OnFileUploadError={this.OnFileUploadError}
          OnUploadSuccess={this.OnSmlFileUploadSuccess}
          OnUploadInprogress={this.OnFileUploadInProgress}
        />
        <UploadRiskLayerFileForm
          ref={(uploadFileForm) => {
            this.UploadRiskLayerFileForm = uploadFileForm;
          }}
          OnFileUploadError={this.OnFileUploadError}
          SaveCustomRiskLayerList={this.SaveCustomRiskLayerList}
          OnUploadInprogress={this.OnFileUploadInProgress}
        />
        <div className="MainBox">
          <div className="MainHeader">
            <MenuBar RaiseEvent={this.RaiseForm} OnPlaceChangedSearchbox={this.OnPlaceChangedSearchbox} OnPlaceChangedSearchboxRestricted={this.OnPlaceChangedSearchboxRestricted} HighlightFilterIcon={this.state.HighlightFilterIcon} EnabledFeatures={this.state.Features} OngoingUpdates={this.state.OngoingUpdates}></MenuBar>
          </div>
          {RiskManagementKeycloak.API_Key !== "" && (
            <div className="MapContainer">
              <LoadScript googleMapsApiKey={RiskManagementKeycloak.API_Key} libraries={libraries}>
                <GoogleMap
                  onMouseDown={this.OnMouseDown}
                  onMouseUp={this.OnMouseUp}
                  options={{
                    disableDefaultUI: true,
                    fullscreenControl: false,
                    zoomControl: false,
                    scaleControl: true,
                    streetViewControl: false,
                    rotateControl: false,
                    mapTypeControl: false,
                    minZoom: 3,
                    mapTypeId: this.state.DisplayedMapType, //satellite, roadmap, hybrid, terrain
                    gestureHandling: this.state.MapGestureHandling, // "none",//auto
                  }}
                  center={startCenter}
                  zoom={3}
                  onZoomChanged={this.OnZoomChanged}
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                  onLoad={this.OnMapLoad}
                >
                  {this.state.DisplayedTiles.map((tile) => (
                    <OverlayView className="Overlay" mapPaneName={OverlayView.MAP_PANE} bounds={tile.Bounds}>
                      <div className="OverlayImage" style={{ backgroundImage: tile.Image, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", opacity: 0.6 / this.state.DisplayedRiskOverlays.length }}></div>
                    </OverlayView>
                  ))}
                  {(RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_Visitor || RiskManagementKeycloak.Has_Role_CategoryManager) && (
                    <MarkerClusterer
                      averageCenter
                      gridSize={120}
                      maxZoom={8}
                      styles={this.state.ClusterStyles}
                      minimumClusterSize={10}
                      calculator={(markers, numStyles) => {
                        let index = 0;
                        let count = markers.length;
                        let selectedStyle = count > 99 ? numStyles[2] : numStyles[1];
                        let dv = count;
                        while (dv !== 0) {
                          dv = Math.floor(dv / 10);
                          index++;
                        }
                        index = Math.min(index, numStyles);

                        return {
                          text: count > 99 ? "99+" : count,
                          index: index,
                          title: "",
                          style: selectedStyle,
                        };
                      }}
                    >
                      {(clusterer) => {
                        // Move map to render markers where noClusterRedraw = true
                        this.state.MapReference.setCenter(this.state.MapReference.getCenter());
                        return this.state.SupplierManufacturingLocations.map((smlItem) => (
                          <Marker
                            key={smlItem.supplierManufacturingLocationId}
                            Clusterer={smlItem.IsNew ? undefined : clusterer}
                            NoClustererRedraw={smlItem.IsNew ? false : true}
                            Latitude={smlItem.latitude}
                            Longitude={smlItem.longitude}
                            IsFocused={smlItem.isFocused}
                            IsEditable={smlItem.belongsToUserId !== null || smlItem.belongsToSmaId !== null}
                            CalculatedHighColor={
                              RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_CategoryManager
                                ? this.state.DisplayMitigatedRisk && smlItem.mitigatedColor != null
                                  ? smlItem.mitigatedColor.colorValue
                                  : this.state.DisplayAggregatedRisks && smlItem.calculatedHighColor.colorValue
                                  ? smlItem.calculatedHighColor.colorValue
                                  : Color.Grey5
                                : Color.Grey5
                            }
                            OnClick={() => {
                              this.SupplierManufacturingLocationOnClick(this.state.SupplierManufacturingLocations.indexOf(smlItem));
                            }}
                            OnMove={this.OnMarkerMoved}
                          ></Marker>
                        ));
                      }}
                    </MarkerClusterer>
                  )}
                  {this.state.CustomRiskLayerItems.map(
                    (item) =>
                      item.isCircle &&
                      item.IsVisible && (
                        <Circle
                          LightColor={this.state.DisplayedMapType === "roadmap"}
                          Circle={item}
                          OnClick={
                            RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner
                              ? () => {
                                  this.CustomRiskLayerItemOnClick(this.state.CustomRiskLayerItems.indexOf(item));
                                }
                              : () => {}
                          }
                          OnChange={this.CustomRiskLayerOnChange}
                        />
                      )
                  )}

                  {this.state.CustomRiskLayerItems.map(
                    (item) =>
                      item.isPolygon &&
                      item.IsVisible && (
                        <Polygon
                          LightColor={this.state.DisplayedMapType === "roadmap"}
                          Polygon={item}
                          OnClick={
                            RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner
                              ? () => {
                                  this.CustomRiskLayerItemOnClick(this.state.CustomRiskLayerItems.indexOf(item));
                                }
                              : () => {}
                          }
                          OnChange={this.CustomRiskLayerOnChange}
                        />
                      )
                  )}

                  {this.state.PolygonalSelectionClicked && <PolygonSelection LightColor={this.state.DisplayedMapType === "roadmap"} Polygon={this.state.PolygonalSelectionObject} OnChange={this.PolygonSelectionChange}></PolygonSelection>}
                  {this.state.CircleSelectionClicked && <CircleSelection LightColor={this.state.DisplayedMapType === "roadmap"} Circle={this.state.CircleSelectionObject} OnChange={this.CircleSelectionChange}></CircleSelection>}
                </GoogleMap>
              </LoadScript>
              <CustomRiskLayerFormEdit
                ref={(customRiskLayerForm) => {
                  this.CustomRiskLayerFormEdit = customRiskLayerForm;
                }}
                OnChange={this.CustomRiskLayerOnChange}
                OnSave={this.SaveCustomRiskLayer}
                OnCancel={this.CustomRiskLayerEditCancel}
                OnDelete={() => this.DeleteCustomRiskLayer([this.state.CustomRiskLayerItems[this.state.CurrentSelectedCustomRiskLayerIndex].customRiskLayerId])}
              />
              <SupplierManufacturingLocationMainForm
                ref={(smlMainForm) => {
                  this.SupplierManufacturingLocationMainForm = smlMainForm;
                }}
                CreatedByUser={false}
                IsNew={false}
                OnChange={this.OnSmlChanged}
                OnSave={this.SaveSml}
                OnCancel={this.OnSmlCancel}
                OnDelete={this.OnSmlDelete}
                OnRefresh={this.OnRefreshSelectedSmlRisk}
                DisplayMitigatedRisk={this.state.DisplayMitigatedRisk}
                DisplayAggregatedRisks={this.state.DisplayAggregatedRisks}
                OngoingUpdates={this.state.OngoingUpdates}
              />
              <FilterContextMenu
                ref={(filterContextMenu) => {
                  this.FilterContextMenu = filterContextMenu;
                }}
                OnApply={this.OnFilterApply}
                OnReset={this.OnFilterReset}
                OnShowList={() => this.SelectionMainForm.Raise(this.state.SupplierManufacturingLocations, [])}
                SupplierManufacturingLocations={this.state.ReadonlySupplierManufacturingLocations}
              />
              <ViewContextMenu
                ref={(viewContextMenu) => {
                  this.ViewContextMenu = viewContextMenu;
                }}
                ToggleDisplayedRiskColor={(showMitigatedRisk, showAggregatedRisks) => {
                  this.setState({ DisplayMitigatedRisk: showMitigatedRisk, DisplayAggregatedRisks: showAggregatedRisks });
                }}
                ToggleMapType={(mapTypeName) => {
                  this.ChangeMapType(mapTypeName);
                }}
                ToggleAllCustomRiskLayer={() => {
                  let item = !this.state.DisplayCustomRiskLayer;
                  this.SetAllCustomRiskLayersVisibility(item);
                }}
                CustomRiskLayerEnabled={this.state.DisplayCustomRiskLayer}
                ToogleRiskLayer={this.ToogleRiskLayer}
                CustomRiskLayerList={this.state.CustomRiskLayerItems}
                ToggleCustomRiskLayer={this.ToggleSingleCustomRiskLayer}
              />
              <UploadedSupplierManufacturingFileList
                ref={(displayFilesForm) => {
                  this.UploadedSupplierManufacturingFileList = displayFilesForm;
                }}
                OnDelete={this.DeleteSelectedFiles}
              />
              <RiskRequestCount
                ref={(displayFilesForm) => {
                  this.RiskRequestCount = displayFilesForm;
                }}
              />
              <SelectionMainForm
                ref={(displaySmls) => {
                  this.SelectionMainForm = displaySmls;
                }}
                OnClose={this.OnSmlSelectionClose}
                OnDeleteRiskLayer={this.DeleteCustomRiskLayer}
                OnDeleteSml={this.OnSmlListDelete}
                OnRefreshSml={this.OnRefreshSelectedSmlRisk}
                OnShowSurveyCreate={this.RaiseSurveyCreateForm}
                EnabledFeatures={this.state.Features}
                OngoingUpdates={this.state.OngoingUpdates}
              />
              <ProgressBar
                ref={(progressBar) => {
                  this.ProgressBar = progressBar;
                }}
              ></ProgressBar>
              <Snackbar open={this.state.RaiseSnackBar} severity={this.state.SnackBarSeverity} autoHideDuration={this.state.SnackBarAutoHideDuration} onClose={this.OnSnackBarClose}>
                <SnackbarContent
                  classes={{ root: this.state.SnackBarVariantStyle }}
                  message={this.state.SnackBarMessage}
                  action={
                    <IconButton onClick={this.OnSnackBarClose}>
                      <Close classes={{ root: "SnackBarCloseIcon" }} />
                    </IconButton>
                  }
                />
              </Snackbar>
            </div>
          )}
        </div>
        <style jsx>
          {`
            .Overlay {
              border: none;
              z-index: 49;
            }
            .OverlayImage {
              width: 100%;
              height: 100%;
              position: absolute;
              opacity: 0.6;
              z-index: 49;
            }
            .MainBox {
              display: flex;
              flex-flow: column;
              height: 100%;
              position: absolute;
              width: 100%;
            }
            .MainHeader {
              flex: 0 1 auto;
              width: 100%;
              height: 55px;
            }
            .MapContainer {
              width: 100%;
              height: 100%;
            }
            .SnackBarCloseIcon {
              color: white;
            }
            .SnackBarError {
              background-color: ${Color.Red1};
            }
            .SnackBarSuccess {
              background-color: ${Color.Green1};
            }
            .SnackBarCommonStyle {
              color: ${Color.White};
              display: flex;
              padding: 6px 16px;
              flex-grow: 1;
              flex-wrap: wrap;
              font-size: 0.875rem;
              align-items: center;
              font-weight: 400;
              line-height: 1.43;
              border-radius: 4px;
              letter-spacing: 0.01071em;
            }
          `}
        </style>
      </>
    );
  };
}
export default MainContainer;
