import { Card, CardContent, CardHeader, Collapse, Divider } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { IconButton, TextField } from "@supplyon/ui-components";
import React, { Component } from "react";

/*  Props: 
        properties:

            SupplierManufacturingLocation: the current on map selected sml
*/

class SupplierManufacturingLocationRelations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsRaised: false,
      Relations: [],
    };
  }

  componentDidMount = () => {
    let relations = [];
    for (let i = 0; i < this.props.SupplierManufacturingLocation.supplyRelations.length; i++) {
      let item = this.props.SupplierManufacturingLocation.supplyRelations[i];
      item.isRaised = false;
      if (item.supplier === undefined || item.supplier === null) {
        item.supplier = {};
      }
      if (item.supplier.supplierTypes === undefined || item.supplier.supplierTypes === null || item.supplier.supplierTypes.length === 0) {
        item.supplier.supplierTypes = [{}];
      }
      relations.push(item);
    }
    this.setState({ Relations: relations });   
  };

  ItemClick = (itemIndex) => {
    let item = this.state.Relations[itemIndex];
    let relations = this.state.Relations;
    item.isRaised = !item.isRaised;
    relations[itemIndex] = item;
    this.setState({ Relations: relations });    
  };

  render = () => {
    return (
      <>
        <div className="SupplierManufacturingLocationRelations_Node">
          {this.state.Relations.map((relation) => (
            <Card className="SupplierManufacturingLocationRelations_RelationNode">
              <CardHeader
                action={
                  <>
                    <IconButton
                      onClick={() => {
                        this.ItemClick(this.state.Relations.indexOf(relation));
                      }}
                    >
                      {relation.isRaised ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </>
                }
                title={
                  <>
                    <label>{relation.supplier.name}:</label>
                    <label className="SupplierManufacturingLocationRelations_LowerLabel">{relation.materialNumber}</label>
                  </>
                }
              ></CardHeader>
              <Collapse in={relation.isRaised}>
                <Divider />
                <CardContent style={{ height: "424px" }}>
                  <div className="SupplierManufacturingLocationRelations_GeneralDataContainerRow">
                    <TextField className="SupplierManufacturingLocationRelations_Textbox" label="Material id" required={true} value={relation.materialKey} disabled={true} />
                    <TextField className="SupplierManufacturingLocationRelations_Textbox" label="Material number" required={true} value={relation.materialNumber} disabled={true} />
                  </div>
                  <div className="SupplierManufacturingLocationRelations_GeneralDataContainerRow">
                    <TextField className="SupplierManufacturingLocationRelations_Textbox" label="Material status long" required={true} value={relation.materialStatusLong} disabled={true} />
                    <TextField className="SupplierManufacturingLocationRelations_Textbox" label="Material status short" required={true} value={relation.materialStatusShort} disabled={true} />
                  </div>
                  <div className="SupplierManufacturingLocationRelations_GeneralDataContainerRow">
                    <TextField className="SupplierManufacturingLocationRelations_Textbox" label="Supplier name" required={true} value={relation.supplier.name} disabled={true} />
                    <TextField className="SupplierManufacturingLocationRelations_Textbox" label="Supplier class" required={true} value={relation.supplier.supplierClass} disabled={true} />
                  </div>
                  <div className="SupplierManufacturingLocationRelations_GeneralDataContainerRow">
                    <TextField className="SupplierManufacturingLocationRelations_Textbox" label="D-U-N-S no" required={true} value={relation.supplier.duns} disabled={true} />
                    <TextField className="SupplierManufacturingLocationRelations_Textbox" label="Internal supplier id" required={true} value={relation.supplier.internalSupplierId} disabled={true} />
                  </div>
                  <div className="SupplierManufacturingLocationRelations_GeneralDataContainerRow">
                    <TextField className="SupplierManufacturingLocationRelations_Textbox" label="Supplier id" required={true} value={relation.supplier.supplierKey} disabled={true} />
                    <TextField className="SupplierManufacturingLocationRelations_Textbox" label="Supplier type name" required={true} value={relation.supplier.supplierTypes[0].name} disabled={true} />
                  </div>
                </CardContent>
              </Collapse>
            </Card>
          ))}
        </div>
        <style jsx>
          {`
            .SupplierManufacturingLocationRelations_LowerLabel {
              font-weight: normal;
              margin-left: 10px;
            }
            .SupplierManufacturingLocationRelations_Textbox {
              width: 231px;
            }
            .SupplierManufacturingLocationRelations_GeneralDataContainerRow {
              display: flex;
              justify-content: space-between;
              width: 100%;
            }
            .SupplierManufacturingLocationRelations_RelationNode {
              width: 534px;
              position: relative;
              left: 50%;
              transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              margin-top: 16px;
            }
            .SupplierManufacturingLocationRelations_Node {
              width: 100%;
              height: 100%;
              overflow-y: scroll;
              padding-top: 7px;
            }
          `}
        </style>
      </>
    );
  };
}
export default SupplierManufacturingLocationRelations;
