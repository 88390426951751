import { Button, ButtonVariant, TextField, SingleComboBox, DatePicker } from "@supplyon/ui-components";
import React, { Component } from "react";
import { FormControlLabel, Switch, Checkbox, MuiThemeProvider, Paper, Table, TableBody, TableCell, TableHead, TableRow, createTheme } from "@material-ui/core/";
import Color from "../../libraries/SupplyOnUiConstants";
import NotificationBackground from "../NotificationBackground";
import { Save, Cancel } from "@material-ui/icons/";
import { MuiPickersUtilsProvider } from "@material-ui/pickers/";
import DayjsUtils from "@date-io/dayjs";

const theme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        width: "100%",
        overflow: "auto",
        boxShadow: "none !important",
      },
    },
    MuiTableCell: {
      root: {
        padding: "0px",
        fontSize: "12px",
      },
      head: {
        color: Color.Grey5,
        backgroundColor: Color.Grey1,
        borderTop: "none",
      },
    },
    MuiSvgIcon: {
      root: {
        height: "16px",
        width: "16px",
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: "7.5px 7.5px 7.5px 7.5px",
        marginLeft: "16.5px",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: Color.Blue1,
        },
      },
    },
    MuiIconButton: {
      colorSecondary: {
        "&:hover": {
          color: Color.Blue1,
          backgroundColor: "rgba(50, 50, 50, 0.10)",
        },
      },
    },
  },
});
const dateFormat = "YYYY-MM-DD";

class SurveyCreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OnCancel: () => {},
      OnConfirm: () => {},
      IsRaised: false,
      Survey: null,
      SurveyContacts: [],
      Questionnaires: [],
      UserCompanyMapping: [],
      AllSurveyContactsSelected: true,
      IsImmediateStart: true,
    };
  }

  Raise = (questionnaires, smls, userCompanyMapping) => {
    let surveyContacts = [];
    smls.forEach((sml) => {
      let smaIDs = this.GetSMAMapping(sml, userCompanyMapping);
      let email = this.GetEmailMapping(sml, userCompanyMapping);
      surveyContacts.push({
        supplierManufacturingLocationId: sml.supplierManufacturingLocationId,
        email: email,
        location: sml.name,
        cdm: sml.supplyRelations == null || sml.supplyRelations.length === 0 ? "" : sml.supplyRelations[0].supplierKey,
        supplierName: sml.supplyRelations[0] == null ? "" : sml.supplyRelations[0].supplier.name,
        isCheckedFlag: true,
        hasNotifyAll: smaIDs !== undefined,
        hasMatch: smaIDs !== undefined,
        smaIDs: smaIDs,
      });
    });

    surveyContacts.sort(function (a, b) {
      var textA = a.location.toLowerCase();
      var textB = b.location.toLowerCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    this.setState(
      {
        IsRaised: true,
        Questionnaires: questionnaires,
        Survey: { questionnaireId: "", hasCompanyAccess: false },
        SurveyContacts: surveyContacts,
        UserCompanyMapping: userCompanyMapping,
        IsImmediateStart: true,
        IsHidden: false,
      },
      () => {
        if (this.NotificationBackground) {
          this.NotificationBackground.Raise();
        }
      }
    );
  };

  GetSMAMapping = (sml, userCompanyMapping) => {
    if (sml.supplyRelations?.length > 0 && sml.supplyRelations[0].supplier.supplierKey) {
      const mappedIndex = userCompanyMapping.findIndex((x) => x.supplierCDM2Email.cdmno === sml.supplyRelations[0].supplier.supplierKey);
      if (!(mappedIndex === -1) && userCompanyMapping[mappedIndex].smaIDs.length > 0) {
        return userCompanyMapping[mappedIndex].smaIDs;
      }
    }

    return undefined;
  };

  GetEmailMapping = (sml, userCompanyMapping) => {
    if (sml.supplyRelations?.length > 0 && sml.supplyRelations[0].supplier.supplierKey) {
      const mappedCDM = userCompanyMapping.find((x) => x.supplierCDM2Email.cdmno === sml.supplyRelations[0].supplier.supplierKey);
      if (mappedCDM !== undefined) {
        return mappedCDM.supplierCDM2Email.accMgr_EMail ? mappedCDM.supplierCDM2Email.accMgr_EMail : mappedCDM.supplierCDM2Email.psB_EMail ? mappedCDM.supplierCDM2Email.psB_EMail : mappedCDM.supplierCDM2Email.infoEmail;
      }
    }

    return undefined;
  };

  Collapse = () => {
    this.NotificationBackground.Collapse();
    this.setState({ OnCancel: () => {}, OnConfirm: () => {}, Survey: null, SurveyContacts: [], SupplierManufacturingLocations: [], Questionnaires: [], AllSurveyContactsSelected: true, IsRaised: false, IsImmediateStart: true });
  };

  OnConfirmSurvey = () => {
    let survey = {...this.state.Survey};
    let surveyContacts = [...this.state.SurveyContacts];
    survey.surveyContacts = surveyContacts.filter((x) => x.isCheckedFlag);
    survey.ReadyForStart = true;
    this.setState({ IsHidden: true });
    this.props.OnShowSurveyCreateConfirmation(survey);
  };

  OnConfirmDraftSurvey = () => {
    let survey = {...this.state.Survey};
    let surveyContacts = [...this.state.SurveyContacts];
    survey.surveyContacts = surveyContacts.filter((x) => x.isCheckedFlag);
    survey.ReadyForStart = false;
    this.setState({ IsHidden: true });
    this.props.OnShowSurveyCreateConfirmation(survey);
  };

  OnCancel = () => {
    this.state.OnCancel();
    this.Collapse();
  };

  Show = () => {
    this.setState({ IsHidden: false },
      () => {
        if (this.NotificationBackground) {
          this.NotificationBackground.Raise();
        }
      });
  };

  OnRowItemChecked = (id) => {
    let surveyContacts = this.state.SurveyContacts;
    surveyContacts.forEach((surveyContact) => {
      if (surveyContact.supplierManufacturingLocationId === id) {
        surveyContact.isCheckedFlag = !surveyContact.isCheckedFlag;
      }
    });
    this.setState({ SurveyContacts: surveyContacts });
  };

  OnNotifyAllChecked = (id) => {
    let surveyContacts = this.state.SurveyContacts;
    surveyContacts.forEach((surveyContact) => {
      if (surveyContact.supplierManufacturingLocationId === id) {
        surveyContact.hasNotifyAll = !surveyContact.hasNotifyAll;
      }
    });
    this.setState({ SurveyContacts: surveyContacts });
  };

  OnSelectAllClick = () => {
    const currentAllSurveyContactsSelected = this.state.AllSurveyContactsSelected;
    let surveyContacts = this.state.SurveyContacts;
    surveyContacts.forEach((surveyContact) => {
      surveyContact.isCheckedFlag = !currentAllSurveyContactsSelected;
    });
    this.setState({ AllSurveyContactsSelected: !currentAllSurveyContactsSelected, SurveyContacts: surveyContacts });
  };

  OnChangeTitle = (newValue) => {
    let survey = this.state.Survey;
    survey.title = newValue;
    this.setState({ Survey: survey });
  };

  OnChangeQuestionnaire = (questionnaireId) => {
    let survey = this.state.Survey;
    survey.questionnaireId = questionnaireId;
    survey.questionnaireTitle = this.state.Questionnaires.find((x) => x.questionnaireId === questionnaireId).title;
    survey.externalQuestionnaireId = this.state.Questionnaires.find((x) => x.questionnaireId === questionnaireId).externalQuestionnaireId;
    this.setState({ Survey: survey });
  };

  OnChangeStartDate = (date) => {
    let survey = this.state.Survey;
    survey.startDate = date;
    if (survey.startDate > Date.now() && (survey.endDate ? survey.endDate > survey.startDate : true)) {
      this.setState({ Survey: survey });
    }
  };

  OnChangeEndDate = (date) => {
    let survey = this.state.Survey;
    survey.endDate = date;
    if (survey.startDate ? survey.endDate > survey.startDate : survey.endDate > Date.now()) {
      this.setState({ Survey: survey });
    }
  };

  OnChangeVisible = () => {
    let survey = this.state.Survey;
    survey.hasCompanyAccess = !survey.hasCompanyAccess;
    this.setState({ Survey: survey });
  };

  OnChangeImmediateStart = () => {
    let isImmediateStart = this.state.IsImmediateStart;
    let survey = this.state.Survey;
    survey.startDate = null;
    this.setState({ IsImmediateStart: !isImmediateStart, Survey: survey });
  };

  OnChangeEmail = (smlId, email) => {
    let surveyContacts = this.state.SurveyContacts;
    let surveyContactIndex = surveyContacts.findIndex((x) => x.supplierManufacturingLocationId === smlId);
    surveyContacts[surveyContactIndex].email = email;
    this.setState({ SurveyContacts: surveyContacts });
  };

  IsFormFilled = () => {
    const survey = this.state.Survey;
    const surveyContacts = this.state.SurveyContacts;
    let hasValidStartDate = this.state.IsImmediateStart ? true : !isNaN(Date.parse(survey.startDate));
    let hasValidEndDate = !isNaN(Date.parse(survey.endDate));
    let allSelectedContactsFilled = surveyContacts.filter((x) => x.isCheckedFlag && (x.email?.length > 0 || x.hasNotifyAll)).length === surveyContacts.filter((x) => x.isCheckedFlag).length;
    return survey.title?.length > 0 && survey.questionnaireId?.length > 0 && !isNaN(survey.externalQuestionnaireId) && hasValidStartDate && hasValidEndDate && allSelectedContactsFilled ? false : true;
  };

  render = () => {
    return (
      this.state.IsRaised &&
      !this.state.IsHidden && (
        <>
          <NotificationBackground
            ref={(notificationBackground) => {
              this.NotificationBackground = notificationBackground;
            }}
          ></NotificationBackground>
          <div className="SurveyCreateForm_Node">
            <div className="SurveyCreateForm_Header">
              <label className="SurveyCreateForm_HeaderLabel">Start Survey</label>
            </div>
            <div className="SurveyCreateForm_Body">
              <div className="SurveyCreateForm_SurveySettings">
                <div className="SurveyCreateForm_Row">
                  <div className="SurveyCreateForm_RowSectionWide">
                    <TextField key="title" label="Title" onChange={(newValue) => this.OnChangeTitle(newValue)} placeholder="Enter Title" value={this.state.Survey.title} />
                  </div>
                  <div className="SurveyCreateForm_RowSectionSmall">
                    <MuiPickersUtilsProvider utils={DayjsUtils}>
                      <DatePicker label="Start date" format={dateFormat} disabled={this.state.IsImmediateStart} onChange={(selection) => this.OnChangeStartDate(selection)} placeholder="YYYY-MM-DD" value={this.state.Survey.startDate} />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="SurveyCreateForm_RowSectionSmall">
                    <MuiPickersUtilsProvider utils={DayjsUtils}>
                      <DatePicker label="End date" format={dateFormat} onChange={(selection) => this.OnChangeEndDate(selection)} placeholder="YYYY-MM-DD" value={this.state.Survey.endDate} />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div className="SurveyCreateForm_Row">
                  <div className="SurveyCreateForm_RowSectionWide">
                    <SingleComboBox
                      label="Questionnaire"
                      onChange={(selection) => {
                        const newSelection = this.state.Questionnaires.find((x) => selection === `${x.externalQuestionnaireId} | ${x.title}`).questionnaireId;
                        this.OnChangeQuestionnaire(newSelection);
                      }}
                      onBlur={this.onBlur}
                      options={this.state.Questionnaires.map((questionnaire) => `${questionnaire.externalQuestionnaireId} | ${questionnaire.title}`)}
                      placeholder="Select Questionnaire"
                      value={null}
                    />
                  </div>
                  <div className="SurveyCreateForm_RowSectionSmall">
                    <FormControlLabel control={<Switch checked={this.state.IsImmediateStart} onChange={this.OnChangeImmediateStart} />} label="Start immediately" />
                  </div>
                  <div className="SurveyCreateForm_RowSectionSmall">
                    <label className="SurveyCreateForm_LabelSelected">Selected rows: {this.state.SurveyContacts.filter((x) => x.isCheckedFlag).length}</label>
                  </div>
                </div>

                <div className="SurveyCreateForm_Row">
                  <div className="SurveyCreateForm_RowSectionWide">
                    <FormControlLabel control={<Switch checked={this.state.Survey.hasCompanyAccess} onChange={this.OnChangeVisible} />} label="Survey visible for all users in my company" />
                  </div>
                </div>
              </div>
              <div className="SurveyCreateForm_TableContainer">
                <MuiThemeProvider theme={theme}>
                  <Paper className="paper">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox">
                            <Checkbox checked={this.state.AllSurveyContactsSelected} onClick={() => this.OnSelectAllClick()} inputProps={{ "aria-label": "select all" }} />
                          </TableCell>
                          <TableCell className="normalSizeCell">Location name</TableCell>
                          <TableCell className="normalSizeCell">Supplier</TableCell>
                          <TableCell className="normalSizeCell">Supplier id</TableCell>
                          <TableCell className="smallSizeCell">Notify all</TableCell>
                          <TableCell className="normalSizeCell">Contact Email</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.SurveyContacts.map((contact) => (
                          <TableRow hover key={contact.supplierManufacturingLocationId}>
                            <TableCell padding="checkbox">
                              <Checkbox checked={contact.isCheckedFlag} onClick={() => this.OnRowItemChecked(contact.supplierManufacturingLocationId)} inputProps={{ "aria-labelledby": contact.supplierManufacturingLocationId }} />
                            </TableCell>
                            <TableCell className="normalSizeCell">{contact.location}</TableCell>
                            <TableCell className="normalSizeCell">{contact.supplierName}</TableCell>
                            <TableCell className="normalSizeCell">{contact.cdm}</TableCell>
                            <TableCell className="smallSizeCell">
                              <Checkbox disabled={!contact.hasMatch} checked={contact.hasNotifyAll} onClick={() => this.OnNotifyAllChecked(contact.supplierManufacturingLocationId)} inputProps={{ "aria-labelledby": contact.supplierManufacturingLocationId }} />
                            </TableCell>
                            <TableCell className="normalSizeCell">
                              <TextField className="emailTextField" disabled={contact.hasNotifyAll && contact.hasMatch} key={contact.supplierManufacturingLocationId} onChange={(newValue) => this.OnChangeEmail(contact.supplierManufacturingLocationId, newValue)} placeholder="Enter Email" value={contact.email} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </MuiThemeProvider>
              </div>
            </div>
            <div className="SurveyCreateForm_Footer">
              <div className="SurveyCreateForm_FooterButtonBar">
                <Button onClick={this.OnCancel} className="SurveyCreateForm_FooterCancelButton" variant={ButtonVariant.Page}>
                  <Cancel className="SurveyCreateForm_CancelIcon" />
                  Cancel
                </Button>
                <Button onClick={this.OnConfirmSurvey} className="SurveyCreateForm_FooterSaveButton" variant={ButtonVariant.PageHighlight} disabled={this.IsFormFilled()}>
                  <Save className="SurveyCreateForm_SaveIcon" />
                  Finalize
                </Button>
                <Button onClick={this.OnConfirmDraftSurvey} className="SurveyCreateForm_FooterDraftButton" variant={ButtonVariant.Page} disabled={this.IsFormFilled()}>
                  <Save className="SurveyCreateForm_SaveIcon" />
                  Save as draft
                </Button>
              </div>
            </div>
          </div>
          <style jsx>
            {`
              .SurveyCreateForm_FooterCancelButton {
                margin-left: 25px;
              }
              .SurveyCreateForm_FooterDraftButton {
                float: right;
                margin-right: 25px;
              }
              .SurveyCreateForm_FooterSaveButton {
                float: right;
                margin-right: 25px;
              }
              .SurveyCreateForm_FooterButtonBar {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .SurveyCreateForm_AddIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .SurveyCreateForm_AddButton {
                position: relative;
                margin-left: 24px;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .SurveyCreateForm_EditIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .SurveyCreateForm_EditButton {
                position: relative;
                margin-left: 24px;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .SurveyCreateForm_DeleteIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .SurveyCreateForm_DeleteButton {
                position: relative;
                margin-left: 24px;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .SurveyCreateForm_CancelIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .SurveyCreateForm_SaveIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .SurveyCreateForm_SurveySettings {
                margin-top: 24px;
                width: 100%;
                border-bottom: 1px solid ${Color.Grey3};
              }
              .SurveyCreateForm_LabelSelected {
                font-size: 15px;
                position: relative;
                top: 21%;
              }
              .SurveyCreateForm_Row {
                width: 100%;
                height: 64px;
                display: inline-block;
                margin-left: 24px;
              }
              .SurveyCreateForm_RowSectionSmall {
                display: inline-block;
                float: left;
                width: calc(20% - 12px);
                height: 100%;
                padding-left: 24px;
              }
              .SurveyCreateForm_RowSectionNormal {
                display: inline-block;
                float: left;
                width: calc(40% - 24px);
                height: 100%;
                padding-left: 24px;
              }
              .SurveyCreateForm_RowSectionWide {
                display: inline-block;
                float: left;
                width: calc(60% - 24px);
                height: 100%;
              }
              .SurveyCreateForm_Body {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 70px;
              }
              .SurveyCreateForm_BodyLabel {
                font-size: 15px;
                font-weight: normal;
                margin-left: 25px;
              }
              .SurveyCreateForm_TableContainer {
                width: 100%;
                height: calc(100% - 359px);
                overflow-y: scroll;
              }
              .SurveyCreateForm_Node {
                width: 80%;
                height: 764px;
                background-color: white;
                position: absolute;
                border: 1px solid grey;
                border-radius: 5px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50);
                z-index: 99;
                box-shadow: 0px 0px 2px ${Color.Grey4};
              }

              .SurveyCreateForm_HeaderLabel {
                font-size: 18px;
                font-weight: bold;
                margin-left: 25px;
              }

              .SurveyCreateForm_Header {
                border-bottom: 1px solid ${Color.Grey3};
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 68px;
                line-height: 70px;
              }

              .SurveyCreateForm_Footer {
                border-top: 1px solid ${Color.Grey3};
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 64px;
              }
              .SurveyCreateForm_ComboBox {
                background-color: ${Color.Grey4};
              }
              .paper {
                width: "100%";
                height: "calc(100% - 64px)";
              }
              .smallSizeCell {
                width: 100px;
              }
              .normalSizeCell {
                width: 250px;
              }
              .emailTextField {
                width: 300px;
              }
            `}
          </style>
        </>
      )
    );
  };
}
export default SurveyCreateForm;
