import { Button, ButtonVariant } from "@supplyon/ui-components";
import { Cancel, CheckCircle } from "@material-ui/icons/";
import { IconButton } from "@material-ui/core";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import React, { Component } from "react";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { FixedSizeList } from "react-window";
import Color from "../libraries/SupplyOnUiConstants";
import NotificationBackground from "./NotificationBackground";

/*  Props: 

        events:
 
            OnFileUploadError: raised when there is error in file processing 
            SaveCustomRiskLayerList: raised when file processing finished
            OnUploadInprogress: to raise progress bar when file upload is in progress
      
*/
//overriding default material ui theme
const theme = createTheme({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: "120px",
        backgroundColor: Color.Grey2,
        color: Color.Grey6,
        border: "none",
        margin: "24px",
        marginBottom: "0",
        width: "544px",
      },
      text: {
        marginBottom: "0",
      },
      icon: {
        color: Color.Grey6,
        width: "25px",
        height: "25px",
      },
      active: {
        backgroundColor: Color.Green2,
        backgroundImage: `repeating-linear-gradient(-45deg, ${Color.Green1}, ${Color.Green1} 25px, ${Color.Green2} 25px, ${Color.Green2} 50px)`,
      },
      invalid: {
        backgroundColor: Color.Red2,
        backgroundImage: `repeating-linear-gradient(-45deg, ${Color.Red1}, ${Color.Red1} 25px, ${Color.Red2} 25px, ${Color.Red2} 50px)`,
      },
    },
    MuiTypography: {
      h5: {
        fontSize: "1rem !important",
      },
    },
    MuiDropzoneSnackbar: {
      errorAlert: {
        backgroundColor: Color.Red1,
        color: Color.White,
      },
      successAlert: {
        backgroundColor: Color.Green1,
        color: Color.White,
      },
    },
  },
});

class UploadRiskLayerFileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsRaised: false,
      Files: [],
    };
  }

  Show = () => {
    this.setState({ IsRaised: true }, () => {
      this.NotificationBackground.Raise();
    });
  };

  Collapse = () => {
    this.NotificationBackground.Collapse();
    this.setState({ Files: [], IsRaised: false });
  };

  UploadInProgress = () => {
    this.NotificationBackground.Collapse();
    this.setState({ IsRaised: false });
    this.props.OnUploadInprogress();
  };

  DeleteFileAtIndex = (index) => {
    const files = this.state.Files;
    files.splice(index, 1);
    this.setState({ Files: files });
  };

  DecodeUploadedFilesToJson = (file) => {
    let base64string = file.data.toString().split(",");
    let buff = Buffer.from(base64string[1], "base64").toString();
    return buff;
  };

  OnUpload = () => {
    this.UploadInProgress();
    let errorFileNames = [];
    let filesProcessed = 0;
    let newRiskLayers = [];

    this.state.Files.forEach((file) => {
      const decodedData = this.DecodeUploadedFilesToJson(file);
      let jsonData = undefined;

      try {
        jsonData = JSON.parse(decodedData);
      } catch (error) {
        jsonData = undefined;
      }

      if (jsonData === undefined || jsonData.length === 0) {
        errorFileNames = errorFileNames.concat(file.file.name);
        if (filesProcessed === this.state.Files.length) {
          this.OnAllFileProcessed(errorFileNames);
        }
        return;
      }

      newRiskLayers = newRiskLayers.concat(jsonData);
    });
    this.OnAllFileProcessed(errorFileNames, newRiskLayers);
  };

  OnDrop = (acceptedFiles) => {
    this.setState({ Files: this.state.Files.concat(acceptedFiles) });
  };

  IsEmpty = () => {
    return this.state.Files.length === 0 ? true : false;
  };

  OnAllFileProcessed(errorFileNames, newRiskLayers) {
    if (errorFileNames.length > 0) {
      this.props.OnFileUploadError(errorFileNames.length ? "'" + errorFileNames.join("', '") + "'" : "");
    }
    //some files processed successfully
    if (errorFileNames.length < this.state.Files.length) {
      this.props.SaveCustomRiskLayerList(newRiskLayers);
    }
    this.setState({ Files: [], IsRaised: false });
  }

  render = () => {
    const fileObjects = this.state.Files.map((file) => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));
    const Row = ({ index, style }) => (
      <div style={style} className="UploadSupplierManufacturingFileForm_Chip UploadSupplierManufacturingFileForm_FilesUploadedChipColor">
        <div className="UploadSupplierManufacturingFileForm_UploadCheckIcon">
          <CheckCircle />
        </div>
        <div className="UploadSupplierManufacturingFileForm_FileLabel">
          <span>
            {this.state.Files[index].file.name} ({this.state.Files[index].file.size} bytes)
          </span>
        </div>
        <IconButton onClick={() => this.DeleteFileAtIndex(index)} color="primary" component="div" classes={{ root: "UploadSupplierManufacturingFileForm_UploadFileDeleteButton" }}>
          <Cancel />
        </IconButton>
      </div>
    );

    return (
      this.state.IsRaised && (
        <>
          <NotificationBackground
            ref={(notificationBackground) => {
              this.NotificationBackground = notificationBackground;
            }}
          />
          <div className="UploadSupplierManufacturingFileForm_Node">
            <div className="UploadSupplierManufacturingFileForm_Header">
              <div className="UploadSupplierManufacturingFileForm_HeaderLabel">Upload risk layers</div>
              <div className="UploadSupplierManufacturingFileForm_Chip UploadSupplierManufacturingFileForm_MandatoryInfoLabel">* Marked fields are mandatory</div>
            </div>
            <div className="UploadSupplierManufacturingFileForm_Body">
              <MuiThemeProvider theme={theme}>
                <DropzoneAreaBase onAdd={(files) => this.OnDrop(files)} acceptedFiles={[".json"]} filesLimit={100} showPreviewsInDropzone={false} dropzoneText="Drag and drop or browse *" alertSnackbarProps={{ anchorOrigin: { vertical: "top", horizontal: "right" } }} />
              </MuiThemeProvider>
              <div>
                <p className="UploadSupplierManufacturingFileForm_AcceptedFilesInfo">Accepted File Types: *.json</p>
              </div>
              <div className="UploadSupplierManufacturingFileForm_UploadedFiles">
                {/* for displaying scroll bar */}
                <FixedSizeList className="FixedSizeList" itemCount={fileObjects.length} itemSize={32} width={550} margin={25} height={70}>
                  {Row}
                </FixedSizeList>
              </div>
            </div>
            <div className="UploadSupplierManufacturingFileForm_Footer">
              <div>
                <Button onClick={this.Collapse} className="UploadSupplierManufacturingFileForm_FooterButton" variant={ButtonVariant.Page}>
                  Cancel
                </Button>
                <Button onClick={this.OnUpload} className="UploadSupplierManufacturingFileForm_FooterButton" variant={ButtonVariant.PageHighlight} disabled={this.IsEmpty()}>
                  Upload
                </Button>
              </div>
            </div>
          </div>
          <style jsx>
            {`
              .UploadSupplierManufacturingFileForm_Checkbox {
                margin-top: 16px;
                font-size: 12px;
                margin-left: 16px;
              }
              .UploadSupplierManufacturingFileForm_UploadCheckIcon {
                float: left;
                padding: 6px;
              }
              .UploadSupplierManufacturingFileForm_UploadFileDeleteButton {
                float: right;
                color: ${Color.Grey5};
                font-size: 20px;
                padding: 6px;
              }
              .UploadSupplierManufacturingFileForm_UploadedFiles {
                margin: 20px;
              }
              .UploadSupplierManufacturingFileForm_AcceptedFilesInfo {
                margin-left: 40px;
              }
              .UploadSupplierManufacturingFileForm_FooterButton {
                margin-left: 24px;
                margin-top: 15px;
              }
              .UploadSupplierManufacturingFileForm_Body {
                height: 225px;
                width: 100%;
              }
              .UploadSupplierManufacturingFileForm_Footer {
                height: 64px;
                width: 100%;
                border-top: 1px solid ${Color.Grey3};
                line-height: 50px;
                bottom: 0;
                position: absolute;
              }
              .UploadSupplierManufacturingFileForm_Header {
                height: 64px;
                width: 100%;
                border-bottom: 1px solid ${Color.Grey3};

                float: left;
                margin-bottom: 20px;
              }
              .UploadSupplierManufacturingFileForm_HeaderLabel {
                font-size: 16px;
                font-weight: bold;
                margin-left: 24px;
                margin-top: 24px;
                width: 50%;
                float: left;
              }
              .UploadSupplierManufacturingFileForm_Node {
                width: 592px;
                height: 384px;
                border-radius: 5px;
                background-color: white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 105;
                border: 1px solid ${Color.Grey5};
              }
              .UploadSupplierManufacturingFileForm_Chip {
                height: 32px;
                margin: 0;
                outline: 0;
                padding: 0 4px 0 4px;
                box-sizing: border-box;
                transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                white-space: nowrap;
                border-radius: 16px;
                vertical-align: middle;
                text-decoration: none;
                border: 1px solid ${Color.Grey3};
                background-color: ${Color.White};
              }
              .UploadSupplierManufacturingFileForm_FilesUploadedChipColor {
                color: ${Color.Blue1};
              }
              .UploadSupplierManufacturingFileForm_FileLabel {
                float: left;
                height: inherit;
                font-size: 12px;
                padding-top: 8px;
                padding-left: 8px;
              }
              .UploadSupplierManufacturingFileForm_MandatoryInfoLabel {
                float: right;
                width: 184px;
                margin: 20px 24px 10px 10px;
                height: 23px;
                line-height: 20px;
                padding: 2px 4px 4px;
                font-size: 12px;
                text-align: center;
                color: gray;
              }
            `}
          </style>
        </>
      )
    );
  };
}
export default UploadRiskLayerFileForm;
