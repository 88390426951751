import Keycloak from "keycloak-js";
import RoleConstants from "./RoleConstants";
import CompanyService from "../services/CompanyService";

class RiskManagementKeycloak {
  static Instance = new Keycloak(process.env.REACT_APP_KEYCLOAK_CONFIGURATION_PATH);

  static Access_Token = "";

  static Refresh_Token = "";

  static UserRoles = "";

  static Has_Role_RiskManager = false;

  static Has_Role_RiskClusterOwner = false;

  static Has_Role_CategoryManager = false;

  static Has_Role_Visitor = false;

  static Is_Ready = false;

  static User_Id = "";

  static Sma_Id = "";

  static Spin_Id = "";

  static API_Key = "";

  static OnReady = () => {
    if (!this.Instance.authenticated) {
      this.Instance.login();
    }
  };

  static GetConfiguration = () => {
    return fetch(process.env.REACT_APP_KEYCLOAK_CONFIGURATION_PATH).then((result) => result.json());
  };

  static RefreshToken = () => {
    return this.GetConfiguration().then((configuration) => {
      return fetch(configuration["auth-server-url"] + "realms/" + configuration["realm"] + "/protocol/openid-connect/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: "client_id=" + configuration.resource + "&grant_type=refresh_token&refresh_token=" + this.Refresh_Token,
      })
        .then((result) => {
          if (result.status === 200) {
            return result.json();
          } else {
            if (!this.Instance.authenticated) {
              this.Instance.login();
            }
          }
        })
        .then((jsonresult) => {
          if (jsonresult !== null && jsonresult !== undefined) {
            this.Access_Token = jsonresult.access_token;
            this.Refresh_Token = jsonresult.refresh_token;
          } else {
            this.Instance.login();
          }
        });
    });
  };

  static OnInitError = () => {};

  static OnAuthSuccess = () => {
    this.Refresh_Token = this.Instance.refreshToken;
    this.Access_Token = this.Instance.idToken;
    this.Is_Ready = true;
    return this.Instance.loadUserInfo().then((userInfo) => {      
      this.User_Id = userInfo.sub;
      this.Sma_Id = userInfo.preferred_username;
      this.UserRoles = this.Instance.tokenParsed.realm_access.roles;
      this.Spin_Id = userInfo.company_id;
      CompanyService.GetCompanyApiKey().then((result) => {
        this.API_Key = result.apiKey ?? "NoApiKey";
      });
      this.Has_Role_RiskManager = this.UserRoles.includes(RoleConstants.StrategicRiskManager);
      this.Has_Role_RiskClusterOwner = this.UserRoles.includes(RoleConstants.StrategicRiskClusterOwner);
      this.Has_Role_CategoryManager = this.UserRoles.includes(RoleConstants.StrategicRiskCategoryManager);
      this.Has_Role_Visitor = this.UserRoles.includes(RoleConstants.StrategicRiskVisitor);      
      return this.RefreshToken().then(() => {
        return;
      });
    });
  };

  static OnAuthError = () => {};

  static OnAuthRefreshSuccess = () => {};

  static OnAuthRefreshError = () => {};

  static OnTokenExpired = () => {};

  static OnAuthLogout = () => {};

  static OnTokens = () => {};

  static OnEvent = (event) => {
    if (event === "onReady") {
      this.OnReady();
    }
    if (event === "onAuthSuccess") {
      this.OnAuthSuccess();
    }
  };
}
export default RiskManagementKeycloak;
