import React, { Component } from "react";
import { Circle as GoogleCircle } from "@react-google-maps/api";
import Color from "../libraries/SupplyOnUiConstants";
import { withStyles } from "@material-ui/core/styles";

/*  Props: 
        events:
 
            OnClick:  raised by onClick on the button
            OnChange: raised by do changes on the circle


        properties:

            Circle: the circle object based on the map
*/

const styles = () => ({
  Container: {
    zIndex: "100000",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -0%)",
    bottom: "0px",
    height: "14px",
    lineHeight: "14px",
  },
  RadiusContainer: {
    textAlign: "right",
    verticalAlign: "middle",
    backgroundColor: Color.Blue1,
    width: "100%",
    height: "100%",
    position: "relative",
  },
  RadiusButton: {
    font: "400 11px Roboto, Arial, sans-serif",
    background: "none",
    borderStyle: "none",
    color: Color.White,
  },
});

class CircleSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CircleReference: {},
      IsDragged: false,
      IsDraggedByControlPoint: false,
    };
  }

  OnRadiusChanged = () => {
    let circle = this.props.Circle;
    if (this.state.CircleReference !== undefined && circle.isFocused && this.state.CircleReference.radius !== undefined) {
      circle.radius = this.state.CircleReference.radius;
      this.props.OnChange(circle);
    }
  };

  OnDragStart = () => {
    this.setState({ IsDragged: true });
  };

  OnDragEnd = () => {
    if (this.props.Circle.isFocused && this.state.CircleReference && Object.keys(this.state.CircleReference).length) {
      let newCircle = this.props.Circle;
      const newCoordinates = { lat: this.state.CircleReference.getCenter().lat(), lng: this.state.CircleReference.getCenter().lng() };
      newCircle.coordinates[0] = newCoordinates;
      this.props.OnChange(newCircle);
    }
    this.setState({ IsDragged: false });
  };

  OnCenterChanged = () => {
    if (!this.state.IsDragged && this.state.CircleReference && Object.keys(this.state.CircleReference).length) {
      if (!this.state.IsDraggedByControlPoint) {
        this.setState({ IsDraggedByControlPoint: true }, () => {
          this.OnDragEnd();
        });
      } else {
        this.setState({ IsDraggedByControlPoint: false });
      }
    }
  };

  OnCircleLoad = (circleReference) => {
    this.setState({ CircleReference: circleReference });
  };

  render = () => {
    const { classes } = this.props;
    return (
      <>
        <GoogleCircle
          onLoad={this.OnCircleLoad}
          center={this.props.Circle.coordinates[0]}
          radius={this.props.Circle.radius}
          onClick={this.OnClick}
          options={{
            fillColor: this.props.LightColor ? Color.Grey6 : Color.White,
            fillOpacity: 0.2,
            strokeColor: this.props.LightColor ? Color.Grey6 : Color.White,
            strokeOpacity: 1,
            strokeWeight: 1,
            clickable: true,
            draggable: true,
            editable: true,
            geodesic: true,
            zIndex: 70,
          }}
          onRadiusChanged={this.OnRadiusChanged}
          onDragStart={this.OnDragStart}
          onDragEnd={this.OnDragEnd}
          onCenterChanged={this.OnCenterChanged}
        />
        {this.props.Circle.isFocused && (
          <div className={classes.Container}>
            <div className={classes.RadiusContainer}>
              <button className={classes.RadiusButton}>Circle radius: {parseFloat(this.props.Circle.radius / 1000).toFixed(2)} km</button>
            </div>
          </div>
        )}
      </>
    );
  };
}
export default withStyles(styles)(CircleSelection);
