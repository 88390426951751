import { Button, ButtonVariant } from "@supplyon/ui-components";
import React, { Component } from "react";
import { Checkbox, Paper, TableCell } from "@material-ui/core/";
import Color from "../../libraries/SupplyOnUiConstants";
import NotificationBackground from "../NotificationBackground";
import { Delete, Save, Cancel, Edit, Update, Lock, Help, Pause } from "@material-ui/icons/";
import VirtualizedTable from "../VirtualizedTable";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import CISUserAccountService from "../../services/CISUserAccountService";
import { SortDirection } from "react-virtualized";
import sortBy from "lodash/sortBy";
import dayjs from "dayjs";

const offset = new Date(Date.now()).getTimezoneOffset();

const styles = () => ({
  paper: {
    width: "100%",
    height: "calc(100% - 64px)",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  tableRow: {
    cursor: "pointer",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: Color.Grey2,
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: "initial",
  },
  infoIcon: {
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "-4px",
    color: `${Color.Grey5}`,
    float: "left",
    "&:hover": {
      color: Color.Blue1,
    },
  },
  noIconSpan: {
    display: "inline-block",
    width: "10px",
  },
  buttonBar: {
    marginLeft: "24px",
    width: "100%",
    height: "64px",
  },
  downloadButton: {
    width: "112px",
    height: "32px",
    marginRight: "16px",
    marginTop: "16px",
  },
  downloadIcon: {
    marginRight: "10px",
    width: "18px",
    height: "20px",
  },
  deleteButton: {
    width: "92",
    height: "32px",
    marginRight: "16px",
    marginTop: "16px",
  },
  deleteIcon: {
    marginRight: "10px",
    width: "14px",
    height: "14px",
  },
  refreshButton: {
    height: "32px",
    marginRight: "16px",
    marginTop: "16px",
  },
  selectedItemsLabel: {
    width: "70px",
    position: "relative",
    marginRight: "50px",
    top: "50%",
    float: "right",
    transform: "translateY(-50%)",
    msTransform: "translateY(-50%)",
  },
});

class SurveyOverviewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsRaised: false,
      Surveys: [],
      DeletedSurveys: [],
      selectedRowIds: [],
      sortBy: "insertDate",
      sortDirection: SortDirection.DESC,
    };
  }
  static defaultProps = {
    headerHeight: 33,
    rowHeight: 33,
  };

  GetFormattedDate = (date) => {
    date = date.replace("Z", "");
    return dayjs(date)
      .add(offset * -1, "minutes")
      .format("YYYY-MM-DD | HH:mm:ss");
  };

  Raise = async (surveys, userAccounts) => {
    const newList = sortBy(surveys, this.state.sortBy);
    if (this.state.sortDirection === SortDirection.DESC) {
      newList.reverse();
    }

    surveys = newList;
    surveys.forEach((survey) => {
      let userAccount = userAccounts.find((x) => x.smaId.toLowerCase() === survey.smaId.toLowerCase());
      if (userAccount == null) {
        CISUserAccountService.GetUserAccountByIdKey(survey.smaId.toUpperCase()).then((userAccountResponse) => {
          userAccount = userAccountResponse;

          survey.creatorName = userAccount?.firstName + " " + userAccount?.lastName;
          survey.questionnaireTitle = survey.questionnaire.title;

          this.setState({ IsRaised: true, Surveys: surveys }, () => {
            if (this.NotificationBackground) {
              this.NotificationBackground.Raise();
            }
          });
        });
      }
      survey.creatorName = userAccount?.firstName + " " + userAccount?.lastName;
      survey.questionnaireTitle = survey.questionnaire.title;

      survey.endDate = this.GetFormattedDate(survey.endDate);
      if (survey.status === "DRAFT") {
        survey.startDate = "";
      } else {
        survey.startDate = this.GetFormattedDate(survey.startDate);
      }
      survey.insertDate = this.GetFormattedDate(survey.insertDate);
    });

    this.setState({ IsRaised: true, Surveys: surveys }, () => {
      if (this.NotificationBackground) {
        this.NotificationBackground.Raise();
      }
    });
  };

  columns = [
    {
      name: "checkbox",
      dataKey: "dataKey is a required prop but not used here",
      headerRenderer: () => this.HeaderRendererCheckbox(),
      cellRenderer: (rowData) => this.CellRendererCheckbox(rowData),
      cellProps: { style: { paddingRight: 9 } },
      width: 100,
      disableSort: true,
    },
    {
      width: 100,
      label: "Status",
      dataKey: "status",
      cellRenderer: (rowData) => this.CellRendererStatusIcon(rowData),
      disableSort: false,
    },
    {
      width: 350,
      label: "Survey Name",
      dataKey: "title",
      cellRenderer: (rowData) => this.CellRendererSurveyLink(rowData),
      disableSort: false,
    },
    {
      width: 350,
      label: "Questionnaire Name",
      dataKey: "questionnaireTitle",
      disableSort: false,
    },
    {
      width: 250,
      label: "Start Date",
      dataKey: "startDate",
      disableSort: false,
    },
    {
      width: 250,
      label: "End Date",
      dataKey: "endDate",
      disableSort: false,
    },
    {
      width: 350,
      label: "Name of Creator",
      dataKey: "creatorName",
      disableSort: false,
    },
    {
      width: 250,
      label: "Creation Date",
      dataKey: "insertDate",
      disableSort: false,
    },
  ];

  HeaderRendererCheckbox = () => {
    const { headerHeight, classes } = this.props;

    return (
      <TableCell component="div" className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)} variant="head" style={{ height: headerHeight }} align={"left"}>
        <Checkbox indeterminate={this.state.selectedRowIds.length > 0 && this.state.selectedRowIds.length < this.state.Surveys?.length} checked={this.state.selectedRowIds.length === this.state.Surveys.length} onChange={(event) => this.OnChangeHeaderCheckbox(event)} size="small" />
      </TableCell>
    );
  };

  CellRendererCheckbox = ({ rowData }) => {
    const { classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={"left"}
      >
        <Checkbox checked={this.state.selectedRowIds.some((id) => rowData.surveyId === id)} size="small" />
      </TableCell>
    );
  };

  CellRendererSurveyLink = ({ rowData }) => {
    const { classes, rowHeight, onRowClick } = this.props;
    if (rowData.externalSurveyId?.length > 0) {
      return (
        <TableCell component="div" className={clsx(classes.tableCell, classes.flexContainer)} variant="body" style={{ height: rowHeight }} align={"left"}>
          <a href={process.env.REACT_APP_Survey_URL + rowData.externalSurveyId} target="_blank" rel="noopener noreferrer">
            {rowData.title}
          </a>
        </TableCell>
      );
    } else {
      return (
        <TableCell
          component="div"
          className={clsx(classes.tableCell, classes.flexContainer, {
            [classes.noClick]: onRowClick == null,
          })}
          variant="body"
          style={{ height: rowHeight }}
          align={"left"}
        >
          {rowData.title}
        </TableCell>
      );
    }
  };

  CellRendererStatusIcon = ({ rowData }) => {
    const { classes, rowHeight } = this.props;
    if (rowData.status === "CLOSED") {
      return (
        <TableCell component="div" className={clsx(classes.tableCell, classes.flexContainer)} variant="body" style={{ height: rowHeight }} align={"center"}>
          <Lock className="SurveyOverviewForm_TableIcon" />
        </TableCell>
      );
    } else if (rowData.status === "DRAFT") {
      return (
        <TableCell component="div" className={clsx(classes.tableCell, classes.flexContainer)} variant="body" style={{ height: rowHeight }} align={"center"}>
          <Edit className="SurveyOverviewForm_TableIcon" />
        </TableCell>
      );
    } else if (rowData.status === "ACTIVE") {
      return (
        <TableCell component="div" className={clsx(classes.tableCell, classes.flexContainer)} variant="body" style={{ height: rowHeight }} align={"center"}>
          <Update className="SurveyOverviewForm_TableIcon" />
        </TableCell>
      );
    } else if (rowData.status === "READY_FOR_START") {
      return (
        <TableCell component="div" className={clsx(classes.tableCell, classes.flexContainer)} variant="body" style={{ height: rowHeight }} align={"center"}>
          <Pause className="SurveyOverviewForm_TableIcon" />
        </TableCell>
      );
    } else {
      return (
        <TableCell component="div" className={clsx(classes.tableCell, classes.flexContainer)} variant="body" style={{ height: rowHeight }} align={"center"}>
          <Help className="SurveyOverviewForm_TableIcon" />
        </TableCell>
      );
    }
  };

  RowGetter = ({ index }) => {
    return this.state.Surveys[index];
  };

  Collapse = () => {
    this.NotificationBackground.Collapse();
    this.setState({ Surveys: [], DeletedSurveys: [], selectedRowIds: [], IsRaised: false });
  };

  OnConfirm = () => {
    this.props.OnSave(this.state.DeletedSurveys);
    this.Collapse();
  };

  OnDeleteClick = () => {
    let surveys = this.state.Surveys;
    let deletedSurveys = [];
    let newSurveys = [];
    let selectedIds = this.state.selectedRowIds;
    surveys.forEach((survey) => {
      if (selectedIds.find((x) => x === survey.surveyId)) {
        deletedSurveys.push(survey);
        selectedIds = selectedIds.filter((x) => x !== survey.surveyId);
      } else {
        newSurveys.push(survey);
      }
    });
    this.setState({ Surveys: newSurveys, DeletedSurveys: deletedSurveys, selectedRowIds: selectedIds });
  };

  OnCancel = () => {
    this.Collapse();
  };

  OnChangeHeaderCheckbox = (event) => {
    const newSelectedRowIds = event.target.checked ? this.state.Surveys.map((item) => item.surveyId) : [];
    this.setState({ selectedRowIds: newSelectedRowIds });
  };

  OnRowItemChecked = ({ rowData }) => {
    const clickedRowId = rowData.surveyId;
    const newSelectedRowIds = this.state.selectedRowIds.includes(clickedRowId) ? this.state.selectedRowIds.filter((id) => id !== clickedRowId) : [...this.state.selectedRowIds, clickedRowId];
    this.setState({ selectedRowIds: newSelectedRowIds });
  };

  OnSelectAllClick = (event) => {
    const newSelectedRowIds = event.target.checked ? this.state.survey.map((item) => item.surveyId) : [];
    this.setState({ selectedRowIds: newSelectedRowIds });
  };

  SortList = (_sortBy, sortDirection) => {
    this.setState((prevState) => {
      const newList = sortBy(prevState.Surveys, [_sortBy]);
      if (sortDirection === SortDirection.DESC) {
        newList.reverse();
      }
      return { Surveys: newList, sortBy: _sortBy, sortDirection: sortDirection };
    });
  };

  render = () => {
    return (
      this.state.IsRaised && (
        <>
          <NotificationBackground
            ref={(notificationBackground) => {
              this.NotificationBackground = notificationBackground;
            }}
          ></NotificationBackground>
          <div className="SurveyOverviewForm_Node">
            <div className="SurveyOverviewForm_Header">
              <label className="SurveyOverviewForm_HeaderLabel">Survey Overview</label>
            </div>
            <div className="SurveyOverviewForm_Message">
              <div className="SurveyOverviewForm_TableButtons">
                <Button variant={ButtonVariant.Page} className="SurveyOverviewForm_DeleteButton" onClick={this.OnDeleteClick} disabled={this.state.selectedRowIds.length === 0}>
                  <Delete className="SurveyOverviewForm_DeleteIcon" />
                  Delete Selected Surveys
                </Button>
              </div>
              <div className="SurveyOverviewForm_TableContainer">
                <Paper className="paper">
                  <VirtualizedTable rowCount={this.state.Surveys.length} rowGetter={this.RowGetter} columns={this.columns} selectedRowIds={this.state.selectedRowIds} onRowClick={this.OnRowItemChecked} SortList={this.SortList} sortBy={this.state.sortBy} sortDirection={this.state.sortDirection} />
                </Paper>
              </div>
            </div>
            <div className="SurveyOverviewForm_Footer">
              <div className="SurveyOverviewForm_FooterButtonBar">
                <Button onClick={this.OnCancel} className="SurveyOverviewForm_FooterCancelButton" variant={ButtonVariant.Page}>
                  <Cancel className="SurveyOverviewForm_CancelIcon" />
                  Cancel
                </Button>
                <Button onClick={this.OnConfirm} className="SurveyOverviewForm_FooterSaveButton" variant={ButtonVariant.PageHighlight}>
                  <Save className="SurveyOverviewForm_SaveIcon" />
                  Confirm
                </Button>
              </div>
            </div>
          </div>
          <style jsx>
            {`
              .SurveyOverviewForm_TableIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .SurveyOverviewForm_FooterCancelButton {
                margin-left: 25px;
              }
              .SurveyOverviewForm_FooterSaveButton {
                float: right;
                margin-right: 25px;
              }
              .SurveyOverviewForm_FooterButtonBar {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .SurveyOverviewForm_DeleteIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .SurveyOverviewForm_DeleteButton {
                position: relative;
                margin-left: 24px;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .SurveyOverviewForm_CancelIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .SurveyOverviewForm_SaveIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .SurveyOverviewForm_TableContainer {
                width: 100%;
                height: calc(100% - 184px);
                overflow-y: scroll;
              }
              .SurveyOverviewForm_TableButtons {
                width: 100%;
                height: 50px;
                border-bottom: 1px solid ${Color.Grey3};
              }
              .SurveyOverviewForm_Message {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 70px;
                line-height: 70px;
              }
              .SurveyOverviewForm_Node {
                width: 1400px;
                height: 700px;
                background-color: white;
                position: absolute;
                border: 1px solid grey;
                border-radius: 5px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50);
                z-index: 99;
              }

              .SurveyOverviewForm_HeaderLabel {
                font-size: 18px;
                font-weight: bold;
                margin-left: 25px;
              }

              .SurveyOverviewForm_Header {
                border-bottom: 1px solid ${Color.Grey3};
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 68px;
                line-height: 70px;
              }

              .SurveyOverviewForm_Footer {
                border-top: 1px solid ${Color.Grey3};
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 64px;
              }
              .paper {
                width: 100%;
                height: calc(100%);
              }
            `}
          </style>
        </>
      )
    );
  };
}
export default withStyles(styles)(SurveyOverviewForm);
