const Color = {
  // Primary
  Blue1: "#006BB3",
  Blue2: "#005792",
  Blue3: "#013A60",
  // Secondary
  Green1: "#97C847",
  Green2: "#678931",
  // White
  White: "#FFFFFF",
  // Grey
  Grey1: "#F5F5F5",
  Grey2: "#E6E6E6",
  Grey3: "#DEDEDE",
  Grey4: "#BFBFBF",
  Grey5: "#7F7F7F",
  Grey6: "#323232",
  // Error
  Red1: "#FF0066",
  Red2: "#D90057",
};
export default Color;
