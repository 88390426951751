import { Check, Close, Visibility, VisibilityOff } from "@material-ui/icons";
import { FormControlLabel, Switch, Tooltip } from "@material-ui/core";
import React, { Component } from "react";
import Color from "../libraries/SupplyOnUiConstants";
import HybridPicture from "../assets/mapoverlay_hybrid.PNG";
import { IconButton } from "@supplyon/ui-components";
import RiskLayerService from "../services/RiskLayerService";
import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";
import RoadMapPicture from "../assets/mapoverlay_roadmap.PNG";
import SatellitePicture from "../assets/mapoverlay_satellite.PNG";

class ViewContextMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsRaised: false,
      ShowAggregatedRisks: true,
      ShowMitigatedRisks: false,
      ShowCustomRiskLayerList: false,
      SelectedMapType: "roadmap", //satellite, roadmap, hybrid, terrain
      RiskTypes: [],
      CustomRiskLayerVisible: false,
      CurrentLegend: [],
    };
  }

  componentDidMount = () => {
    RiskLayerService.GetAllExternalRiskLayer().then((result) => {
      if (result !== undefined && result !== null) {
        let riskTypes = [];
        for (let i = 0; i < result.length; i++) {
          let riskType = result[i];
          riskTypes.push({ Name: riskType.mapServerName, FriendlyName: riskType.friendlyName, IsVisible: false, RiskLayerLegends: riskType.riskLayerLegends });
        }
        this.setState({ RiskTypes: riskTypes });
      }
    });
  };

  Raise = () => {
    this.setState({ IsRaised: true, CustomRiskLayerVisible: this.props.CustomRiskLayerEnabled });
  };

  Collapse = () => {
    this.setState({ IsRaised: false });
  };

  OnSwitchChange = (event) => {
    this.setState({ [event.target.id]: event.target.checked }, () => {
      this.props.ToggleDisplayedRiskColor(this.state.ShowMitigatedRisks, this.state.ShowAggregatedRisks);
    });
  };

  ToggleCustomRiskLayerList = (event) => {
    this.setState({ ShowCustomRiskLayerList: event.target.checked });
  };

  ToggleMapType = (mapTypeName) => {
    this.props.ToggleMapType(mapTypeName);
    this.setState({ SelectedMapType: mapTypeName });
  };

  ToggleRiskType = (index) => {
    let riskTypes = this.state.RiskTypes;

    if (riskTypes[index].IsVisible) {
      riskTypes[index].IsVisible = false;
      this.setState({ CurrentLegend: [] });
    } else {
      for (let i = 0; i < riskTypes.length; i++) {
        if (riskTypes[i].IsVisible) {
          riskTypes[i].IsVisible = false;
          this.props.ToogleRiskLayer(riskTypes[i]);
        } else {
          riskTypes[i].IsVisible = false;
        }
      }
      riskTypes[index].IsVisible = true;
      this.setState({ CurrentLegend: riskTypes[index].RiskLayerLegends });
    }

    this.setState({ RiskTypes: riskTypes });
    this.props.ToogleRiskLayer(riskTypes[index]);
  };

  ToggleCustomRiskLayer = () => {
    let item = !this.state.CustomRiskLayerVisible;
    this.setState({ CustomRiskLayerVisible: item });
    this.props.ToggleCustomRiskLayer();
  };

  ChangeCustomRiskLayer = (state) => {
    this.setState({ CustomRiskLayerVisible: state });
  };

  render = () => {
    return (
      this.state.IsRaised && (
        <>
          <div className="ViewContextMenu_Node">
            <div className="ViewContextMenu_SectionViewContextHeader">
              <div className="ViewContextMenu_CloseButton" onClick={this.Collapse}>
                <Close></Close>
              </div>
              <label className="ViewContextMenu_SectionViewContextHeaderLabel">Toggle visualizations</label>
            </div>
            <div className="ViewContextMenu_SectionDividerHeader" />
            <div className="ViewContextMenu_SectionViewContext">
              {(RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_CategoryManager) && (
                <div className="ViewContextMenu_SectionLocations">
                  <label className="ViewContextMenu_HeaderLabel">Locations</label>

                  <div className="ViewContextMenu_Switch">
                    <FormControlLabel control={<Switch id="ShowAggregatedRisks" checked={this.state.ShowAggregatedRisks} onChange={this.OnSwitchChange} />} label="Show aggregated gross risk" />
                  </div>
                  <div className="ViewContextMenu_Switch">
                    <FormControlLabel control={<Switch id="ShowMitigatedRisks" checked={this.state.ShowMitigatedRisks} onChange={this.OnSwitchChange} />} label="Show mitigated gross risk" />
                  </div>
                </div>
              )}
              <div className="ViewContextMenu_SectionMapTypes">
                <label className="ViewContextMenu_HeaderLabel">Map types</label>
                <div className="ViewContextMenu_MapTypeButtonBar">
                  <div className="ViewContextMenu_MapTypeButtonContainer">
                    <div
                      onClick={() => {
                        this.ToggleMapType("hybrid");
                      }}
                      className="ViewContextMenu_MapTypeButton"
                      style={{ backgroundImage: "url(" + HybridPicture + ")", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", border: this.state.SelectedMapType === "hybrid" ? `2px solid ${Color.Blue1}` : `1px solid ${Color.Grey3}` }}
                    >
                      {this.state.SelectedMapType === "hybrid" && (
                        <div className="ViewContextMenu_ColorCodingButton" style={{ backgroundColor: Color.Blue1 }}>
                          <Check className="ViewContextMenu_CheckIcon"></Check>
                        </div>
                      )}
                    </div>
                    <div className="ViewContextMenu_MapTypeButtonLabelContainer">
                      <label className="ViewContextMenu_MapTypeButtonLabel" style={{ fontWeight: this.state.SelectedMapType === "hybrid" ? `bold` : `normal` }}>
                        Hybrid
                      </label>
                    </div>
                  </div>
                  <div className="ViewContextMenu_MapTypeButtonContainer">
                    <div
                      onClick={() => {
                        this.ToggleMapType("roadmap");
                      }}
                      className="ViewContextMenu_MapTypeButton"
                      style={{ backgroundImage: "url(" + RoadMapPicture + ")", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", border: this.state.SelectedMapType === "roadmap" ? `2px solid ${Color.Blue1}` : `1px solid ${Color.Grey3}` }}
                    >
                      {this.state.SelectedMapType === "roadmap" && (
                        <div className="ViewContextMenu_ColorCodingButton" style={{ backgroundColor: Color.Blue1 }}>
                          <Check className="ViewContextMenu_CheckIcon"></Check>
                        </div>
                      )}
                    </div>
                    <div className="ViewContextMenu_MapTypeButtonLabelContainer">
                      <label className="ViewContextMenu_MapTypeButtonLabel" style={{ fontWeight: this.state.SelectedMapType === "roadmap" ? `bold` : `normal` }}>
                        Roadmap
                      </label>
                    </div>
                  </div>
                  <div className="ViewContextMenu_MapTypeButtonContainer">
                    <div
                      onClick={() => {
                        this.ToggleMapType("satellite");
                      }}
                      className="ViewContextMenu_MapTypeButton"
                      style={{ backgroundImage: "url(" + SatellitePicture + ")", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", border: this.state.SelectedMapType === "satellite" ? `2px solid ${Color.Blue1}` : `1px solid ${Color.Grey3}` }}
                    >
                      {this.state.SelectedMapType === "satellite" && (
                        <div className="ViewContextMenu_ColorCodingButton" style={{ backgroundColor: Color.Blue1 }}>
                          <Check className="ViewContextMenu_CheckIcon"></Check>
                        </div>
                      )}
                    </div>
                    <div className="ViewContextMenu_MapTypeButtonLabelContainer">
                      <label className="ViewContextMenu_MapTypeButtonLabel" style={{ fontWeight: this.state.SelectedMapType === "satellite" ? `bold` : `normal` }}>
                        Satellite
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {(RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_CategoryManager) && (
                <div className="ViewContextMenu_SectionRiskOverlays">
                  <label className="ViewContextMenu_HeaderLabel">Risk layers</label>
                  <div className="ViewContextMenu_SectionRiskOverlaysFlex">
                    {this.state.RiskTypes.map((riskType) => (
                      <div className="ViewContextMenu_SectionRiskOverlaysButtonRow">
                        <Tooltip title={riskType.IsVisible ? "Visible" : "Hidden"}>
                          {riskType.IsVisible ? (
                            <IconButton
                              className="ViewContextMenu_IconButtonVisible"
                              onClick={() => {
                                this.ToggleRiskType(this.state.RiskTypes.indexOf(riskType));
                              }}
                            >
                              <Visibility />
                            </IconButton>
                          ) : (
                            <IconButton
                              className="ViewContextMenu_IconButtonVisibleOff"
                              onClick={() => {
                                this.ToggleRiskType(this.state.RiskTypes.indexOf(riskType));
                              }}
                            >
                              <VisibilityOff />
                            </IconButton>
                          )}
                        </Tooltip>
                        <div className="ViewContextMenu_TableCell">
                          <label className="ViewContextMenu_SectionRiskOverlaysButtonLabel">{riskType.FriendlyName}</label>
                        </div>
                      </div>
                    ))}
                    <div className="ViewContextMenu_Switch_CustomRiskLayers">
                      <FormControlLabel control={<Switch id="ShowCustomRiskLayerList" checked={this.state.ShowCustomRiskLayerList} onChange={this.ToggleCustomRiskLayerList} />} label="Custom risk layers" />
                    </div>
                  </div>
                  {this.state.ShowCustomRiskLayerList && (
                    <div className="ViewContextMenu_CustomRiskLayerList">
                      <div className="ViewContextMenu_SectionRiskOverlaysButtonRowAllRiskLayers">
                        <Tooltip title={this.props.CustomRiskLayerEnabled ? "Visible" : "Hidden"}>
                          {this.props.CustomRiskLayerEnabled ? (
                            <IconButton
                              className="ViewContextMenu_IconButtonVisible"
                              onClick={() => {
                                this.props.ToggleAllCustomRiskLayer();
                              }}
                            >
                              <Visibility />
                            </IconButton>
                          ) : (
                            <IconButton
                              className="ViewContextMenu_IconButtonVisibleOff"
                              onClick={() => {
                                this.props.ToggleAllCustomRiskLayer();
                              }}
                            >
                              <VisibilityOff />
                            </IconButton>
                          )}
                        </Tooltip>
                        <div className="ViewContextMenu_TableCellAll">
                          <label style={{ fontWeight: "bold" }} className="ViewContextMenu_SectionRiskOverlaysButtonLabel">
                            All custom risk layers
                          </label>
                        </div>
                      </div>
                      <div className="ViewContextMenu_SectionRiskOverlaysFlex">
                        {this.props.CustomRiskLayerList.map((riskLayer) => (
                          <div className="ViewContextMenu_SectionRiskOverlaysButtonRow">
                            <Tooltip title={riskLayer.IsVisible ? "Visible" : "Hidden"}>
                              {riskLayer.IsVisible ? (
                                <IconButton
                                  className="ViewContextMenu_IconButtonVisible"
                                  onClick={() => {
                                    this.props.ToggleCustomRiskLayer(this.props.CustomRiskLayerList.indexOf(riskLayer));
                                  }}
                                >
                                  <Visibility />
                                </IconButton>
                              ) : (
                                <IconButton
                                  className="ViewContextMenu_IconButtonVisibleOff"
                                  onClick={() => {
                                    this.props.ToggleCustomRiskLayer(this.props.CustomRiskLayerList.indexOf(riskLayer));
                                  }}
                                >
                                  <VisibilityOff />
                                </IconButton>
                              )}
                            </Tooltip>
                            <div className="ViewContextMenu_TableCell">
                              <label className="ViewContextMenu_SectionRiskOverlaysButtonLabel">{riskLayer.name}</label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.CurrentLegend.length > 0 && (RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_CategoryManager) && (
                <div className="ViewContextMenu_SectionRiskLegend">
                  <label className="ViewContextMenu_HeaderLabel">Legend</label>
                  <div className="ViewContextMenu_SectionRiskOverlaysFlex">
                    {this.state.CurrentLegend.map((legend, index) => (
                      <div className="ViewContextMenu_SectionRiskOverlaysButtonRow" key={`legend${index}`}>
                        <div className="ViewContextMenu_RiskLegendPicture" style={{ backgroundImage: "url('data:image/jpeg;base64," + legend.picture + "')", backgroundSize: "100% 100%", backgroundRepeat: "no-repeat" }}></div>
                        <label className="ViewContextMenu_RiskLegendLabel">{legend.label}</label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <style jsx>
            {`
              .ViewContextMenu_CustomRiskLayerList {
                position: relative;
                width: 100%;
                margin-top: 15px;
              }
              .ViewContextMenu_SectionDividerHeader {
                border-bottom: 1px solid #dedede;
                margin-top: 15px;
              }
              .ViewContextMenu_RiskLegendLabel {
                font-size: 12px;
                color: black;
                margin-left: 2px;
              }
              .ViewContextMenu_RiskLegendPicture {
                width: 14px;
                height: 14px;
                position: relative;
                float: left;
                margin-right: 5px;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                border-radius: 2px;
              }
              .ViewContextMenu_SectionRiskLegend {
                width: 340px;
                height: 400px;
                border-bottom: 1px solid ${Color.Grey3};
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                top: 110px;
              }

              .ViewContextMenu_SectionRiskOverlaysButtonRowCustomRiskLayer {
                width: 200px;
                height: 30px;
                line-height: 30px;
                margin-top: 20px;
              }
              .ViewContextMenu_SectionRiskOverlaysCustomRiskLayer {
                width: 340px;
                height: 60px;
                border-top: 1px solid ${Color.Grey3};
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                top: 20px;
              }
              .ViewContextMenu_SectionRiskOverlaysFlex {
                display: flex;
                position: relative;
                width: 340px;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 10px;
                margin-left: 0;
              }
              .ViewContextMenu_IconButtonVisible {
                display: table-cell;
                vertical-align: middle;
                position: relative;
                color: ${Color.Blue1};
              }
              .ViewContextMenu_IconButtonVisibleOff {
                position: relative;
                display: table-cell;
                vertical-align: middle;
              }

              .ViewContextMenu_SectionRiskOverlaysButtonRowAllRiskLayers {
                width: 300px;
                height: 30px;
                overflow-wrap: anywhere;
                display: table;
                margin-top: 10px;
                margin-bottom: -10px;
              }

              .ViewContextMenu_SectionRiskOverlaysButtonRow {
                height: 30px;
                overflow-wrap: anywhere;
                display: table;
              }
              .ViewContextMenu_SectionRiskOverlaysButtonLabel {
                font-size: 14px;
                color: black;
                margin-left: 2px;
                text-align: left;
                display: inline-block;
              }
              .ViewContextMenu_TableCell {
                display: table-cell;
                vertical-align: middle;
                width: 130px;
              }
              .ViewContextMenu_TableCellAll {
                display: table-cell;
                vertical-align: middle;
                width: 275px;
              }
              .ViewContextMenu_MapTypeButtonLabelContainer {
                position: relative;
                margin-bottom: 0px;
                height: 30px;
                line-height: 30px;
              }
              .ViewContextMenu_MapTypeButtonLabel {
                margin-bottom: 0;
                font-size: 14px;
              }
              .ViewContextMenu_MapTypeButtonContainer {
                width: 76px;
                height: 106px;
              }
              .ViewContextMenu_ColorCodingButton {
                width: 14px;
                height: 14px;
                border-radius: 7px;
                margin-right: 20px;
                margin-top: 5px;
                margin-right: 5px;
                float: right;
              }
              .ViewContextMenu_MapTypeButton:hover {
                border: 1px solid ${Color.Blue1};
              }
              .ViewContextMenu_CheckIcon {
                width: 12px;
                height: 12px;
                color: white;
                left: 50%;
                top: 50%;
                position: relative;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
              }
              .ViewContextMenu_MapTypeButton {
                width: 74px;
                height: 74px;
                border: 1px solid ${Color.Grey3};
                border-radius: 5px;
              }
              .ViewContextMenu_MapTypeButtonBar {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
              }

              .ViewContextMenu_Switch_CustomRiskLayers {
                margin-left: -12px;
                padding: 0;
                margin-bottom: 0px;
                margin-top: 20px;
              }

              .ViewContextMenu_Switch {
                margin-left: -12px;
                padding: 0;
                margin-bottom: -10px;
              }
              .ViewContextMenu_SectionViewContextHeader {
                height: 30px;
                width: 390px;
                line-height: 30px;
                padding-left: 30px;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                top: 10px;
              }
              .ViewContextMenu_SectionViewContextHeaderLabel {
                font-size: 16px;
                font-weight: bold;
                margin-left: -16px;
              }
              .ViewContextMenu_HeaderLabel {
                font-size: 16px;
                font-weight: bold;
              }
              .ViewContextMenu_CloseButton {
                position: relative;
                width: 20px;
                height: 20px;
                margin-left: auto;
                float: right;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                color: ${Color.Grey5};
                margin-right: 8px;
              }
              .ViewContextMenu_CloseButton:hover {
                color: ${Color.Blue1};
              }
              .ViewContextMenu_SectionLocations {
                width: 340px;
                height: 100px;
                border-bottom: 1px solid ${Color.Grey3};
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                top: 20px;
              }
              .ViewContextMenu_SectionMapTypes {
                width: 340px;
                height: 160px;
                border-bottom: 1px solid ${Color.Grey3};
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                top: 55px;
              }
              .ViewContextMenu_SectionViewContext {
                width: 100%;
                height: calc(100% - 46px);
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                overflow-y: scroll;
              }
              .ViewContextMenu_SectionRiskOverlays {
                width: 340px;
                padding-bottom: 20px;
                border-bottom: 1px solid ${Color.Grey3};
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                top: 80px;
              }
              .ViewContextMenu_Node {
                height: calc(100% - 52px);
                width: 400px;
                position: absolute;
                top: 51px;
                right: 0;
                background-color: white;
                z-index: 70;
                box-shadow: 0px 0px 2px ${Color.Grey4};
              }
            `}
          </style>
        </>
      )
    );
  };
}
export default ViewContextMenu;
