import { Button, ButtonVariant } from "@supplyon/ui-components";
import React, { Component } from "react";
import Color from "../libraries/SupplyOnUiConstants";
import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";
import SelectionSmlList from "./SelectionSmlList";
import SelectionRiskLayerList from "./SelectionRiskLayerList";
import { SortDirection } from "react-virtualized";
import sortBy from "lodash/sortBy";

/*  Props:
        events:

            OnDelete: raised by onClick of delete button
            OnClose: raised by onClick of close button
            OnRefresh: raised by onClick of Refresh button
*/

class SelectionMainForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsRaised: false,
      SmlInsideArea: [],
      RiskLayersInsideArea: [],
      RiskLayersDisplayed: false,
      SmlDisplayed: true,
      sortBySml: "name",
      sortDirectionSml: SortDirection.ASC,
      sortByRiskLayer: "name",
      sortDirectionRiskLayer: SortDirection.ASC,
    };
  }

  Raise = (Smls, RiskLayers) => {
    // note: sortBy is case sensitive
    let smlInsideSelection = sortBy([...Smls], [this.state.sortBySml]);
    if (this.state.sortDirectionSml === SortDirection.DESC) {
      smlInsideSelection.reverse();
    }

    // todo refactor not neede in raise only in download sml
    smlInsideSelection.forEach((sml) => {
      sml.id = sml.supplierManufacturingLocationId;
      sml.address = `${sml.street} ${sml.city} ${sml.country}`;
      sml.colorValue = RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_CategoryManager ? sml.calculatedHighColor.colorValue : Color.Grey5;
      sml.isSelected = false;
    });

    let riskLayersInsideSelection = sortBy([...RiskLayers], [this.state.sortByRiskLayer]);
    if (this.state.sortDirectionRiskLayer === SortDirection.DESC) {
      riskLayersInsideSelection.reverse();
    }

    this.setState({ SmlInsideArea: smlInsideSelection, RiskLayersInsideArea: riskLayersInsideSelection, IsRaised: true });
  };

  RaiseRiskLayers = () => {
    this.setState({ RiskLayersDisplayed: true, SmlDisplayed: false });
  };

  RaiseSmlList = () => {
    this.setState({ RiskLayersDisplayed: false, SmlDisplayed: true });
  };

  Collapse = () => {
    this.setState({ IsRaised: false, SmlInsideArea: [], RiskLayersInsideArea: [], RiskLayersDisplayed: false, SmlDisplayed: true });
    this.props.OnClose();
  };

  SortListSml = (_sortBy, sortDirection) => {
    this.setState((prevState) => {
      const newList = sortBy(prevState.SmlInsideArea, [_sortBy]);
      if (sortDirection === SortDirection.DESC) {
        newList.reverse();
      }
      return { SmlInsideArea: newList, sortBySml: _sortBy, sortDirectionSml: sortDirection };
    });
  };

  SortListRiskLayer = (_sortBy, sortDirection) => {
    this.setState((prevState) => {
      const newList = sortBy(prevState.RiskLayersInsideArea, [_sortBy]);
      if (sortDirection === SortDirection.DESC) {
        newList.reverse();
      }
      return { RiskLayersInsideArea: newList, sortByRiskLayer: _sortBy, sortDirectionRiskLayer: sortDirection };
    });
  };

  render() {
    return (
      this.state.IsRaised && (
        <>
          <div className="SelectionMainForm_Node">
            <div className="SelectionMainForm_Header">
              <div className="SelectionMainForm_HeaderButton" onClick={this.RaiseSmlList} style={this.state.SmlDisplayed ? { borderBottom: `3px solid ${Color.Blue1}` } : { borderBottom: "none" }}>
                <label className="SelectionMainForm_HeaderButtonLabel" style={this.state.SmlDisplayed ? { color: Color.Blue1 } : { color: Color.Grey5 }}>
                  Selected locations
                </label>
              </div>
              {( RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_CategoryManager) && (
                <div className="SelectionMainForm_HeaderButton" onClick={this.RaiseRiskLayers} style={this.state.RiskLayersDisplayed ? { borderBottom: `3px solid ${Color.Blue1}` } : { borderBottom: "none" }}>
                  <label className="SelectionMainForm_HeaderButtonLabel" style={this.state.RiskLayersDisplayed ? { color: Color.Blue1 } : { color: Color.Grey5 }}>
                    Selected Risk layers
                  </label>
                </div>
              )}
            </div>
            <div className="SelectionMainForm_Content">
              {this.state.SmlDisplayed && <SelectionSmlList OnShowSurveyCreate={this.props.OnShowSurveyCreate} data={this.state.SmlInsideArea} SortList={this.SortListSml} OnRefresh={this.props.OnRefreshSml} OnDelete={this.props.OnDeleteSml} sortBy={this.state.sortBySml} sortDirection={this.state.sortDirectionSml} EnabledFeatures={this.props.EnabledFeatures} OngoingUpdates={this.props.OngoingUpdates}></SelectionSmlList>}
              {this.state.RiskLayersDisplayed && <SelectionRiskLayerList OnShowSurveyCreate={this.props.OnShowSurveyCreate} data={this.state.RiskLayersInsideArea} SortList={this.SortListRiskLayer} OnDelete={this.props.OnDeleteRiskLayer} sortBy={this.state.sortByRiskLayer} sortDirection={this.state.sortDirectionRiskLayer}></SelectionRiskLayerList>}
            </div>
            <div className="SelectionMainForm_Footer">
              <div className="SelectionMainForm_FooterButtonBar">
                <Button onClick={this.Collapse} className="SelectionMainForm_FooterButton" variant={ButtonVariant.Page}>
                  Close
                </Button>
              </div>
            </div>
          </div>
          <style jsx>
            {`
              .SelectionMainForm_Header {
                height: 64px;
                width: 100%;
                border-bottom: 1px solid ${Color.Grey3};
                line-height: 64px;
              }
              .SelectionMainForm_HeaderButton {
                float: left;
                width: 190px;
                height: 64px;
                line-height: 64px;
                text-align: center;
              }
              .SelectionMainForm_HeaderButtonLabel {
                font-size: 16px;
                font-weight: bold;
                color: ${Color.Grey5};
              }
              .SelectionMainForm_Content {
                width: 100%;
                height: calc(100% - 128px);
              }
              .SelectionMainForm_FooterButton {
                margin-left: 24px;
              }
              .SelectionMainForm_FooterButtonBar {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .SelectionMainForm_Footer {
                height: 64px;
                width: 100%;
                border-top: 1px solid ${Color.Grey3};
                line-height: 50px;
                bottom: 0;
                position: absolute;
              }
              .SelectionMainForm_Node {
                width: 650px;
                height: calc(100% - 132px);
                border-radius: 5px;
                background-color: white;
                position: absolute;
                top: 92px;
                left: 40px;
                z-index: 50;
                box-shadow: 0px 0px 2px ${Color.Grey4};
              }
            `}
          </style>
        </>
      )
    );
  }
}
export default SelectionMainForm;
