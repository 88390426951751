export function IsPointInCircle(point, center, radius) {
  // radius in km
  const ky = 40000 / 360;
  const kx = Math.cos((Math.PI * point.latitude) / 180.0) * ky;
  const dx = Math.abs(center.longitude - point.longitude) * kx;
  const dy = Math.abs(center.latitude - point.latitude) * ky;
  return Math.sqrt(dx * dx + dy * dy) <= radius;
}

export function generatePointsFromCircle(circle, numPoints) {
  let points = [];
  const degreeStep = 360 / numPoints;

  const circleLatLng = new window.google.maps.LatLng(circle.coordinates[0].lat, circle.coordinates[0].lng);

  for (var i = 0; i < numPoints; i++) {
    var gpos = window.google.maps.geometry.spherical.computeOffset(circleLatLng, circle.radius, degreeStep * i);
    points.push({ lat: gpos.lat(), lng: gpos.lng() });
  }

  return points;
}

// code from https://stackoverflow.com/a/39020487
const e = Math; // shortcut for the mathematical function
const D2R = e.PI / 180.0; // value used for converting degrees to radians

const toRadians = (number) => {
  return number * D2R;
};

function distance(lat0, lng0, lat1, lng1) {
  // convert degrees to radians
  var rlat0 = toRadians(lat0);
  var rlng0 = toRadians(lng0);
  var rlat1 = toRadians(lat1);
  var rlng1 = toRadians(lng1);

  // calculate the differences for both latitude and longitude (the deltas)
  var Δlat = rlat1 - rlat0;
  var Δlng = rlng1 - rlng0;

  // calculate the great use haversine formula to calculate great-circle distance between two points
  var a = e.pow(e.sin(Δlat / 2), 2) + e.pow(e.sin(Δlng / 2), 2) * e.cos(rlat0) * e.cos(rlat1);
  var c = 2 * e.asin(e.sqrt(a));
  var d = c * 6378137; // multiply by the radius of the great-circle (average radius of the earth in meters)

  return d;
}

export function hasIntersections(circle1, circle2) {
  var maxDist = circle1.radius + circle2.radius;
  var actualDist = distance(circle1.coordinates[0].lat, circle1.coordinates[0].lng, circle2.coordinates[0].lat, circle2.coordinates[0].lng);

  return maxDist >= actualDist;
}
