import React, { Component } from "react";
import { LinearProgress } from "@material-ui/core/";

class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = { IsRaised: false, Title: "" };
  }

  Raise = (titleText) => {
    this.setState({ Title: titleText, IsRaised: true });
  };

  Collapse = () => {
    this.setState({ IsRaised: false, Title: "" });
  };

  render = () => {
    return (
      this.state.IsRaised && (
        <>
          <div className="ProgressBar_Box">
            <div className="ProgressBar_Title">{this.state.Title}</div>
            <LinearProgress />
          </div>
          <style jsx>{`
            .ProgressBar_Box {
              padding: 10px;
              height: 60px;
              width: 30%;
              top: 60px;
              right: 0px;
              position: absolute;
              background-color: white;
              overflow: auto;
            }
            .ProgressBar_Title {
              margin-bottom: 10px;
              font-size: 12px;
              font-weight: bold;
            }
          `}</style>
        </>
      )
    );
  };
}
export default ProgressBar;
