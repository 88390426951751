import { Card, CardContent, Divider } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { Component } from "react";
import Color from "../libraries/SupplyOnUiConstants";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { ExpandMore } from "@material-ui/icons";

class RiskRequestCount extends Component {
  constructor(props) {
    super(props);
    this.state = { IsRaised: false, RequestCounts: [], YearCounts: [] };
  }

  Raise = (monthlyRequestCount) => {
    const uniqueYears = [];
    monthlyRequestCount.forEach((request) => {
      if (!uniqueYears.find((x) => x.year === request.year)) {
        uniqueYears.push(request);
      }
    });
    this.setState({ IsRaised: true, RequestCounts: monthlyRequestCount, YearCounts: uniqueYears });
  };
  Collapse = () => {
    this.setState({ IsRaised: false, RequestCounts: [], YearCounts: [] });
  };

  render = () => {
    return (
      this.state.IsRaised && (
        <>
          <div className="RiskRequestCount_Node">
            <div className="RequestCount_SectionFiltersHeader">
              <div className="RequestCount_CloseButton" onClick={this.Collapse}>
                <Close></Close>
              </div>
              <label className="RequestCount_HeaderLabel">Request counts</label>
            </div>
            <div className="ViewContextMenu_SectionDividerHeader" />
            <div className="RiskRequestCount_XRelationNode">
              <Card>
                <Divider />
                <CardContent>
                  {this.state.YearCounts.map((yearCount) => (
                    <div>
                      <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                          <Typography className="RequestCount_AccordionSummary">{yearCount.year}</Typography>
                          <Typography className="RequestCount_AccordionSummaryInfo">
                            - total locations{" "}
                          </Typography><Typography className="RequestCount_AccordionSummaryInfoCount">
                            
                            {this.state.RequestCounts.filter((x) => x.year === yearCount.year)
                              .map((x) => x.requestCount)
                              .reduce((a, b) => a + b, 0)}
                          </Typography>
                        </AccordionSummary>
                        {this.state.RequestCounts.map((requestCount) =>
                          requestCount.year === yearCount.year ? (
                            <AccordionDetails key={requestCount.requestCountId}>
                              <Typography className="RequestCount_AccordionContent">
                                <div> Provider : {requestCount.riskDataProvider} </div>
                                <div> Month : {requestCount.monthName}</div>
                                <div> Locations: {requestCount.requestCount}</div>
                              </Typography>
                            </AccordionDetails>
                          ) : null
                        )}
                      </Accordion>
                    </div>
                  ))}
                </CardContent>
              </Card>
            </div>
          </div>

          <style jsx>
            {`
              .RiskRequestCount_Node {
                width: 400px;
                height: calc(100% - 52px);
                background-color: white;
                position: absolute;
                top: 51px;
                right: 0;
                z-index: 70;
                border: 0px 0px 2px ${Color.Grey4};
                position: absolute;
              }
              .ViewContextMenu_SectionDividerHeader {
                border-bottom: 0.25px solid #dedede;
                margin-top: 15px;
              }
              .RiskRequestCount_XRelationNode {
                border: none;
                width: 400px;
                position: relative;
                height: calc(100% - 46px);
                left: 50%;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                overflow-y: scroll;
              }
              .RequestCount_SectionFiltersHeader {
                position: relative;
                height: 30px;
                width: 390px;
                padding-left: 30px;
                line-height: 30px;
                left: 50%;
                transform: translateX(-50%);
                top: 10px;
              }
              .RequestCount_HeaderLabel {
                font-size: 16px;
                font-weight: bold;
                margin-left: -16px;
              }
              .RequestCount_CloseButton {
                position: relative;
                width: 20px;
                height: 20px;
                margin-left: auto;
                float: right;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                color: ${Color.Grey5};
                margin-right: 8px;
              }
              .RequestCount_AccordionSummary {
                font-size: 16px;
                font-weight: bold;
              }
              .RequestCount_AccordionSummaryInfo {
                font-size: 16px;
                padding-left: 5px;
              }
              .RequestCount_AccordionSummaryInfoCount {
                font-size: 16px;
                font-weight: bold;
                padding-left: 5px;
              }
              .RequestCount_AccordionContent {
                font-size: 14px;
                font-family: Arial, sans-serif;
                letter-spacing: normal;
              }
              .MuiCardContent-root > div {
                margin-bottom: 10px;
                border: none;
              }
              .MuiPaper-root > .MuiDivider-root {
                display: none;
              }
            `}
          </style>
        </>
      )
    );
  };
}
export default RiskRequestCount;
