import { Button, ButtonVariant } from "@supplyon/ui-components";
import { Cancel, CheckCircle } from "@material-ui/icons/";
import { Checkbox, FormControlLabel, IconButton } from "@material-ui/core";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import React, { Component } from "react";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { FixedSizeList } from "react-window";
import Color from "../libraries/SupplyOnUiConstants";
import NotificationBackground from "./NotificationBackground";
import UserFileUploadService from "../services/UserFileUploadService";
import XLSX from "xlsx";
import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";

/*  Props: 

        events:
 
            OnFileUploadError: raised when there is error in file processing 
            OnUploadSuccess: raised when file processing succeds
            OnUploadInprogress: to raise progress bar when file upload is in progress
      
*/
//overriding default material ui theme
const theme = createTheme({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: "120px",
        backgroundColor: Color.Grey2,
        color: Color.Grey6,
        border: "none",
        margin: "24px",
        marginBottom: "0",
        width: "544px",
      },
      text: {
        marginBottom: "0",
      },
      icon: {
        color: Color.Grey6,
        width: "25px",
        height: "25px",
      },
      active: {
        backgroundColor: Color.Green2,
        backgroundImage: `repeating-linear-gradient(-45deg, ${Color.Green1}, ${Color.Green1} 25px, ${Color.Green2} 25px, ${Color.Green2} 50px)`,
      },
      invalid: {
        backgroundColor: Color.Red2,
        backgroundImage: `repeating-linear-gradient(-45deg, ${Color.Red1}, ${Color.Red1} 25px, ${Color.Red2} 25px, ${Color.Red2} 50px)`,
      },
    },
    MuiTypography: {
      h5: {
        fontSize: "1rem !important",
      },
    },
    MuiDropzoneSnackbar: {
      errorAlert: {
        backgroundColor: Color.Red1,
        color: Color.White,
      },
      successAlert: {
        backgroundColor: Color.Green1,
        color: Color.White,
      },
    },
  },
});

class UploadSupplierManufacturingFileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsRaised: false,
      Files: [],
      SavedSml: [],
      IsRetrieveRiskDataSet: false,
    };
  }

  Show = () => {
    this.setState({ IsRaised: true, IsRetrieveRiskDataSet: false }, () => {
      this.NotificationBackground.Raise();
    });
  };

  Collapse = () => {
    this.NotificationBackground.Collapse();
    this.setState({ Files: [], IsRaised: false, IsRetrieveRiskDataSet: false, SavedSml: [] });
  };

  UploadInProgress = () => {
    this.NotificationBackground.Collapse();
    this.setState({ IsRaised: false });
    this.props.OnUploadInprogress();
  };

  DeleteFileAtIndex = (index) => {
    const files = this.state.Files;
    files.splice(index, 1);
    this.setState({ Files: files });
  };

  FormatColumnName = (name) => {
    return name.replace(/[_\s]/g, "").toLowerCase();
  };

  ParseUploadedFilesToJson = (file) => {
    let base64string = file.data.toString().split(",");
    let buff = new Buffer.from(base64string[1], "base64");

    const wb = XLSX.read(buff, { type: "array", bookVBA: true });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    if (!ws || !ws["!ref"]) {
      //handle error
      return;
    }
    var range = XLSX.utils.decode_range(ws["!ref"]);
    var headers = [];
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var addr = XLSX.utils.encode_cell({ r: range.s.r, c: C });
      var cell = ws[addr];
      if (!cell) continue;
      headers.push(this.FormatColumnName(cell.v));
    }
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: headers, range: 1, raw: false });

    return data;
  };

  OnUpload = () => {
    this.UploadInProgress();
    let errorFileNames = [];
    let filesProcessed = 0;

    this.state.Files.forEach((file) => {
      let jsonSmls = this.ParseUploadedFilesToJson(file);
      if (jsonSmls === undefined || jsonSmls.length === 0) {
        filesProcessed++;
        errorFileNames = errorFileNames.concat(file.file.name);
        if (filesProcessed === this.state.Files.length) {
          this.OnAllFileProcessed(errorFileNames);
        }
        return;
      }
      let smlsUpdated = 0;
      jsonSmls.forEach((sml) => {
        if (!(sml.longitude || sml.longitude === 0) || !(sml.latitude || sml.latitude === 0)) {
          if (!sml.street || !sml.zip || !sml.city || !sml.regionname || !sml.country) {
            filesProcessed++;
            errorFileNames = errorFileNames.concat(file.file.name);
            if (filesProcessed === this.state.Files.length) {
              this.OnAllFileProcessed(errorFileNames);
            }
            return;
          } else {
            this.GetCoordinatesByAddress(sml).then((smlResponse) => {
              sml = smlResponse;
              smlsUpdated++;
              if (smlsUpdated === jsonSmls.length) {
                let fileData = { Name: file.file.name, ByteSize: file.file.size, SupplierManufacturingLocations: jsonSmls };
                this.SaveFileData(fileData, this.state.IsRetrieveRiskDataSet).then((result) => {
                  filesProcessed++;
                  //check for service response status
                  if (result === undefined || result === null || result === "") {
                    errorFileNames = errorFileNames.concat(file.file.name);
                  } else {
                    this.state.SavedSml.push(...result);
                  }

                  if (filesProcessed === this.state.Files.length) {
                    this.OnAllFileProcessed(errorFileNames);
                  }
                });
              }
            });
          }
        } else {
          //for removing unneccessary space in the file row
          sml.longitude = sml.longitude.replace(/^\s+|\s+$/g, "");
          sml.latitude = sml.latitude.replace(/^\s+|\s+$/g, "");
          smlsUpdated++;
          if (smlsUpdated === jsonSmls.length) {
            let fileData = { Name: file.file.name, ByteSize: file.file.size, SupplierManufacturingLocations: jsonSmls };
            this.SaveFileData(fileData, this.state.IsRetrieveRiskDataSet).then((result) => {
              filesProcessed++;
              //check for service response status
              if (result === undefined || result === null || result === "") {
                errorFileNames = errorFileNames.concat(file.file.name);
              } else {
                // mutating state
                this.state.SavedSml.push(...result);
              }
              if (filesProcessed === this.state.Files.length) {
                this.OnAllFileProcessed(errorFileNames);
              }
            });
          }
        }
      });
    });
  };

  OnDrop = (acceptedFiles) => {
    this.setState({ Files: this.state.Files.concat(acceptedFiles) });
  };

  //call the service with csv data payload
  SaveFileData = (fileData, getRiskData) => {
    return UserFileUploadService.SaveUploadedFileData(fileData, getRiskData).then((result) => {
      return result;
    });
  };

  IsEmpty = () => {
    return this.state.Files.length === 0 ? true : false;
  };

  OnAllFileProcessed(errorFileNames) {
    if (errorFileNames.length > 0) {
      //call the OnFileUploadError callback function defined in MainContainer.js
      this.props.OnFileUploadError(errorFileNames.length ? "'" + errorFileNames.join("', '") + "'" : "");
    }
    //some files processed successfully
    if (errorFileNames.length < this.state.Files.length) {
      this.props.OnUploadSuccess(this.state.SavedSml);
    }
    this.setState({ Files: [], IsRaised: false, IsRetrieveRiskDataSet: false, SavedSml: [] });
  }

  GetCoordinatesByAddress = (supplierManufacturingLocation) => {
    let formattedAddress = supplierManufacturingLocation.street + ", " + supplierManufacturingLocation.zip + ", " + supplierManufacturingLocation.city + ", " + supplierManufacturingLocation.regionname + ", " + supplierManufacturingLocation.country;
    return fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + formattedAddress + "&key=" + RiskManagementKeycloak.API_Key, {
      method: "GET",
    })
      .then((result) => result.json())
      .then((jsonResult) => {
        if (jsonResult !== null && jsonResult !== undefined && jsonResult.results !== undefined && jsonResult.results.length > 0) {
          if (jsonResult.results[0] !== null && jsonResult.results[0] !== undefined && jsonResult.results[0].geometry !== null && jsonResult.results[0].geometry !== undefined && jsonResult.results[0].geometry.location !== null && jsonResult.results[0].geometry.location !== undefined) {
            supplierManufacturingLocation.latitude = jsonResult.results[0].geometry.location.lat;
            supplierManufacturingLocation.longitude = jsonResult.results[0].geometry.location.lng;
          }
        }

        return supplierManufacturingLocation;
      });
  };

  OnRetriveCheckBoxClick = () => {
    this.setState((prevState) => ({
      IsRetrieveRiskDataSet: !prevState.IsRetrieveRiskDataSet,
    }));
  };

  render = () => {
    const fileObjects = this.state.Files.map((file) => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));

    const Row = ({ index, style }) => (
      <div style={style} className="UploadSupplierManufacturingFileForm_Chip UploadSupplierManufacturingFileForm_FilesUploadedChipColor">
        <div className="UploadSupplierManufacturingFileForm_UploadCheckIcon">
          <CheckCircle />
        </div>
        <div className="UploadSupplierManufacturingFileForm_FileLabel">
          <span>
            {this.state.Files[index].file.name} ({this.state.Files[index].file.size} bytes)
          </span>
        </div>
        <IconButton onClick={() => this.DeleteFileAtIndex(index)} color="primary" component="div" classes={{ root: "UploadSupplierManufacturingFileForm_UploadFileDeleteButton" }}>
          <Cancel />
        </IconButton>
      </div>
    );

    return (
      this.state.IsRaised && (
        <>
          <NotificationBackground
            ref={(notificationBackground) => {
              this.NotificationBackground = notificationBackground;
            }}
          />
          <div className="UploadSupplierManufacturingFileForm_Node">
            <div className="UploadSupplierManufacturingFileForm_Header">
              <div className="UploadSupplierManufacturingFileForm_HeaderLabel">Upload Locations</div>
              <div className="UploadSupplierManufacturingFileForm_Chip UploadSupplierManufacturingFileForm_MandatoryInfoLabel">* Marked fields are mandatory</div>
            </div>
            <div className="UploadSupplierManufacturingFileForm_Body">
              <MuiThemeProvider theme={theme}>
                <DropzoneAreaBase
                  onAdd={(files) => this.OnDrop(files)}
                  //accept only csv files
                  acceptedFiles={[".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                  filesLimit={100}
                  showPreviewsInDropzone={false}
                  dropzoneText="Drag and drop or browse *"
                  alertSnackbarProps={{ anchorOrigin: { vertical: "top", horizontal: "right" } }}
                />
              </MuiThemeProvider>
              <div>
                <p className="UploadSupplierManufacturingFileForm_AcceptedFilesInfo">Accepted File Types: *.xlsx</p>
              </div>
              <div className="UploadSupplierManufacturingFileForm_UploadedFiles">
                {/* for displaying scroll bar */}
                <FixedSizeList className="FixedSizeList" itemCount={fileObjects.length} itemSize={32} width={550} margin={25} height={70}>
                  {Row}
                </FixedSizeList>
              </div>
            </div>
            <div className="UploadSupplierManufacturingFileForm_Footer">
              <div>
                <Button onClick={this.Collapse} className="UploadSupplierManufacturingFileForm_FooterButton" variant={ButtonVariant.Page}>
                  Cancel
                </Button>
                <Button onClick={this.OnUpload} className="UploadSupplierManufacturingFileForm_FooterButton" variant={ButtonVariant.PageHighlight} disabled={this.IsEmpty()}>
                  Upload
                </Button>
                <FormControlLabel
                  control={
                    <Checkbox
                      // checked={this.state.AllFilesSelected}
                      onClick={() => this.OnRetriveCheckBoxClick()}
                      inputProps={{ "aria-label": "select" }}
                      disabled={this.IsEmpty()}
                    />
                  }
                  className="UploadSupplierManufacturingFileForm_Checkbox"
                  label="Retrieve risk data from data provider"
                ></FormControlLabel>
              </div>
            </div>
          </div>

          <style jsx>
            {`
              .UploadSupplierManufacturingFileForm_Checkbox {
                margin-top: 16px;
                font-size: 12px;
                margin-left: 16px;
              }
              .UploadSupplierManufacturingFileForm_UploadCheckIcon {
                float: left;
                padding: 6px;
              }
              .UploadSupplierManufacturingFileForm_UploadFileDeleteButton {
                float: right;
                color: ${Color.Grey5};
                font-size: 20px;
                padding: 6px;
              }
              .UploadSupplierManufacturingFileForm_UploadedFiles {
                margin: 20px;
              }
              .UploadSupplierManufacturingFileForm_AcceptedFilesInfo {
                margin-left: 40px;
              }
              .UploadSupplierManufacturingFileForm_FooterButton {
                margin-left: 24px;
                margin-top: 15px;
              }
              .UploadSupplierManufacturingFileForm_Body {
                height: 225px;
                width: 100%;
              }
              .UploadSupplierManufacturingFileForm_Footer {
                height: 64px;
                width: 100%;
                border-top: 1px solid ${Color.Grey3};
                line-height: 50px;
                bottom: 0;
                position: absolute;
              }
              .UploadSupplierManufacturingFileForm_Header {
                height: 64px;
                width: 100%;
                border-bottom: 1px solid ${Color.Grey3};

                float: left;
                margin-bottom: 20px;
              }
              .UploadSupplierManufacturingFileForm_HeaderLabel {
                font-size: 16px;
                font-weight: bold;
                margin-left: 24px;
                margin-top: 24px;
                width: 50%;
                float: left;
              }
              .UploadSupplierManufacturingFileForm_Node {
                width: 592px;
                height: 384px;
                border-radius: 5px;
                background-color: white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 105;
                border: 1px solid ${Color.Grey5};
              }
              .UploadSupplierManufacturingFileForm_Chip {
                height: 32px;
                margin: 0;
                outline: 0;
                padding: 0 4px 0 4px;
                box-sizing: border-box;
                transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                white-space: nowrap;
                border-radius: 16px;
                vertical-align: middle;
                text-decoration: none;
                border: 1px solid ${Color.Grey3};
                background-color: ${Color.White};
              }
              .UploadSupplierManufacturingFileForm_FilesUploadedChipColor {
                color: ${Color.Blue1};
              }
              .UploadSupplierManufacturingFileForm_FileLabel {
                float: left;
                height: inherit;
                font-size: 12px;
                padding-top: 8px;
                padding-left: 8px;
              }
              .UploadSupplierManufacturingFileForm_MandatoryInfoLabel {
                float: right;
                width: 184px;
                margin: 20px 24px 10px 10px;
                height: 23px;
                line-height: 20px;
                padding: 2px 4px 4px;
                font-size: 12px;
                text-align: center;
                color: gray;
              }
            `}
          </style>
        </>
      )
    );
  };
}
export default UploadSupplierManufacturingFileForm;
