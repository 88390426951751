import { Button, ButtonVariant } from "@supplyon/ui-components";
import { Delete, Menu, GetAppRounded } from "@material-ui/icons/";
import React, { PureComponent } from "react";
import clsx from "clsx";
import Color from "../libraries/SupplyOnUiConstants";
import { Checkbox, TableCell, Paper, Tooltip } from "@material-ui/core/";
import { DownloadSmlsAsExcel } from "../libraries/DownloadHelper";
import { FiberManualRecord, InfoRounded } from "@material-ui/icons/";
import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";
import VirtualizedTable from "./VirtualizedTable";
import { withStyles } from "@material-ui/core/styles";
import FeatureConstants from "../libraries/FeatureConstants";

/*  Props:
        events:
            onDelete
        
        properties:

            data: list of sml inside selected area
*/

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: Color.Grey5,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: Color.Grey5,
  },
}))(Tooltip);

const styles = () => ({
  paper: {
    width: "100%",
    height: "calc(100% - 64px)",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  tableRow: {
    cursor: "pointer",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: Color.Grey2,
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: "initial",
  },
  infoIcon: {
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "-4px",
    color: `${Color.Grey5}`,
    float: "left",
    "&:hover": {
      color: Color.Blue1,
    },
  },
  noIconSpan: {
    display: "inline-block",
    width: "10px",
  },
  buttonBar: {
    marginLeft: "24px",
    width: "100%",
    height: "64px",
  },
  downloadButton: {
    width: "112px",
    height: "32px",
    marginRight: "16px",
  },
  downloadIcon: {
    marginRight: "10px",
    width: "18px",
    height: "20px",
  },
  deleteButton: {
    width: "92",
    height: "32px",
    marginRight: "16px",
  },
  deleteIcon: {
    marginRight: "10px",
    width: "14px",
    height: "14px",
  },
  refreshButton: {
    height: "32px",
    marginRight: "16px",
  },
  selectedItemsLabel: {
    width: "70px",
    position: "relative",
    marginRight: "50px",
    marginTop: "16px",
    float: "right",
    transform: "translateY(-50%)",
    msTransform: "translateY(-50%)",
  },
  buttonBarSpan: {
    display: "block",
    paddingTop: "16px",
  },
  refreshToolTip: {
    paddingTop: "8px",
    paddingBottom: "11px",
  },
});

class SelectionSmlList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowIds: [],
    };
  }
  static defaultProps = {
    headerHeight: 33,
    rowHeight: 33,
  };

  columns = [
    {
      name: "checkbox",
      dataKey: "dataKey is a required prop but not used here",
      headerRenderer: () => this.HeaderRendererCheckbox(),
      cellRenderer: (rowData) => this.CellRendererCheckbox(rowData),
      cellProps: { style: { paddingRight: 0 } },
      width: 48,
      disableSort: true,
    },
    {
      width: 250,
      label: "Name",
      dataKey: "name",
      cellRenderer: (cellData, rowData) => this.CellRendererWithTooltip(cellData, rowData),
      disableSort: false,
    },
    {
      width: 250,
      label: "Address",
      dataKey: "address",
      disableSort: false,
    },
    {
      width: 100,
      label: "Gross risk",
      dataKey: "colorValue",
      cellRenderer: (cellData) => this.CellRendererRiskColor(cellData),
      disableSort: true,
    },
  ];

  HeaderRendererCheckbox = () => {
    const { headerHeight, classes } = this.props;

    return (
      <TableCell component="div" className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)} variant="head" style={{ height: headerHeight }} align={"left"}>
        <Checkbox indeterminate={this.state.selectedRowIds.length > 0 && this.state.selectedRowIds.length < this.props.data.length} checked={this.state.selectedRowIds.length === this.props.data.length} onChange={(event) => this.OnChangeHeaderCheckbox(event)} size="small" />
      </TableCell>
    );
  };

  CellRendererCheckbox = ({ rowData }) => {
    const { classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={"left"}
      >
        <Checkbox checked={this.state.selectedRowIds.some((id) => rowData.id === id)} size="small" />
      </TableCell>
    );
  };

  CellRendererRiskColor = ({ cellData }) => {
    const { classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={"left"}
      >
        <FiberManualRecord style={{ color: cellData }}></FiberManualRecord>
      </TableCell>
    );
  };

  CellRendererWithTooltip = ({ cellData, rowData }) => {
    const { classes, rowHeight, onRowClick } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={"left"}
      >
        {rowData.belongsToUserId === null && rowData.belongsToSmaId === null ? (
          <Tooltip title={"Location is generated by API and cannot be deleted."}>
            <InfoRounded className={classes.infoIcon}></InfoRounded>
          </Tooltip>
        ) : (
          <span className={classes.noIconSpan}></span>
        )}
        {cellData}
      </TableCell>
    );
  };

  RowGetter = ({ index }) => this.props.data[index];

  OnChangeHeaderCheckbox = (event) => {
    const newSelectedRowIds = event.target.checked ? this.props.data.map((item) => item.id) : [];
    this.setState({ selectedRowIds: newSelectedRowIds });
  };

  // clicking anywhere in a row works as onChange for checkboxes
  OnRowClick = ({ rowData }) => {
    const clickedRowId = rowData.id;
    const newSelectedRowIds = this.state.selectedRowIds.includes(clickedRowId) ? this.state.selectedRowIds.filter((id) => id !== clickedRowId) : [...this.state.selectedRowIds, clickedRowId];
    this.setState({ selectedRowIds: newSelectedRowIds });
  };

  OnRefreshClick = () => {
    const smlsToUpdate = this.props.data.filter((sml) => this.state.selectedRowIds.includes(sml.id));
    if (smlsToUpdate.length) {
      const idList = smlsToUpdate.map((item) => item.supplierManufacturingLocationId);
      this.props.OnRefresh(idList);
    }
  };

  OnDeleteClick = () => {
    const smlsToDelete = this.props.data.filter((sml) => (sml.belongsToUserId !== null || sml.belongsToSmaId !== null) && this.state.selectedRowIds.includes(sml.id));
    if (smlsToDelete.length) {
      const idList = smlsToDelete.map((item) => item.supplierManufacturingLocationId);
      this.props.OnDelete(idList);
    }
  };

  OnStartSurveyClick = () => {
    this.props.OnShowSurveyCreate(this.state.selectedRowIds);
  };

  OnDownloadClick = () => {
    const selectedSml = this.props.data.filter((sml) => this.state.selectedRowIds.includes(sml.id));
    DownloadSmlsAsExcel(selectedSml);
  };

  GetButtonState = () => {
    const disableButton = !this.props.data.some((item) => this.state.selectedRowIds.includes(item.id));
    const disableDeleteButton = disableButton || this.state.selectedRowIds.length === 0 || this.props.data.some((item) => item.belongsToUserId === null && item.belongsToSmaId === null && this.state.selectedRowIds.includes(item.id));
    const disableRefreshButton = disableButton || this.props.OngoingUpdates.length !== 0;
    return { disableButton, disableDeleteButton, disableRefreshButton };
  };

  render() {
    const { classes } = this.props;
    const { disableButton, disableDeleteButton, disableRefreshButton } = this.GetButtonState();
    return (
      <>
        <div className={classes.buttonBar}>
          <span className={classes.buttonBarSpan}>
            {(RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_CategoryManager) && (
              <Button onClick={this.OnDownloadClick} variant={ButtonVariant.Page} className={classes.downloadButton} disabled={disableButton}>
                <GetAppRounded className={classes.downloadIcon} />
                Download
              </Button>
            )}
            {(RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner) && (
              <Button variant={ButtonVariant.Page} className={classes.deleteButton} onClick={() => this.OnDeleteClick()} disabled={disableDeleteButton}>
                <Delete className={classes.deleteIcon} />
                Delete
              </Button>
            )}
            {RiskManagementKeycloak.Has_Role_RiskManager && (
              <LightTooltip title={this.props.OngoingUpdates.length !== 0 ? "Update ongoing" : ""}>
                <span className={classes.refreshToolTip}>
                  <Button onClick={this.OnRefreshClick} className={classes.refreshButton} disabled={disableRefreshButton} variant={ButtonVariant.Page}>
                    Refresh Risk Data
                  </Button>
                </span>
              </LightTooltip>
            )}
            {RiskManagementKeycloak.Has_Role_RiskManager && this.props.EnabledFeatures.find((key) => key === FeatureConstants.Survey) && (
              <Button onClick={this.OnStartSurveyClick} className={classes.refreshButton} disabled={disableButton} variant={ButtonVariant.Page}>
                <Menu className={classes.menuIcon} />
                Start Survey
              </Button>
            )}
            <div className={classes.selectedItemsLabel}>
              <label>Selected: {this.state.selectedRowIds.length}</label>
            </div>
          </span>
        </div>
        <Paper className={classes.paper}>
          <VirtualizedTable rowCount={this.props.data.length} rowGetter={this.RowGetter} columns={this.columns} selectedRowIds={this.state.selectedRowIds} onRowClick={this.OnRowClick} SortList={this.props.SortList} sortBy={this.props.sortBy} sortDirection={this.props.sortDirection} />
        </Paper>
      </>
    );
  }
}
export default withStyles(styles)(SelectionSmlList);
