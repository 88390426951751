import React, { PureComponent } from "react";
import { Search } from "@material-ui/icons";
import { StandaloneSearchBox } from "@react-google-maps/api";
import Color from "../libraries/SupplyOnUiConstants";

/*  Props: 
        events:
 
            onPlaceChanged:   raised by the user selecting a query
            
*/

class MenuBarSearchBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      SearchBox: null,
    };
  }

  onLoad = (ref) => {
    this.setState({ SearchBox: ref });
  };

  OnPlacesChanged = () => {
    const places = this.state.SearchBox?.getPlaces();
    if (places && places.length) {
      this.props.OnPlaceChanged(places[0].geometry.location.toJSON());
    }
  };

  render = () => {
    return (
      <>
        <div className="MenuBarSearchBox_NodeContainer">
          <div className="MenuBarSearchBox_IconContainer">
            <Search className="MenuBarSearchBox_Icon"></Search>
          </div>
          <div className="MenuBarSearchBox_TextBoxContainer">
            <StandaloneSearchBox onLoad={this.onLoad} onPlacesChanged={this.OnPlacesChanged}>
              <input type="text" className="MenuBarSearchBox_TextBox" placeholder="Search for locations" />
            </StandaloneSearchBox>
          </div>
        </div>
        <style jsx>
          {`
            .MenuBarSearchBox_StandaloneSearchBox {
              width: 90%;
            }
            .MenuBarSearchBox_Icon {
              color: ${Color.Grey5};
              width: 100%;
              height: 100%;
              margin-left: -7px;
            }
            .MenuBarSearchBox_IconContainer {
              width: 18px;
              height: 18px;
              float: left;
              top: 50%;
              position: relative;
              margin-left: 5px;
              transform: translateY(-50%);
              -ms-transform: translateY(-50%);
            }
            .MenuBarSearchBox_NodeContainer {
              height: 100%;
              width: 100%;
              border-bottom: 1px solid ${Color.Grey4};
            }

            .MenuBarSearchBox_NodeContainer:hover {
              border-bottom: 1px solid ${Color.Blue1};
            }

            .MenuBarSearchBox_IconContainer:hover .MenuBarSearchBox_Icon {
              color: ${Color.Blue1};
            }

            .MenuBarSearchBox_TextBoxContainer {
              margin-left: 10px;
              top: 50%;
              position: relative;
              transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              width: 380px;
              left: 5px;
            }

            .MenuBarSearchBox_TextBox {
              color: ${Color.Grey6};
              font-size: 14px;
              width: 90%;
              height: 100%;
              box-sizing: border-box;
              outline: none;
              border: none;
            }

            .MenuBarSearchBox_TextBox::placeholder {
              color: ${Color.Grey4};
              font-size: 12px;
              opacity: 0.75;
            }
          `}
        </style>
      </>
    );
  };
}
export default MenuBarSearchBox;
