import { Button, ButtonVariant } from "@supplyon/ui-components";
import React, { Component } from "react";
import Color from "../libraries/SupplyOnUiConstants";
import NotificationBackground from "./NotificationBackground";

class MessageBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OnCancel: () => {},
      OnConfirm: () => {},
      Message: "Message",
      Header: "MessageBoxHeader",
      IsRaised: false,
    };
  }

  Show = (header, message, onCancel, onConfirm) => {
    this.setState({ OnCancel: onCancel, OnConfirm: onConfirm, Header: header, Message: message, IsRaised: true }, () => {
      this.NotificationBackground.Raise();
    });
  };

  Collapse = () => {
    this.NotificationBackground.Collapse();
    this.setState({ OnCancel: () => {}, OnConfirm: () => {}, Header: "", Message: "", IsRaised: false });
  };

  OnConfirm = () => {
    this.state.OnConfirm();
    this.Collapse();
  };

  OnCancel = () => {
    this.state.OnCancel();
    this.Collapse();
  };

  render = () => {
    return (
      this.state.IsRaised && (
        <>
          <NotificationBackground
            ref={(notificationBackground) => {
              this.NotificationBackground = notificationBackground;
            }}
          ></NotificationBackground>
          <div className="MessageBox_Node">
            <div className="MessageBox_Header">
              <label className="MessageBox_HeaderLabel">{this.state.Header}</label>
            </div>
            <div className="MessageBox_Message">
              <label className="MessageBox_MessageLabel">{this.state.Message}</label>
            </div>
            <div className="MessageBox_Footer">
              <div className="CustomRiskLayerFormEdit_FooterButtonBar">
                <Button onClick={this.OnCancel} className="CustomRiskLayerFormEdit_FooterButton" variant={ButtonVariant.Page}>
                  Cancel
                </Button>
                <Button onClick={this.OnConfirm} className="CustomRiskLayerFormEdit_FooterButton" variant={ButtonVariant.PageHighlight}>
                  Confirm
                </Button>
              </div>
            </div>
          </div>
          <style jsx>
            {`
              .CustomRiskLayerFormEdit_FooterButton {
                margin-left: 25px;
              }
              .CustomRiskLayerFormEdit_FooterButtonBar {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .MessageBox_Message {
                width: 100%;
                height: 75px;
                position: absolute;
                top: 70px;
                line-height: 70px;
              }
              .MessageBox_MessageLabel {
                font-size: 15px;
                font-weight: normal;
                margin-left: 25px;
              }
              .MessageBox_Node {
                width: 600px;
                height: 210px;
                background-color: white;
                position: absolute;
                border: 1px solid grey;
                border-radius: 5px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50);
                z-index: 99;
              }

              .MessageBox_HeaderLabel {
                font-size: 18px;
                font-weight: bold;
                margin-left: 25px;
              }

              .MessageBox_Header {
                border-bottom: 1px solid ${Color.Grey3};
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 68px;
                line-height: 70px;
              }

              .MessageBox_Footer {
                border-top: 1px solid ${Color.Grey3};
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 64px;
              }
            `}
          </style>
        </>
      )
    );
  };
}
export default MessageBox;
