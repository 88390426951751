import React, { Component } from "react";
import MainContainer from "./components/MainContainer";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import RiskManagementKeycloak from "./libraries/RiskManagementKeycloak";
import { withTheme } from "@supplyon/ui-components";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
    };
  }

  render = () => {
    return withTheme(
      <>
        <ReactKeycloakProvider
          authClient={RiskManagementKeycloak.Instance}
          onEvent={(event) => {
            if (event === "onAuthSuccess") {
              RiskManagementKeycloak.OnAuthSuccess().then(() => {
                this.setState({ authenticated: RiskManagementKeycloak.Is_Ready });
              });
            } else {
              RiskManagementKeycloak.OnEvent(event);
            }
          }}
        >
          {this.state.authenticated && <MainContainer></MainContainer>}
        </ReactKeycloakProvider>
      </>
    );
  };
}
export default App;
