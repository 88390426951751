import React, { Component } from "react";
import SlideBar from "./SlideBar";

/*  Props: 
        properties:

            SupplierManufacturingLocationRisks: the list of risks. Every single risk gets mapped to a slidebar.
*/

class SupplierManufacturingLocationRisks extends Component {
  CheckForNull = (item) => {
    if (item && item.riskCategoryColorCodingThresholdModels) {
      return true;
    }
    return false;
  };

  render = () => {
    return (
      <>
        <div className="SupplierManufacturingLocationRisks_Node">
          {this.props.SupplierManufacturingLocationRisks.map(
            (risk) =>
              this.CheckForNull(risk.riskCategory) && (
                <div className="SupplierManufacturingLocationRisks_Slider">
                  <SlideBar ChangeRiskScore={() => {}} IsChangeable={false} Score={risk.score} Thresholds={risk.riskCategory.riskCategoryColorCodingThresholdModels} RiskName={risk.riskCategory.name} />
                </div>
              )
          )}
        </div>
        <style jsx>
          {`
            .SupplierManufacturingLocationRisks_Slider {
              width: 534px;
              height: 70px;
              position: relative;
              left: 50%;
              transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              margin-top: 8px;
            }
            .SupplierManufacturingLocationRisks_Node {
              width: 100%;
              height: 100%;
              overflow-y: scroll;
              padding-bottom: 20px;
            }
          `}
        </style>
      </>
    );
  };
}
export default SupplierManufacturingLocationRisks;
