import { Button, ButtonVariant, MultiComboBox, SingleComboBox, TextField } from "@supplyon/ui-components";
import { FormControlLabel, Switch } from "@material-ui/core";
import React, { Component } from "react";
import { Close } from "@material-ui/icons";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import Color from "../libraries/SupplyOnUiConstants";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import { IsPointInCircle } from "../libraries/CollisionDetectionHelper";
import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";

const choices = "choices";
const belongsToUserId = "belongsToUserId";
const belongsToSmaId = "belongsToSmaId";

/*  Props: 
        events:
 
            OnFilterApply: raised by onClick of Save button, gets filtered list of SupplierManufacturingLocations as parameter
            OnFilterReset: raised by onClick of Reset button

        properties:

            SupplierManufacturingLocations: the unfiltered list of SupplierManufacturingLocations
*/

// allowed role combinations
const allExceptVisitor = () => {
  return RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_CategoryManager;
};

const allExceptClusterOwner = () => {
  return RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_Visitor || RiskManagementKeycloak.Has_Role_CategoryManager;
};

const RiskAndCategoryManager = () => {
  return RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_CategoryManager;
};

/*  filter types: 
        string, choiceBool, choiceMulti, number
    path types: 
        simple, nestedArrayOfObjects

*/
const createFilterCriteria = () => {
  return [
    // simple fields
    { id: "smlName", label: "Name", filterType: "string", key: "name", pathType: "simple", allowedRoles: true },
    { id: "smlUniqueKey", label: "SML id", filterType: "string", key: "supplierManufacturingLocationKey", pathType: "simple", allowedRoles: true },
    { id: "smlCountry", label: "Country", filterType: "choiceMulti", key: "country", pathType: "simple", allowedRoles: true },
    { id: "smlRegionId", label: "Region id", filterType: "choiceMulti", key: "regionId", pathType: "simple", allowedRoles: true },
    { id: "smlRegionName", label: "Region name", filterType: "choiceMulti", key: "regionName", pathType: "simple", allowedRoles: true },
    { id: "smlCity", label: "City", filterType: "choiceMulti", key: "city", pathType: "simple", allowedRoles: true },
    { id: "smlZip", label: "ZIP code", filterType: "string", key: "zip", pathType: "simple", allowedRoles: true },
    { id: "smlStreet", label: "Street", filterType: "string", key: "street", pathType: "simple", allowedRoles: true },
    { id: "smlLifecycleStatus", label: "Lifecycle status", filterType: "string", key: "lifecycleStatus", pathType: "simple", allowedRoles: allExceptClusterOwner() },
    { id: "smlMitigatedRisk", label: "Mitigated Risk Color", filterType: "choiceMulti", key: "mitigatedRiskColorCodingKey", pathType: "simple", allowedRoles: RiskAndCategoryManager() },
    // nested in object
    { id: "smlAggregatedRisk", label: "Aggregated High Risk", filterType: "choiceMulti", key: "calculatedHighColor.colorCodingKey", pathType: "simple", allowedRoles: allExceptVisitor() },
    { id: "smlSupplierName", label: "Supplier name", filterType: "choiceMulti", key: "supplier.name", pathType: "simple", allowedRoles: allExceptClusterOwner() },
    { id: "smlSupplierUniqueKey", label: "Supplier id", filterType: "string", key: "supplier.supplierKey", pathType: "simple", allowedRoles: allExceptClusterOwner() },
    { id: "smlUserFile", label: "Belongs to uploaded file", filterType: "choiceMulti", key: "userFile.name", pathType: "simple", allowedRoles: allExceptVisitor() },
    // nested in array of objects
    { id: "smlLocationTypes", label: "Location type", filterType: "choiceMulti", key: "locationTypes", secondaryKey: "name", pathType: "nestedArrayOfObject", allowedRoles: allExceptClusterOwner() },
    { id: "smlLocationTypesOfSupplier", label: "Location type of supplier", filterType: "choiceMulti", key: "locationTypeOfSuppliers", secondaryKey: "name", pathType: "nestedArrayOfObject", allowedRoles: allExceptClusterOwner() },
    { id: "supplyRelationMaterial", label: "Relation to Material (id)", filterType: "string", key: "supplyRelations", secondaryKey: "materialKey", pathType: "nestedArrayOfObject", allowedRoles: allExceptClusterOwner() },
    { id: "supplyRelatioSupplier", label: "Relation to Supplier (id)", filterType: "string", key: "supplyRelations", secondaryKey: "supplierKey", pathType: "nestedArrayOfObject", allowedRoles: allExceptClusterOwner() },
    { id: "supplyRelationMaterialNumber", label: "Material number", filterType: "string", key: "supplyRelations", secondaryKey: "materialNumber", pathType: "nestedArrayOfObject", allowedRoles: allExceptClusterOwner() },
    { id: "supplyRelationMaterialStatusShort", label: "Material status short", filterType: "string", key: "supplyRelations", secondaryKey: "materialStatusShort", pathType: "nestedArrayOfObject", allowedRoles: allExceptClusterOwner() },
    { id: "supplyRelationMaterialStatusLong", label: "Material status long", filterType: "string", key: "supplyRelations", secondaryKey: "materialStatusLong", pathType: "nestedArrayOfObject", allowedRoles: allExceptClusterOwner() },
    // Example for filterType: "choiceBool"
    // { id: "smlDeletedFlag", label: "To be deleted", filterType: "choiceBool", key: "toBeDeletedFlag", choices: ["true", "false"] },
    // Example for filterType: "number"
    // { id: "smlMatQuantity", label: "Material quantity", filterType: "number", key: "materialQuantity", min: 0, max: 1 },
  ];
};

class FilterContextMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsRaised: false,
      filterCriteria: [],
      filterChoices: [],
      filters: {},
      showSapLocations: true,
      showUserLocations: true,
      filterLocationRadiusCenter: "",
      filterLocationRadius: 1,
    };
  }

  Raise = () => {
    const initialFilterCriteria = this.createFilterOptions(this.props.SupplierManufacturingLocations);
    const initialChoices = cloneDeep(initialFilterCriteria);
    this.setState({ filterChoices: initialChoices, filterCriteria: initialFilterCriteria, IsRaised: true });
  };

  Collapse = () => {
    this.setState({ IsRaised: false });
  };

  shouldUpdateFilterOptions = () => {
    let shouldUpdate = 0;
    let currentFilterKey = "";
    Object.entries(this.state.filters).forEach(([key, value]) => {
      if (value.length) {
        currentFilterKey = key;
        shouldUpdate++;
      }
    });
    return { shouldUpdate, currentFilterKey };
  };

  createFilterOptions = (supplierManufacturingLocations) => {
    let filterCriteria = createFilterCriteria();

    const { shouldUpdate, currentFilterKey } = this.shouldUpdateFilterOptions();

    // if all filters empty use initial filter choices
    if (shouldUpdate === 0 && Object.keys(this.state.filters).length) {
      return cloneDeep(this.state.filterChoices);
    }

    // create new Set for filter criterias
    Object.entries(filterCriteria).forEach(([index, filter]) => {
      if (filter.filterType === "choiceMulti" && !(shouldUpdate === 1 && currentFilterKey === filter.id)) {
        filterCriteria[index][choices] = new Set();
      }
    });

    supplierManufacturingLocations.forEach((smlObj) => {
      Object.entries(filterCriteria).forEach(([index, filter]) => {
        if (filter.filterType === "choiceMulti") {
          // Skip updating options for this filter
          if (shouldUpdate === 1 && currentFilterKey === filter.id) {
            return;
          }

          if (filter.pathType === "simple") {
            const objValue = get(smlObj, filter.key);
            if (objValue) {
              filterCriteria[index][choices].add(objValue);
            }
          } else if (filter.filterType === "choiceMulti" && filter.pathType === "nestedArrayOfObject") {
            const valueArray = get(smlObj, filter.key);
            if (Array.isArray(valueArray)) {
              valueArray.forEach((value) => {
                if (value) {
                  filterCriteria[index][choices].add(get(value, filter.secondaryKey));
                }
              });
            }
          }
        } else if (filter.filterType === "number") {
          const quantity = smlObj[filter.key];
          if (quantity < filterCriteria[index]["min"]) {
            filterCriteria[index]["min"] = quantity;
          } else if (quantity > filterCriteria[index]["max"]) {
            filterCriteria[index]["max"] = quantity;
          }
        }
      });
    });

    Object.entries(filterCriteria).forEach(([index, filter]) => {
      // Combobox expects an array but choices is a set of unique values
      if (filter.filterType === "choiceMulti") {
        // if only one filter has values, show initial choices for that filter
        if (shouldUpdate === 1 && currentFilterKey === filter.id) {
          filterCriteria[index][choices] = this.state.filterChoices[index][choices];
        } else {
          const optionsSet = filterCriteria[index][choices];
          filterCriteria[index][choices] = [...optionsSet].sort();

          // keep old options if new options less than amount of selected items
          if (filterCriteria[index][choices].length < this.state.filters[filter.id]?.length) {
            filterCriteria[index][choices] = this.state.filters[filter.id];
          }
        }
      }
    });

    if (shouldUpdate === 0 && Object.keys(this.state.filters).length) {
      return cloneDeep(this.state.filterChoices);
    }

    return filterCriteria;
  };

  filterComponents = {
    string: ({ filter, value }) => <TextField label={filter.label} onChange={(newValue) => this.OnChangeTextField(newValue, filter.id)} onBlur={this.onBlur} placeholder={"Enter " + filter.label} value={value || ""} />,
    choiceBool: ({ filter, value }) => <SingleComboBox label={filter.label} onChange={(selection) => this.OnChangeSingleComboBox(selection, filter.id)} onBlur={this.onBlur} options={filter.choices} placeholder="Filter and Select Option" required={false} value={value} />,
    choiceMulti: ({ filter, value }) => (
      <MultiComboBox
        label={filter.label}
        multiple={true}
        getOptionLabel={(option) => (typeof option != "undefined" ? option : [])}
        renderTags="useChips"
        onChange={(selection) => this.OnChangeMultiComboBox(selection, filter.id)}
        onBlur={this.onBlur}
        options={filter.choices}
        placeholder="Filter and Select Option(s)"
        required={false}
        useListVirtualization={true}
        selectAllLabel="Select All"
        value={value || []}
      />
    ),
    number: ({ filter, value }) => (
      <div className="FilterContextMenu_Slider">
        <Typography id="rangeSliderLabel" gutterBottom>
          {filter.label} range
        </Typography>
        <Slider
          value={value || [filter.min, filter.max]}
          onChange={(e, range) => this.OnChangeSliderRange(e, range, filter.id)}
          onBlur={this.onBlur}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          min={filter.min}
          max={filter.max}
          marks={[
            { value: filter.min, label: filter.min },
            { value: filter.max, label: filter.max },
          ]}
        />
      </div>
    ),
  };

  onBlur = () => {
    const filteredList = this.ApplyFilters(this.props.SupplierManufacturingLocations, this.state.filters);
    const filterCriteria = this.createFilterOptions(filteredList);
    this.setState({ filterCriteria: filterCriteria });
  };

  OnChangeMultiComboBox = (selection, filterId) => {
    selection = typeof selection !== "undefined" ? selection : [];
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [filterId]: selection,
      },
    }));
  };

  OnChangeSingleComboBox = (selection, filterId) => {
    selection = typeof selection !== "undefined" ? selection : "";
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [filterId]: selection,
      },
    }));
  };

  OnChangeSliderRange = (e, newValue, filterId) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [filterId]: newValue,
      },
    }));
  };

  OnChangeSliderRadius = (e, newValue) => {
    this.setState({
      filterLocationRadius: newValue,
    });
  };

  OnChangeSwitch = (key) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  OnChangeTextField = (selection, filterId) => {
    selection = typeof selection !== "undefined" ? selection : "";
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [filterId]: selection,
      },
    }));
  };

  OnChangeRadiusLocation = (selection) => {
    selection = typeof selection !== "undefined" ? selection : "";
    this.setState({
      filterLocationRadiusCenter: selection,
    });
  };

  OnClickApplyFilters = () => {
    const filteredList = this.ApplyFilters(this.props.SupplierManufacturingLocations, this.state.filters);
    this.props.OnApply(filteredList);
  };

  OnClickResetFilters = () => {
    let _filters = {};
    this.setState({ filters: _filters, showSapLocations: true, showUserLocations: true, filterLocationRadiusCenter: "", filterLocationRadius: 1 }, () => {
      const filteredList = this.ApplyFilters(this.props.SupplierManufacturingLocations, this.state.filters);
      const newFilterCriteria = this.createFilterOptions(filteredList);
      this.setState({ filterCriteria: newFilterCriteria });
    });

    this.props.OnReset();
  };

  ApplyFilters = (supplierManufacturingLocations, filters) => {
    let smlList = supplierManufacturingLocations;

    // Check whether to filter only around a location
    if (this.state.filterLocationRadiusCenter) {
      const centerLocation = smlList.find((sml) => sml.supplierManufacturingLocationKey === this.state.filterLocationRadiusCenter);
      if (centerLocation != null) {
        smlList = supplierManufacturingLocations.filter((sml) => {
          return IsPointInCircle(sml, centerLocation, this.state.filterLocationRadius);
        });
      }
    }

    return smlList.filter((smlObj) => {
      const isSapLocation = !(smlObj[belongsToUserId] || smlObj[belongsToSmaId]) && this.state.showSapLocations;
      const isUserLocation = (smlObj[belongsToUserId] || smlObj[belongsToSmaId]) && this.state.showUserLocations;

      if (!(isSapLocation || isUserLocation)) {
        return false;
      }

      return Object.entries(filters).every(([key, value]) => {
        if (!value || value.length === 0) {
          // ignore empty filters
          return true;
        }

        let valueAtKey = null;
        const _filter = this.state.filterCriteria.find((f) => f.id === key);

        switch (_filter.filterType) {
          case "string":
            valueAtKey = get(smlObj, _filter.key);
            if (_filter.pathType === "simple") {
              return valueAtKey ? valueAtKey.toLowerCase().includes(value.toLowerCase()) : false;
            } else {
              // pathtype == nestedArrayOfObject
              return Array.isArray(valueAtKey) ? valueAtKey.some((x) => get(x, _filter.secondaryKey).toLowerCase().includes(value.toLowerCase())) : false;
            }

          case "choiceBool":
            return smlObj[_filter.key].toString() === value;

          case "choiceMulti":
            valueAtKey = get(smlObj, _filter.key);
            if (_filter.pathType === "simple") {
              return value.includes(valueAtKey);
            } else {
              // pathtype == nestedArrayOfObject
              return Array.isArray(valueAtKey) ? valueAtKey.some((x) => value.includes(get(x, _filter.secondaryKey))) : false;
            }

          case "number":
            return value[0] <= smlObj[_filter.key] && smlObj[_filter.key] <= value[1];

          default:
            return true;
        }
      });
    });
  };

  renderFilters(f) {
    const Component = this.filterComponents[f.filterType];
    return <Component filter={f} value={this.state.filters[f.id]} />;
  }

  render = () => {
    return (
      this.state.IsRaised && (
        <>
          <div className="FilterContextMenu_Node">
            <div className="FilterContextMenu_SectionFiltersHeader">
              <div className="FilterContextMenu_CloseButton" onClick={this.Collapse}>
                <Close></Close>
              </div>
              <label className="FilterContextMenu_HeaderLabel">Filter locations</label>
            </div>
            <div className="FilterContextMenu_SectionDividerHeader" />
            <div className="FilterContextMenu_SectionFilters">
              {this.state.filterCriteria.length && this.props.SupplierManufacturingLocations.length && (
                <div className="FilterContextMenu_SectionFiltersContent">
                  {( RiskAndCategoryManager()) && (
                    <div className="FilterContextMenu_SectionSwitches">
                      <div className="FilterContextMenu_Switch">
                        <FormControlLabel control={<Switch checked={this.state.showSapLocations} onChange={() => this.OnChangeSwitch("showSapLocations")} />} label="Show API based locations" />
                      </div>
                      <div className="FilterContextMenu_Switch">
                        <FormControlLabel control={<Switch checked={this.state.showUserLocations} onChange={() => this.OnChangeSwitch("showUserLocations")} />} label="Show manually added locations" />
                      </div>
                    </div>
                  )}
                  <div className="FilterContextMenu_SectionDividerSwitch" />
                  {this.state.filterCriteria.map((f) => f.allowedRoles && <React.Fragment key={f.id}>{this.renderFilters(f)}</React.Fragment>)}
                  <div className="FilterContextMenu_SectionDivider" />
                  {/* Filter in a radius around a location */}
                  <div key="filterRadiusLabel" className="FilterContextMenu_Slider">
                    <Typography id="raidusSMLFieldLabel" gutterBottom>
                      Filter in range around a location
                    </Typography>
                  </div>
                  <TextField key="filterLocationRadius" label="SML id" onChange={(newValue) => this.OnChangeRadiusLocation(newValue)} placeholder="Enter SML id" value={this.state.filterLocationRadiusCenter} />
                  <div key="filterRadius" className="FilterContextMenu_Slider">
                    <Typography id="radiusSliderLabel" gutterBottom>
                      Radius in kilometers
                    </Typography>
                    <Slider value={this.state.filterLocationRadius} onChange={(e, range) => this.OnChangeSliderRadius(e, range)} valueLabelDisplay="auto" aria-labelledby="continuous-slider" min={1} max={500} />
                  </div>
                </div>
              )}
            </div>
            <div className="FilterContextMenu_Footer">
              <div className="FilterContextMenu_FooterButtonBar">
                <Button onClick={this.OnClickResetFilters} className="FilterContextMenu_FooterButton" variant={ButtonVariant.Table}>
                  Reset
                </Button>
                <Button onClick={this.props.OnShowList} className="FilterContextMenu_FooterButton" variant={ButtonVariant.Table}>
                  Show list
                </Button>
                <Button onClick={this.OnClickApplyFilters} className="FilterContextMenu_FooterButton" variant={ButtonVariant.TableHighlight}>
                  Apply
                </Button>
              </div>
            </div>
          </div>
          <style jsx>
            {`
              .FilterContextMenu_SectionSwitches {
                margin-bottom: 20px;
              }
              .FilterContextMenu_Footer {
                height: 64px;
                width: 100%;
                border-top: 1px solid ${Color.Grey3};
                line-height: 64px;
                bottom: 0;
                position: absolute;
              }
              .FilterContextMenu_FooterButton {
                margin-left: 24px;
                margin-top: 10px;
                margin-bottom: 10px;
              }
              .FilterContextMenu_FooterButtonBar {
                display: flex;
                justify-content: left;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .FilterContextMenu_Slider {
                margin-left: 15px;
                margin-right: 15px;
                margin-bottom: 5px;
              }
              .FilterContextMenu_Switch {
                margin-left: -12px;
                padding: 0;
                margin-bottom: -10px;
              }
              .FilterContextMenu_SectionDivider {
                border-bottom: 1px solid ${Color.Grey3};
                margin-bottom: 22px;
              }
              .FilterContextMenu_SectionDividerHeader {
                border-bottom: 1px solid ${Color.Grey3};
                margin-top: 15px;
              }
              .FilterContextMenu_SectionDividerSwitch {
                border-bottom: 1px solid ${Color.Grey3};
                margin-bottom: 22px;
                margin-top: 22px;
              }
              .FilterContextMenu_SectionFiltersContent {
                width: 90%;
                height: 100%;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                top: 10px;
              }
              .FilterContextMenu_SectionFiltersHeader {
                position: relative;
                height: 30px;
                width: 390px;
                padding-left: 30px;
                line-height: 30px;
                left: 50%;
                transform: translateX(-50%);
                top: 10px;
              }
              .FilterContextMenu_HeaderLabel {
                font-size: 16px;
                font-weight: bold;
                margin-left: -10px;
              }
              .FilterContextMenu_CloseButton {
                position: relative;
                width: 20px;
                height: 20px;
                margin-left: auto;
                float: right;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                color: ${Color.Grey5};
                margin-right: 8px;
              }
              .FilterContextMenu_CloseButton:hover {
                color: ${Color.Blue1};
              }              
              .FilterContextMenu_SectionFilters {
                width: 100%;
                height: calc(100% - 117px);
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                overflow-y: scroll;
              }
              .FilterContextMenu_Node {
                height: calc(100% - 52px);
                width: 400px;
                position: absolute;
                top: 51px;
                right: 0;
                background-color: ${Color.White};
                z-index: 70;
                box-shadow: 0px 0px 2px ${Color.Grey4};
              }
            `}
          </style>
        </>
      )
    );
  };
}
export default FilterContextMenu;
