import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";

class CISCompanyService {
  static GetFeatureCompanyByCompanySpinId = () => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_CorporateIdentityService_URL + "/company/" + RiskManagementKeycloak.Spin_Id + "/featureCompany", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: RiskManagementKeycloak.Access_Token,
        },
      }).then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return null;
        }
      });
    });
  };
}
export default CISCompanyService;
