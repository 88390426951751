import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import Color from "../libraries/SupplyOnUiConstants";

class NotificationBackground extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsRaised: false,
      ShowProgressIndicator: false,
      Message: "",
    };
  }

  Raise = (withProgressIndicator, message) => {
    if (withProgressIndicator === undefined || withProgressIndicator === null) {
      withProgressIndicator = false;
    }
    if (message === undefined || message === null) {
      message = "";
    }
    this.setState({ IsRaised: true, ShowProgressIndicator: withProgressIndicator, Message: message });
  };

  Collapse = () => {
    this.setState({ IsRaised: false, ShowProgressIndicator: false, Message: "" });
  };

  render = () => {
    return (
      this.state.IsRaised && (
        <>
          <div className="NotificationBackground_Node">
            {this.state.ShowProgressIndicator && (
              <>
                <div className="NotificationBackground_TextContainer">
                  <label className="NotificationBackground_Text">{this.state.Message}</label>
                </div>

                <CircularProgress className="NotificationBackground_ProcessIndicator" variant="indeterminate" />
              </>
            )}
          </div>
          <style jsx>
            {`
              .NotificationBackground_TextContainer {
                position: absolute;
                background-color: ${Color.Grey6};
                opacity: 0.8;
                top: calc(50% - 30px);
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                z-index: 99;
              }
              .NotificationBackground_Text {
                z-index: 99;
                font-size: 16px;
                color: ${Color.Grey3};
              }
              .NotificationBackground_ProcessIndicator {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                z-index: 99;
              }
              .NotificationBackground_Node {
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: ${Color.Grey6};
                opacity: 0.8;
                z-index: 75;
              }
            `}
          </style>
        </>
      )
    );
  };
}
export default NotificationBackground;
