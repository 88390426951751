import React, { Component } from "react";
import { Place } from "@material-ui/icons";

/*  Props: 
        events:
 
            OnClick:  raised by onClick on the button
            ChangeRiskScore: changes the score on slide


        properties:

            IsChangeable: on false the slider can not be moved
            Thresholds: the list of thresholds with colors
            Score: the current score of the element
            RiskName: the name of the slider
*/

class SlideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      risk: {
        colorCodings: [{ colorValue: "lightgrey", upperThreshold: 10 }],
        riskName: "River Flood",
      },
      MarkerColor: "",
      MouseDown: false,
      MouseMove: false,
    };
  }

  OnMouseUp = () => {
    if (this.props.IsChangeable) {
      let newScore = +parseFloat(this.state.risk.score).toFixed(1);
      let currentRisk = this.state.risk;

      currentRisk.score = newScore;
      this.props.ChangeRiskScore(currentRisk);
      this.setState({ MouseDown: false });
    }
  };

  OnMouseDown = () => {
    if (this.state.IsChangeable) {
      this.setState({ MouseDown: true });
    }
  };

  OnMouseMove = (e) => {
    if (this.state.MouseDown && this.props.IsChangeable) {
      let movement = Math.abs(e.movementX) * (0.0215 / (this.Node.offsetWidth / 480));

      if (e.movementX > 0) {
        let oldScore = this.state.risk;
        let calcScore = +parseFloat(oldScore.score).toFixed(2) + movement;

        if (parseFloat(calcScore) <= parseFloat(10.0)) {
          oldScore.score = calcScore;
          this.props.ChangeRiskScore(oldScore);
        }
      } else {
        let oldScore = this.state.risk;
        let calcScore = +parseFloat(oldScore.score).toFixed(2) - movement;

        if (parseFloat(calcScore) >= parseFloat(0.0)) {
          oldScore.score = calcScore;
          this.props.ChangeRiskScore(oldScore);
        }
      }

      this.CalculateMarkerColor();
    }
  };

  CalculateMarkerColor = () => {
    let isBigger = false;
    let result = "";

    for (let i = 0; i < this.props.Thresholds.length; i++) {
      let item = this.props.Thresholds[i];
      if (i === 0) {
        if (item.upperThreshold >= this.props.Score) {
          result = item.colorCoding.colorValue;
          break;
        } else {
          isBigger = true;
        }
      } else if (isBigger) {
        if (item.upperThreshold >= this.props.Score) {
          result = this.props.Thresholds[i].colorCoding.colorValue;
          break;
        }
      }
    }

    this.setState({ MarkerColor: result });
  };

  componentDidMount = () => {
    this.CalculateMarkerColor();
  };

  render = () => {
    if (this.props.Thresholds.length > 0) {
      let previousThreshold = 0;
      let isFirst = true;
      let isLast = false;
      let highestThreshold = this.props.Thresholds[this.props.Thresholds.length - 1].upperThreshold;
      return (
        <>
          <div
            className="NodeContainer"
            ref={(node) => {
              this.Node = node;
            }}
          >
            <label className="LabelZero">0</label>
            <label className="LabelFive">{(highestThreshold / 2) % 1 === 0 ? highestThreshold / 2 : parseFloat(highestThreshold / 2).toFixed(2)}</label>
            <label className="LabelTen">{highestThreshold}</label>
            <label className="LabelRiskName">{this.props.RiskName}</label>
            <div style={{ left: (100 / highestThreshold) * this.props.Score + "%" }} className="MarkerContainer" onMouseMove={this.OnMouseMove} onMouseDown={this.OnMouseDown} onMouseUp={this.OnMouseUp} onMouseLeave={this.OnMouseUp}>
              <div style={{ backgroundColor: this.state.MarkerColor }} className="CircleFiller"></div>
              <label style={{ backgroundColor: this.state.MarkerColor }} className="MarkerLabel">
                {this.props.Score.toFixed(1)}
              </label>
              <Place style={{ color: this.state.MarkerColor }} className="Marker"></Place>
            </div>

            <div className="BarContainer">
              {this.props.Thresholds.map((threshold) => {
                if (threshold.upperThreshold % 1 === 0) {
                  let values = [];
                  let previousUpperThreshold = previousThreshold % 1 === 0 ? previousThreshold : Math.ceil(previousThreshold);
                  for (let i = 0; i < threshold.upperThreshold - previousUpperThreshold; i++) {
                    isLast = this.props.Thresholds.indexOf(threshold) === this.props.Thresholds.length - 1 && i === threshold.upperThreshold - previousUpperThreshold - 1;

                    if (isFirst) {
                      isFirst = false;
                      values.push(
                        <div style={{ backgroundColor: threshold.colorCoding.colorValue, float: "left", width: 100 / highestThreshold + "%" }} className="BarItem First">
                          <div className="DividerRight"></div>
                        </div>
                      );
                    } else if (isLast) {
                      values.push(
                        <div style={{ backgroundColor: threshold.colorCoding.colorValue, float: "left", width: 100 / highestThreshold + "%" }} className="BarItem Last">
                          <div className="DividerLeft"></div>
                        </div>
                      );
                    } else {
                      values.push(
                        <div style={{ backgroundColor: threshold.colorCoding.colorValue, float: "left", width: 100 / highestThreshold + "%" }} className="BarItem">
                          <div className="DividerLeft"></div>
                          <div className="DividerRight"></div>
                        </div>
                      );
                    }
                  }
                  previousThreshold = threshold.upperThreshold;
                  return values;
                } else {
                  let values = [];

                  let previousUpperThreshold = previousThreshold % 1 === 0 ? previousThreshold : Math.ceil(previousThreshold);
                  for (let i = 0; i < Math.floor(threshold.upperThreshold) - previousUpperThreshold; i++) {
                    isLast = this.props.Thresholds.indexOf(threshold) === this.props.Thresholds.length - 1 && i === threshold.upperThreshold - previousUpperThreshold - 1;

                    if (isFirst) {
                      isFirst = false;
                      values.push(
                        <div style={{ backgroundColor: threshold.colorCoding.colorValue, float: "left", width: 100 / highestThreshold + "%" }} className="BarItem First">
                          <div className="DividerRight"></div>
                        </div>
                      );
                    } else if (isLast) {
                      values.push(
                        <div style={{ backgroundColor: threshold.colorCoding.colorValue, float: "left", width: 100 / highestThreshold + "%" }} className="BarItem Last">
                          <div className="DividerLeft"></div>
                        </div>
                      );
                    } else {
                      values.push(
                        <div style={{ backgroundColor: threshold.colorCoding.colorValue, float: "left", width: 100 / highestThreshold + "%" }} className="BarItem">
                          <div className="DividerLeft"></div>
                          <div className="DividerRight"></div>
                        </div>
                      );
                    }
                  }

                  isLast = this.props.Thresholds.indexOf(threshold) === this.props.Thresholds.length - 1;

                  let nextColor = "";
                  if (this.props.Thresholds.indexOf(threshold) < this.props.Thresholds.length - 1) {
                    nextColor = this.props.Thresholds[this.props.Thresholds.indexOf(threshold) + 1].colorCoding.colorValue;
                  }
                  if (isFirst) {
                    isFirst = false;
                    values.push(
                      <div className="BarItem First" style={{ float: "left", width: 100 / highestThreshold + "%" }}>
                        <div className="BarItemSplitted First" style={{ backgroundColor: threshold.colorCoding.colorValue, width: 100 * (+threshold.upperThreshold - Math.floor(threshold.upperThreshold)) + "%" }}></div>
                        <div className="BarItemSplitted" style={{ backgroundColor: nextColor, width: 100 * (Math.ceil(threshold.upperThreshold) - threshold.upperThreshold) + "%" }}></div>
                        <div className="DividerRight"></div>
                      </div>
                    );
                  } else if (isLast) {
                    values.push(
                      <div className="BarItem Last" style={{ float: "left", width: 100 / highestThreshold + "%", backgroundColor: threshold.colorCoding.colorValue }}>
                        <div className="DividerLeft"></div>
                      </div>
                    );
                  } else {
                    values.push(
                      <div className="BarItem" style={{ float: "left", width: 100 / highestThreshold + "%" }}>
                        <div className="DividerLeft"></div>
                        <div className="BarItemSplitted" style={{ backgroundColor: threshold.colorCoding.colorValue, width: 100 * (+threshold.upperThreshold - Math.floor(threshold.upperThreshold)) + "%" }}></div>
                        <div className="BarItemSplitted" style={{ backgroundColor: nextColor, width: 100 * (Math.ceil(threshold.upperThreshold) - threshold.upperThreshold) + "%" }}></div>
                        <div className="DividerRight"></div>
                      </div>
                    );
                  }

                  previousThreshold = threshold.upperThreshold;
                  return values;
                }
              })}
            </div>
          </div>
          <style jsx>
            {`
              .BarItemSplitted {
                height: 8px;
                position: relative;
              }
              .LabelRiskName {
                position: absolute;
                top: 65%;
                font-size: 14px;
                left: 5px;
                float: left;
                left: 0px;
              }
              .LabelZero {
                position: absolute;
                top: 20%;
                font-size: 11px;
                float: left;
                left: 0px;
              }
              .LabelFive {
                position: absolute;
                top: 20%;
                font-size: 11px;
                left: 50%;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
              }
              .LabelTen {
                position: absolute;
                top: 20%;
                font-size: 11px;
                float: right;
                right: 0px;
              }
              .DividerRight {
                position: relative;
                height: 100%;
                width: 1px;
                background-color: white;
                margin-left: auto;
              }
              .DividerLeft {
                position: relative;
                height: 100%;
                width: 1px;
                background-color: white;
                float: left;
              }
              .CircleFiller {
                width: 12px;
                height: 12px;
                transform: translate(-50%, -50%);
                top: 40%;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                position: absolute;
              }
              .BarContainer {
                width: 100%;
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
                -ms-transform: translateY(-50%);
                display: flex;
                justify-content: space-between;
              }
              .MarkerLabel {
                z-index: 99;
                position: absolute;
                font-size: 11px;
                font-weight: normal;
                color: white;
                transform: translate(-50%, -50%);
                top: 40%;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                user-select: none;
              }
              .MarkerContainer {
                width: 35px;
                height: 35px;
                position: absolute;
                transform: translateX(-50%);
                left: 50%;
                -ms-transform: translateX(-50%);
                z-index: 70;
              }
              .Marker {
                width: 100%;
                height: 100%;
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
              }
              .First {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
              }

              .Last {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              }

              .BarItem {
                float: left;
                width: 10%;
                height: 8px;
                z-index: 0;
                display: flex;
              }
              .Bar {
                width: 90%;
                position: absolute;
                height: 10px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                border-radius: 5px;
              }
              .NodeContainer {
                width: 100%;
                height: 100%;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
              }
            `}
          </style>
        </>
      );
    } else {
      let previousThreshold = 0;
      let isFirst = true;
      let isLast = false;
      return (
        <>
          <div
            className="NodeContainer"
            ref={(node) => {
              this.Node = node;
            }}
          >
            <label className="LabelZero">0</label>
            <label className="LabelFive">5</label>
            <label className="LabelTen">10</label>
            <label className="LabelRiskName">{this.props.RiskName}</label>
            <div className="BarContainer">
              {this.state.risk.colorCodings.map((threshold) => {
                let values = [];
                for (let i = 0; i < threshold.upperThreshold - previousThreshold; i++) {
                  isLast = this.state.risk.colorCodings.indexOf(threshold) === this.state.risk.colorCodings.length - 1 && i === threshold.upperThreshold - previousThreshold - 1;

                  if (isFirst) {
                    isFirst = false;
                    values.push(
                      <div style={{ backgroundColor: threshold.colorValue, float: "left" }} className="BarItem First">
                        <div className="DividerRight"></div>
                      </div>
                    );
                  } else if (isLast) {
                    values.push(
                      <div style={{ backgroundColor: threshold.colorValue, float: "left" }} className="BarItem Last">
                        <div className="DividerLeft"></div>
                      </div>
                    );
                  } else {
                    values.push(
                      <div style={{ backgroundColor: threshold.colorValue, float: "left" }} className="BarItem">
                        <div className="DividerRight"></div>
                        <div className="DividerLeft"></div>
                      </div>
                    );
                  }
                }
                previousThreshold = threshold.upperThreshold;
                return values;
              })}
            </div>
          </div>
          <style jsx>
            {`
              .LabelRiskName {
                position: absolute;
                top: 65%;
                font-size: 11px;
                left: 5px;
              }
              .LabelZero {
                position: absolute;
                top: 20%;
                font-size: 11px;
                left: 5px;
              }
              .LabelFive {
                position: absolute;
                top: 20%;
                font-size: 11px;
                left: 50%;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
              }
              .LabelTen {
                position: absolute;
                top: 20%;
                font-size: 11px;
                right: 5px;
              }
              .DividerRight {
                position: relative;
                height: 100%;
                width: 1px;
                background-color: white;
                float: right;
              }
              .DividerLeft {
                position: relative;
                height: 100%;
                width: 1px;
                background-color: white;
                float: left;
              }
              .CircleFiller {
                width: 12px;
                height: 12px;
                transform: translate(-50%, -50%);
                top: 40%;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                position: absolute;
              }
              .BarContainer {
                width: 100%;
                position: absolute;
                transform: translateY(-50%);
                top: 50%;
                -ms-transform: translateY(-50%);
                display: flex;
                justify-content: space-between;
              }
              .MarkerLabel {
                z-index: 99;
                position: absolute;
                font-size: 9px;
                font-weight: 600;
                color: white;
                transform: translate(-50%, -50%);
                top: 40%;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                user-select: none;
              }
              .MarkerContainer {
                width: 35px;
                height: 35px;
                position: absolute;
                transform: translateX(-50%);
                left: 50%;
                -ms-transform: translateX(-50%);
                z-index: 70;
              }
              .Marker {
                width: 100%;
                height: 100%;
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
              }
              .First {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
              }

              .Last {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              }

              .BarItem {
                float: left;
                width: 10%;
                height: 8px;
                z-index: 0;
              }
              .Bar {
                width: 90%;
                position: absolute;
                height: 10px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                border-radius: 5px;
              }
              .NodeContainer {
                width: 100%;
                height: 100%;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
              }
            `}
          </style>
        </>
      );
    }
  };
}
export default SlideBar;
