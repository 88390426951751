import { Button, ButtonVariant } from "@supplyon/ui-components";
import React, { Component } from "react";
import { Checkbox, Paper, TableCell } from "@material-ui/core/";
import Color from "../../libraries/SupplyOnUiConstants";
import NotificationBackground from "../NotificationBackground";
import { Add, Edit, Delete, Save, Cancel } from "@material-ui/icons/";
import VirtualizedTable from "../VirtualizedTable";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  paper: {
    width: "100%",
    height: "calc(100% - 64px)",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  tableRow: {
    cursor: "pointer",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: Color.Grey2,
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: "initial",
  },
  infoIcon: {
    marginLeft: "5px",
    marginRight: "5px",
    marginBottom: "-4px",
    color: `${Color.Grey5}`,
    float: "left",
    "&:hover": {
      color: Color.Blue1,
    },
  },
  noIconSpan: {
    display: "inline-block",
    width: "10px",
  },
  buttonBar: {
    marginLeft: "24px",
    width: "100%",
    height: "64px",
  },
  downloadButton: {
    width: "112px",
    height: "32px",
    marginRight: "16px",
    marginTop: "16px",
  },
  downloadIcon: {
    marginRight: "10px",
    width: "18px",
    height: "20px",
  },
  deleteButton: {
    width: "92",
    height: "32px",
    marginRight: "16px",
    marginTop: "16px",
  },
  deleteIcon: {
    marginRight: "10px",
    width: "14px",
    height: "14px",
  },
  refreshButton: {
    height: "32px",
    marginRight: "16px",
    marginTop: "16px",
  },
  selectedItemsLabel: {
    width: "70px",
    position: "relative",
    marginRight: "50px",
    top: "50%",
    float: "right",
    transform: "translateY(-50%)",
    msTransform: "translateY(-50%)",
  },
});

class QuestionnariesManageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsRaised: false,
      Questionnaires: [],
      DeletedQuestionnaires: [],
      selectedRowIds: [],
    };
  }
  static defaultProps = {
    headerHeight: 33,
    rowHeight: 33,
  };

  Raise = (questionnaires) => {
    let sortedQuestionnaires = [].concat(questionnaires).sort((a, b) => (a.title > b.title ? 1 : -1));
    this.setState({ IsRaised: true, Questionnaires: sortedQuestionnaires }, () => {
      if (this.NotificationBackground) {
        this.NotificationBackground.Raise();
      }
    });
  };

  columns = [
    {
      name: "checkbox",
      dataKey: "dataKey is a required prop but not used here",
      headerRenderer: () => this.HeaderRendererCheckbox(),
      cellRenderer: (rowData) => this.CellRendererCheckbox(rowData),
      cellProps: { style: { paddingRight: 9 } },
      width: 100,
      disableSort: true,
    },
    {
      width: 350,
      label: "Name",
      dataKey: "title",
      disableSort: false,
    },
    {
      width: 350,
      label: "Questionnaire ID",
      dataKey: "externalQuestionnaireId",
      disableSort: false,
    },
  ];

  HeaderRendererCheckbox = () => {
    const { headerHeight, classes } = this.props;

    return (
      <TableCell component="div" className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)} variant="head" style={{ height: headerHeight }} align={"left"}>
        <Checkbox
          indeterminate={this.state.selectedRowIds.length > 0 && this.state.selectedRowIds.length < this.state.Questionnaires?.length}
          checked={this.state.selectedRowIds.length === this.state.Questionnaires.length}
          onChange={(event) => this.OnChangeHeaderCheckbox(event)}
          size="small"
        />
      </TableCell>
    );
  };

  CellRendererCheckbox = ({ rowData }) => {
    const { classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={"left"}
      >
        <Checkbox checked={this.state.selectedRowIds.some((id) => rowData.questionnaireId === id)} size="small" />
      </TableCell>
    );
  };

  RowGetter = ({ index }) => {
    return this.state.Questionnaires[index];
  };

  Collapse = () => {
    this.setState({ Questionnaires: [], DeletedQuestionnaires: [],  selectedRowIds: [], IsRaised: false });
  };

  OnConfirm = () => {
    this.props.OnSave(this.state.DeletedQuestionnaires);
    this.Collapse();
  };

  OnDeleteClick = () => {    
    let questionnaires = this.state.Questionnaires;
    let deletedQuestionnaires = [];
    let newQuestionnaires = [];
    let selectedIds = this.state.selectedRowIds;
    questionnaires.forEach((questionnaire) => {
      if (selectedIds.find(x => x === questionnaire.questionnaireId)) {
        deletedQuestionnaires.push(questionnaire);
        selectedIds = selectedIds.filter(x=> x !== questionnaire.questionnaireId);
      } else {
        newQuestionnaires.push(questionnaire);
      }
    });
    this.setState({ Questionnaires: newQuestionnaires, DeletedQuestionnaires: deletedQuestionnaires, selectedRowIds: selectedIds });
  };

  OnCancel = () => {
    this.Collapse();
  };

  OnChangeHeaderCheckbox = (event) => {
    const newSelectedRowIds = event.target.checked ? this.state.Questionnaires.map((item) => item.questionnaireId) : [];
    this.setState({ selectedRowIds: newSelectedRowIds });
  };

  OnRowItemChecked = ({rowData}) => {
    const clickedRowId = rowData.questionnaireId;
    const newSelectedRowIds = this.state.selectedRowIds.includes(clickedRowId) ? this.state.selectedRowIds.filter((id) => id !== clickedRowId) : [...this.state.selectedRowIds, clickedRowId];
    this.setState({ selectedRowIds: newSelectedRowIds });
  };

  OnSelectAllClick = (event) => {
    const newSelectedRowIds = event.target.checked ? this.state.Questionnaires.map((item) => item.questionnaireId) : [];
    this.setState({ selectedRowIds: newSelectedRowIds  });
  };

  render = () => {
    return (
      this.state.IsRaised && (
        <>
          <NotificationBackground
            ref={(notificationBackground) => {
              this.NotificationBackground = notificationBackground;
            }}
          ></NotificationBackground>
          <div className="QuestionnariesManageForm_Node">
            <div className="QuestionnariesManageForm_Header">
              <label className="QuestionnariesManageForm_HeaderLabel">Manage Questionnaires</label>
            </div>
            <div className="QuestionnariesManageForm_Body">
              <div className="QuestionnariesManageForm_TableButtons">
                <Button variant={ButtonVariant.Page} className="QuestionnariesManageForm_AddButton" onClick={this.props.OnCreate}>
                  <Add className="QuestionnariesManageForm_AddIcon" />
                  Add Questionnaire
                </Button>
                <Button variant={ButtonVariant.Page} className="QuestionnariesManageForm_EditButton" onClick={() => this.props.OnEdit(this.state.Questionnaires.find((x) => x.questionnaireId === this.state.selectedRowIds[0]))} disabled={this.state.selectedRowIds?.length !== 1}>
                  <Edit className="QuestionnariesManageForm_EditIcon" />
                  Edit Questionnaire
                </Button>
                <Button variant={ButtonVariant.Page} className="QuestionnariesManageForm_DeleteButton" onClick={this.OnDeleteClick} disabled={this.state.selectedRowIds.length === 0}>
                  <Delete className="QuestionnariesManageForm_DeleteIcon" />
                  Delete Questionnaires
                </Button>
              </div>
              <div className="QuestionnariesManageForm_TableContainer">
                <Paper className="paper">
                  <VirtualizedTable rowCount={this.state.Questionnaires.length} rowGetter={this.RowGetter} columns={this.columns} selectedRowIds={this.state.selectedRowIds} onRowClick={this.OnRowItemChecked} SortList={this.props.SortList} sortBy={this.props.sortBy} sortDirection={this.props.sortDirection} />                  
                </Paper>
              </div>
            </div>
            <div className="QuestionnariesManageForm_Footer">
              <div className="QuestionnariesManageForm_FooterButtonBar">
                <Button onClick={this.OnCancel} className="QuestionnariesManageForm_FooterCancelButton" variant={ButtonVariant.Page}>
                  <Cancel className="QuestionnariesManageForm_CancelIcon" />
                  Cancel
                </Button>
                <Button onClick={this.OnConfirm} className="QuestionnariesManageForm_FooterSaveButton" variant={ButtonVariant.PageHighlight}>
                  <Save className="QuestionnariesManageForm_SaveIcon" />
                  Confirm
                </Button>
              </div>
            </div>
          </div>
          <style jsx>
            {`
              .QuestionnariesManageForm_FooterCancelButton {
                margin-left: 25px;
              }
              .QuestionnariesManageForm_FooterSaveButton {
                float: right;
                margin-right: 25px;
              }
              .QuestionnariesManageForm_FooterButtonBar {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .QuestionnariesManageForm_AddIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .QuestionnariesManageForm_AddButton {
                position: relative;
                margin-left: 24px;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .QuestionnariesManageForm_EditIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .QuestionnariesManageForm_EditButton {
                position: relative;
                margin-left: 24px;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .QuestionnariesManageForm_DeleteIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .QuestionnariesManageForm_DeleteButton {
                position: relative;
                margin-left: 24px;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .QuestionnariesManageForm_CancelIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .QuestionnariesManageForm_SaveIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .QuestionnariesManageForm_TableButtons {
                width: 100%;
                height: 50px;
                border-bottom: 1px solid ${Color.Grey3};
              }
              .QuestionnariesManageForm_Body {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 70px;
                line-height: 70px;
              }
              .QuestionnariesManageForm_TableContainer {
                width: 100%;
                height: calc(100% - 184px);
              }
              .QuestionnariesManageForm_Node {
                width: 800px;
                height: 700px;
                background-color: white;
                position: absolute;
                border: 1px solid grey;
                border-radius: 5px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50);
                z-index: 99;
              }

              .QuestionnariesManageForm_HeaderLabel {
                font-size: 18px;
                font-weight: bold;
                margin-left: 25px;
              }

              .QuestionnariesManageForm_Header {
                border-bottom: 1px solid ${Color.Grey3};
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 68px;
                line-height: 70px;
              }

              .QuestionnariesManageForm_Footer {
                border-top: 1px solid ${Color.Grey3};
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 64px;
              }
              .paper {
                width: 100%;
                height: calc(100%);
              }
            `}
          </style>
        </>
      )
    );
  };
}
export default withStyles(styles)(QuestionnariesManageForm);
