import { Button, ButtonVariant } from "@supplyon/ui-components";
import React, { Component } from "react";
import { MuiThemeProvider, Paper, Table, TableBody, TableCell, TableHead, TableRow, createTheme, Checkbox } from "@material-ui/core/";
import Color from "../../libraries/SupplyOnUiConstants";
import NotificationBackground from "../NotificationBackground";
import { Save, Cancel } from "@material-ui/icons/";
import SurveyService from "../../services/SurveyService";
import dayjs from "dayjs";

const offset = new Date(Date.now()).getTimezoneOffset();
const theme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        width: "100%",
        overflow: "auto",
        boxShadow: "none !important",
      },
    },
    MuiTableCell: {
      root: {
        padding: "0px",
        fontSize: "12px",
      },
      head: {
        color: Color.Grey5,
        backgroundColor: Color.Grey1,
        borderTop: "none",
      },
    },
    MuiSvgIcon: {
      root: {
        height: "16px",
        width: "16px",
      },
    },
    PrivateSwitchBase: {
      root: {
        padding: "7.5px 7.5px 7.5px 7.5px",
        marginLeft: "16.5px",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: Color.Blue1,
        },
      },
    },
    MuiIconButton: {
      colorSecondary: {
        "&:hover": {
          color: Color.Blue1,
          backgroundColor: "rgba(50, 50, 50, 0.10)",
        },
      },
    },
  },
});

class SurveyCreateConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OnCancel: () => {},
      OnConfirm: () => {},
      IsRaised: false,
      Survey: null,
    };
  }

  Raise = (survey) => {
    this.setState(
      {
        IsRaised: true,
        Survey: survey,
      },
      () => {
        if (this.NotificationBackground) {
          this.NotificationBackground.Raise();
        }
      }
    );
  };

  Collapse = () => {
    this.NotificationBackground.Collapse();
    this.setState({ OnCancel: () => {}, OnConfirm: () => {}, Survey: null, SurveyContacts: [], SupplierManufacturingLocations: [], Questionnaires: [], AllSurveyContactsSelected: true, IsRaised: false, IsImmediateStart: true });
  };

  OnStartSurvey = () => {
    let survey = {...this.state.Survey};

    if (survey.startDate) {
      survey.startDate.$d.setUTCHours(0);
      survey.startDate.$d.setMinutes(0);
      survey.startDate.$d.setSeconds(0);
      survey.startDate = dayjs(survey.startDate).add(offset, "minutes");
    }

    survey.endDate.$d.setUTCHours(23);
    survey.endDate.$d.setMinutes(59);
    survey.endDate.$d.setSeconds(59);
    survey.endDate = dayjs(survey.endDate).add(offset, "minutes");

    SurveyService.SaveSurvey(survey).then((response) => {
      this.props.OnSave(response.surveyId != null);
      this.Collapse();
    });
  };

  OnCancel = () => {
    this.props.OnCancel();
    this.Collapse();
  };

  render = () => {
    return (
      this.state.IsRaised && (
        <>
          <NotificationBackground
            ref={(notificationBackground) => {
              this.NotificationBackground = notificationBackground;
            }}
          ></NotificationBackground>
          <div className="SurveyCreateConfirmation_Node">
            <div className="SurveyCreateConfirmation_Header">
              <label className="SurveyCreateConfirmation_HeaderLabel">{this.state.Survey.ReadyForStart ? "Start" : "Save Draft"} Survey Confirmation</label>
            </div>
            <div className="SurveyCreateConfirmation_Body">
              <div className="SurveyCreateConfirmation_InfoText">Confirm survey details before {this.state.Survey.ReadyForStart ? "starting the survey" : "saving draft"}</div>
              <div className="SurveyCreateConfirmation_SurveyDetails">
                <div className="SurveyCreateConfirmation_SurveyDetail">
                  <div className="SurveyCreateConfirmation_SurveyDetailKey"> Status:</div>
                  <div className="SurveyCreateConfirmation_SurveyDetailValue">{this.state.Survey.ReadyForStart ? (<div className="SurveyCreateConfirmation_FinalStatusValue">Final survey</div>) : (<div className="SurveyCreateConfirmation_DraftStatusValue">Draft</div>)}</div>
                </div>
                <div className="SurveyCreateConfirmation_SurveyDetail">
                  <div className="SurveyCreateConfirmation_SurveyDetailKey"> Title:</div>
                  <div className="SurveyCreateConfirmation_SurveyDetailValue">{this.state.Survey.title}</div>
                </div>
                <div className="SurveyCreateConfirmation_SurveyDetail">
                  <div className="SurveyCreateConfirmation_SurveyDetailKey">Questionnaire:</div>
                  <div className="SurveyCreateConfirmation_SurveyDetailValue">{this.state.Survey.questionnaireTitle}</div>
                </div>
                <div className="SurveyCreateConfirmation_SurveyDetail">
                  <div className="SurveyCreateConfirmation_SurveyDetailKey">Start date: (YYYY-MM-DD)</div>
                  <div className="SurveyCreateConfirmation_SurveyDetailValue">{this.state.Survey.startDate ? this.state.Survey.startDate?.$d.toISOString().split("T")[0] : "immediately"}</div>
                </div>
                <div className="SurveyCreateConfirmation_SurveyDetail">
                  <div className="SurveyCreateConfirmation_SurveyDetailKey">End date: (YYYY-MM-DD)</div>
                  <div className="SurveyCreateConfirmation_SurveyDetailValue">{this.state.Survey.endDate?.$d.toISOString().split("T")[0]}</div>
                </div>
                <div className="SurveyCreateConfirmation_SurveyDetail">
                  <div className="SurveyCreateConfirmation_SurveyDetailKey">Number of selected rows:</div>
                  <div className="SurveyCreateConfirmation_SurveyDetailValue">{this.state.Survey.surveyContacts.length}</div>
                </div>
                <div className="SurveyCreateConfirmation_SurveyDetail">
                  <div className="SurveyCreateConfirmation_SurveyDetailKey">Visibility:</div>
                  <div className="SurveyCreateConfirmation_SurveyDetailValue">{this.state.Survey.hasCompanyAccess ? "Company" : "Creator"}</div>
                </div>
              </div>
              <div className="SurveyCreateConfirmation_TableContainer">
                <MuiThemeProvider theme={theme}>
                  <Paper className="paper">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox" className="normalSizeCell">
                            Location name
                          </TableCell>
                          <TableCell className="normalSizeCell">Supplier</TableCell>
                          <TableCell className="normalSizeCell">Supplier id</TableCell>
                          <TableCell className="smallSizeCell">Notify all</TableCell>
                          <TableCell className="normalSizeCell">Contact Email</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.Survey?.surveyContacts.map((contact) => (
                          <TableRow hover key={contact.supplierManufacturingLocationId}>
                            <TableCell padding="checkbox" className="normalSizeCell">
                              {contact.location}
                            </TableCell>
                            <TableCell className="normalSizeCell">{contact.supplierName}</TableCell>
                            <TableCell className="normalSizeCell">{contact.cdm}</TableCell>
                            <TableCell className="smallSizeCell">
                              <Checkbox disabled={true} checked={contact.hasNotifyAll} onClick={() => this.OnNotifyAllChecked(contact.supplierManufacturingLocationId)} inputProps={{ "aria-labelledby": contact.supplierManufacturingLocationId }} />
                            </TableCell>
                            <TableCell className="normalSizeCell">{contact.hasNotifyAll ? "" : contact.email} </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </MuiThemeProvider>
              </div>
            </div>
            <div className="SurveyCreateConfirmation_Footer">
              <div className="SurveyCreateConfirmation_FooterButtonBar">
                <Button onClick={this.OnCancel} className="SurveyCreateConfirmation_FooterCancelButton" variant={ButtonVariant.Page}>
                  <Cancel className="SurveyCreateConfirmation_CancelIcon" />
                  Cancel
                </Button>
                <Button onClick={this.OnStartSurvey} className="SurveyCreateConfirmation_FooterSaveButton" variant={ButtonVariant.PageHighlight}>
                  <Save className="SurveyCreateConfirmation_SaveIcon" />
                  {this.state.Survey.ReadyForStart ? "Start Survey" : "Save draft"}
                </Button>
              </div>
            </div>
          </div>
          <style jsx>
            {`
              .SurveyCreateConfirmation_FooterCancelButton {
                margin-left: 25px;
              }
              .SurveyCreateConfirmation_FooterSaveButton {
                float: right;
                margin-right: 25px;
              }
              .SurveyCreateConfirmation_FooterButtonBar {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .SurveyCreateConfirmation_AddIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .SurveyCreateConfirmation_AddButton {
                position: relative;
                margin-left: 24px;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .SurveyCreateConfirmation_EditIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .SurveyCreateConfirmation_EditButton {
                position: relative;
                margin-left: 24px;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .SurveyCreateConfirmation_DeleteIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .SurveyCreateConfirmation_CancelIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .SurveyCreateConfirmation_SaveIcon {
                margin-right: 10px;
                width: 14px;
                height: 14px;
              }
              .SurveyCreateConfirmation_Body {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 70px;
                font-size: 15px;
                font-weight: normal;
              }
              .SurveyCreateConfirmation_InfoText {
                margin-left: 25px;
                margin-top: 25px;
              }
              .SurveyCreateConfirmation_SurveyDetails {
                margin-left: 25px;
                margin-top: 25px;
              }
              .SurveyCreateConfirmation_SurveyDetail {
                display: inline-block;
                width: 100%;
                margin-top: 10px;
              }
              .SurveyCreateConfirmation_SurveyDetailKey {
                display: inline-block;
                float: left;
                width: calc(40% - 24px);
                height: 100%;
                margin-top: 10px;
              }
              .SurveyCreateConfirmation_SurveyDetailValue {
                display: inline-block;
                float: left;
                width: calc(60% - 24px);
                height: 100%;
                margin-top: 10px;
              }              
              .SurveyCreateConfirmation_FinalStatusValue {
                font-weight: bold;
                color: green;
              }              
              .SurveyCreateConfirmation_DraftStatusValue {
                font-weight: bold;
                color: #FFCC00;
              }
              .SurveyCreateConfirmation_BodyLabel {
                font-size: 15px;
                font-weight: normal;
                margin-left: 25px;
              }
              .SurveyCreateConfirmation_TableContainer {
                margin-top: 25px;
                width: 100%;
                height: calc(100% - 438px);
                overflow-y: scroll;
              }
              .SurveyCreateConfirmation_Node {
                width: 60%;
                height: 864px;
                background-color: white;
                position: absolute;
                border: 1px solid grey;
                border-radius: 5px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50);
                z-index: 99;
                box-shadow: 0px 0px 2px ${Color.Grey4};
              }
              .SurveyCreateConfirmation_HeaderLabel {
                font-size: 18px;
                font-weight: bold;
                margin-left: 25px;
              }
              .SurveyCreateConfirmation_Header {
                border-bottom: 1px solid ${Color.Grey3};
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 68px;
                line-height: 70px;
              }
              .SurveyCreateConfirmation_Footer {
                border-top: 1px solid ${Color.Grey3};
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 64px;
              }
              .paper {
                width: "100%";
                height: "calc(100% - 64px)";
              }
              .normalSizeCell {
                width: 250px;
                height: 24px;
                padding-left: 24px;
              }
              .smallSizeCell {
                width: 100px;
                height: 24px;
                padding-left: 24px;
              }
            `}
          </style>
        </>
      )
    );
  };
}
export default SurveyCreateConfirmation;
