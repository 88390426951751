import { Button, ButtonVariant } from "@supplyon/ui-components";
import { Checkbox, FormControlLabel, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Info, LocationOn } from "@material-ui/icons";
import React, { Component } from "react";
import Color from "../libraries/SupplyOnUiConstants";
import { DownloadSmlsAsExcel } from "../libraries/DownloadHelper";
import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";
import SupplierManufacturingLocationDataEdit from "./SupplierManufacturingLocationDataEdit";
import SupplierManufacturingLocationRelations from "./SupplierManufacturingLocationRelations";
import SupplierManufacturingLocationRisks from "./SupplierManufacturingLocationRisks";

/*  Props: 
        events:
 
            OnSave:   raises the save functionality of the parent
            OnChange: raised when a parameter are changed and do the changes in the injected object in the parent
            OnDelete: raised the delete functionality of the parent
            OnCancel: raised when the process are canceled
*/

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: Color.Grey5,
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: Color.Grey5,
  },
}))(Tooltip);

class SupplierManufacturingLocationMainForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsRaised: false,
      Item: {},
      RisksDisplayed: false,
      DataDisplayed: true,
      RelationsDisplayed: false,
      CreatedByUser: false,
      IsNew: false,
      HasChanges: false,
      RetrieveRiskData: false,
    };
  }

  RaiseRisks = () => {
    this.setState({ RisksDisplayed: true, DataDisplayed: false, RelationsDisplayed: false });
  };

  RaiseData = () => {
    this.setState({ RisksDisplayed: false, DataDisplayed: true, RelationsDisplayed: false });
  };

  RaiseRelations = () => {
    this.setState({ RisksDisplayed: false, DataDisplayed: false, RelationsDisplayed: true });
  };

  GetAddressByCoordinates = async (supplierManufacturingLocation) => {
    const result = await fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + supplierManufacturingLocation.latitude + "," + supplierManufacturingLocation.longitude + "&key=" + RiskManagementKeycloak.API_Key, {
      method: "GET",
    });
    const jsonResult = await result.json();
    if (jsonResult && jsonResult.results !== undefined && jsonResult.results.length > 0) {
      let street = "";
      let houseNumber = "";
      for (let i = 0; i < jsonResult.results[0].address_components.length; i++) {
        let addressComponent = jsonResult.results[0].address_components[i];
        if (addressComponent.types[0] === "street_number") {
          houseNumber = addressComponent.long_name;
        } else if (addressComponent.types[0] === "route") {
          street = addressComponent.long_name;
        } else if (addressComponent.types[0] === "locality") {
          supplierManufacturingLocation.city = addressComponent.long_name;
        } else if (addressComponent.types[0] === "administrative_area_level_1") {
          supplierManufacturingLocation.regionName = addressComponent.long_name;
          supplierManufacturingLocation.regionId = addressComponent.short_name;
        } else if (addressComponent.types[0] === "country") {
          supplierManufacturingLocation.country = addressComponent.short_name;
        } else if (addressComponent.types[0] === "postal_code") {
          supplierManufacturingLocation.zip = addressComponent.long_name;
        }
      }

      supplierManufacturingLocation.street = street + " " + houseNumber;
    }
    return supplierManufacturingLocation;
  };

  GetCoordinatesByAddress = async (supplierManufacturingLocation) => {
    let formattedAddress = supplierManufacturingLocation.street + ", " + supplierManufacturingLocation.zip + ", " + supplierManufacturingLocation.city + ", " + supplierManufacturingLocation.regionName + ", " + supplierManufacturingLocation.country;

    const result = await fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + formattedAddress + "&key=" + RiskManagementKeycloak.API_Key, {
      method: "GET",
    });
    const jsonResult = await result.json();
    if (jsonResult && jsonResult.results !== undefined && jsonResult.results.length > 0) {
      if (jsonResult.results[0] !== null && jsonResult.results[0] !== undefined && jsonResult.results[0].geometry !== null && jsonResult.results[0].geometry !== undefined && jsonResult.results[0].geometry.location !== null && jsonResult.results[0].geometry.location !== undefined) {
        supplierManufacturingLocation.latitude = jsonResult.results[0].geometry.location.lat;
        supplierManufacturingLocation.longitude = jsonResult.results[0].geometry.location.lng;
      }
    }
    return supplierManufacturingLocation;
  };

  GetHasChanges = () => {
    return this.state.HasChanges;
  };

  OnBlurSml = (sml, coordinatesChanged, addressChanged) => {
    if (coordinatesChanged) {
      this.GetAddressByCoordinates(sml).then((sml) => {
        this.props.OnChange(sml);
        this.setState({ HasChanges: true, Item: sml });
      });
    } else if (addressChanged) {
      this.GetCoordinatesByAddress(sml).then((sml) => {
        this.props.OnChange(sml);
        this.setState({ HasChanges: true, Item: sml });
      });
    } else {
      this.props.OnChange(sml);
      this.setState({ HasChanges: true, Item: sml });
    }
  };

  OnChangeSml = (sml) => {
    this.setState({ HasChanges: true, Item: sml });
  };

  Raise = async (supplierManufacturingLocation, isNew) => {
    if (RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_CategoryManager || RiskManagementKeycloak.Has_Role_Visitor) {
      if (isNew) {
        this.setState({ CreatedByUser: true, IsNew: true, HasChanges: true });
        this.GetAddressByCoordinates(supplierManufacturingLocation).then((sml) => {
          this.setState({ IsRaised: true, Item: sml });
        });
      } else {
        this.setState({ CreatedByUser: supplierManufacturingLocation.belongsToUserId !== null || supplierManufacturingLocation.belongsToSmaId !== null });
        for (let i = 0; i < supplierManufacturingLocation.supplierManufacturingLocationRisks.length; i++) {
          let u = supplierManufacturingLocation.supplierManufacturingLocationRisks[i];
          if (u.riskCategory !== null && u.riskCategory !== undefined) {
            u.riskCategory.riskCategoryColorCodingThresholdModels.sort((a, b) => {
              return a.upperThreshold < b.upperThreshold ? -1 : 1;
            });
          }
        }
        this.setState({ IsRaised: true, Item: supplierManufacturingLocation });
      }
    }
  };

  Collapse = () => {
    this.setState({ HasChanges: false, IsRaised: false, Item: {}, RisksDisplayed: false, DataDisplayed: true, RelationsDisplayed: false, CreatedByUser: false, IsNew: false });
  };

  OnRefreshClick = () => {
    let smlIdsToUpdate = [this.state.Item.supplierManufacturingLocationId];
    this.props.OnRefresh(smlIdsToUpdate);
  };

  OnChangeCheckbox = (event) => {
    this.setState({
      RetrieveRiskData: event.target.checked,
    });
  };

  render = () => {
    return (
      this.state.IsRaised && (
        <>
          <div className="SMLMainForm_Node">
            <div className="SMLMainForm_Header">
              <div className="SMLMainForm_HeaderButton" onClick={this.RaiseData} style={this.state.DataDisplayed ? { borderBottom: `3px solid ${Color.Blue1}` } : { borderBottom: "none" }}>
                <label className="SMLMainForm_HeaderButtonLabel" style={this.state.DataDisplayed ? { color: Color.Blue1 } : { color: Color.Grey5 }}>
                  Location
                </label>
              </div>
              {(RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_CategoryManager) && (
                <div className="SMLMainForm_HeaderButton" onClick={this.RaiseRisks} style={this.state.RisksDisplayed ? { borderBottom: `3px solid ${Color.Blue1}` } : { borderBottom: "none" }}>
                  <label className="SMLMainForm_HeaderButtonLabel" style={this.state.RisksDisplayed ? { color: Color.Blue1 } : { color: Color.Grey5 }}>
                    Risks
                  </label>
                </div>
              )}
              {!this.state.CreatedByUser && this.state.Item.supplyRelations?.length > 0 && (
                <div className="SMLMainForm_HeaderButton" onClick={this.RaiseRelations} style={this.state.RelationsDisplayed ? { borderBottom: `3px solid ${Color.Blue1}` } : { borderBottom: "none" }}>
                  <label className="SMLMainForm_HeaderButtonLabel" style={this.state.RelationsDisplayed ? { color: Color.Blue1 } : { color: Color.Grey5 }}>
                    Relations
                  </label>
                </div>
              )}
              <LocationOn
                className="SMLMainForm_MarkerLabel"
                style={{
                  color:
                    RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_CategoryManager
                      ? this.props.DisplayMitigatedRisk && this.state.Item.mitigatedColor != null
                        ? this.state.Item.mitigatedColor.colorValue
                        : this.props.DisplayAggregatedRisks && this.state.Item.calculatedHighColor.colorValue
                        ? this.state.Item.calculatedHighColor.colorValue
                        : Color.Grey5
                      : Color.Grey5,
                }}
              ></LocationOn>
            </div>
            <div className="SMLMainForm_Content">
              {this.state.RelationsDisplayed && !this.state.CreatedByUser && <SupplierManufacturingLocationRelations SupplierManufacturingLocation={this.state.Item}></SupplierManufacturingLocationRelations>}
              {this.state.RisksDisplayed && !this.state.CreatedByUser && <SupplierManufacturingLocationRisks SupplierManufacturingLocationRisks={this.state.Item.supplierManufacturingLocationRisks} />}
              {this.state.RisksDisplayed && this.state.CreatedByUser && !this.state.IsNew && <SupplierManufacturingLocationRisks SupplierManufacturingLocationRisks={this.state.Item.supplierManufacturingLocationRisks} />}
              {this.state.RisksDisplayed && this.state.CreatedByUser && this.state.IsNew && (
                <div className="SMLMainForm_InfoForm">
                  <Info className="SMLMainForm_InfoIcon"></Info>
                  <label className="SMLMailForm_InfoLabel">Please save location to display risk information.</label>
                </div>
              )}
              {this.state.DataDisplayed && <SupplierManufacturingLocationDataEdit OnChange={this.OnChangeSml} OnBlur={this.OnBlurSml} SupplierManufacturingLocation={this.state.Item} IsEditable={this.state.CreatedByUser} />}
            </div>
            <div className="SMLMainForm_Footer">
              <div className="SMLMainForm_FooterButtonBar">
                <Button
                  onClick={() => {
                    this.props.OnCancel(this.state.HasChanges);
                  }}
                  className="SMLMainForm_FooterButton"
                  variant={ButtonVariant.Page}
                >
                  Close
                </Button>
                {(RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner) && this.state.CreatedByUser && (
                  <Button onClick={this.props.OnDelete} className="SMLMainForm_FooterButton" variant={ButtonVariant.Page}>
                    Delete
                  </Button>
                )}
                {this.state.CreatedByUser && (
                  <Button onClick={() => this.props.OnSave(this.state.RetrieveRiskData)} className="SMLMainForm_FooterButton" variant={ButtonVariant.PageHighlight}>
                    Save
                  </Button>
                )}
                {(RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner || RiskManagementKeycloak.Has_Role_CategoryManager) && this.state.DataDisplayed && !this.state.IsNew && (
                  <Button onClick={() => DownloadSmlsAsExcel([this.state.Item])} variant={ButtonVariant.Page} className="SMLMainForm_FooterButton">
                    Download
                  </Button>
                )}
                {(RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner) && (this.state.CreatedByUser || RiskManagementKeycloak.Has_Role_RiskManager) && this.state.RisksDisplayed && !this.state.IsNew && (
                  <LightTooltip title={this.props.OngoingUpdates.length !== 0 ? "Update ongoing" : ""}>
                    <span>
                      <Button disabled={this.props.OngoingUpdates.length !== 0} onClick={() => this.OnRefreshClick()} className="SMLMainForm_FooterButton" variant={ButtonVariant.Page}>
                        Refresh Risk Data
                      </Button>
                    </span>
                  </LightTooltip>
                )}
                {(RiskManagementKeycloak.Has_Role_RiskManager || RiskManagementKeycloak.Has_Role_RiskClusterOwner) && (this.state.CreatedByUser || RiskManagementKeycloak.Has_Role_RiskManager) && this.state.IsNew && (
                  <FormControlLabel control={<Checkbox checked={this.state.RetrieveRiskData} onChange={this.OnChangeCheckbox} inputProps={{ "aria-label": "select" }} />} className="SMLMainForm_FooterCheckbox" label="Retrieve risk data from data provider"></FormControlLabel>
                )}
                {this.state.RisksDisplayed && this.state.Item?.risksLastUpdated && !this.state.IsNew && (
                  <Button disabled={true} className="SMLMainForm_FooterButton" variant={ButtonVariant.Contextual}>
                    Last updated:
                    <br />
                    {new Date(this.state.Item.risksLastUpdated).toLocaleString("en-US", { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" })}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <style jsx>
            {`
              .SMLMainForm_DownloadIcon {
                margin-right: 10px;
                width: 18px;
                height: 20px;
              }
              .SMLMainForm_FooterCheckbox {
                font-size: 12px;
                margin-left: 24px;
              }
              .SMLMainForm_MarkerLabel {
                position: absolute;
                right: 25px;
                top: 32px;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .SMLMainForm_InfoForm {
                top: 10px;
                height: 30px;
                position: relative;
                line-height: 30px;
              }
              .SMLMailForm_InfoLabel {
                position: relative;
                left: 30px;
              }
              .SMLMainForm_InfoIcon {
                color: ${Color.Blue1};
                position: relative;
                top: 52%;
                left: 20px;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .SMLMainForm_HeaderButton {
                float: left;
                width: 125px;
                height: 64px;
                line-height: 64px;
                text-align: center;
              }
              .SMLMainForm_HeaderButtonLabel {
                font-size: 16px;
                font-weight: bold;
                color: ${Color.Grey5};
              }
              .SMLMainForm_FooterButton {
                margin-left: 24px;
              }
              .SMLMainForm_FooterButtonBar {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .SMLMainForm_Header {
                height: 64px;
                width: 100%;
                border-bottom: 1px solid ${Color.Grey3};
                line-height: 64px;
              }
              .SMLMainForm_Slider {
                width: 520px;
                height: 70px;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                margin-top: 20px;
              }
              .SMLMainForm_Node {
                width: 600px;
                height: calc(100% - 132px);
                border-radius: 5px;
                background-color: white;
                position: absolute;
                top: 92px;
                left: 40px;
                z-index: 49;
                box-shadow: 0px 0px 2px ${Color.Grey4};
              }
              .SMLMainForm_Content {
                width: 100%;
                height: calc(100% - 130px);
              }
              .SMLMainForm_Footer {
                height: 64px;
                width: 100%;
                border-top: 1px solid ${Color.Grey3};
                line-height: 50px;
                bottom: 0;
                position: absolute;
              }
            `}
          </style>
        </>
      )
    );
  };
}
export default SupplierManufacturingLocationMainForm;
