import React, { Component } from "react";
import { Polygon as GooglePolygon } from "@react-google-maps/api";

/*  Props: 
        events:
 
            OnClick:  raised by onClick on the button
            OnChange: raised by do changes on the circle


        properties:

            Polgon: the polygon object based on the map
*/

class Polygon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MouseDown: false,
      MouseMove: false,
      MouseDrag: false,
      ClickedCoordinates: {},
    };
  }

  IndexOfCoordinate = (array, lat, lng) => {
    for (var i = 0; i < array.length; i++) {
      if (array[i].lat === lat && array[i].lng === lng) {
        return i;
      }
    }
    return -1;
  };

  OnMouseDown = (e) => {
    this.setState({ MouseDown: true });
    this.setState({
      ClickedCoordinates: {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    });
  };

  OnMouseMove = () => {
    if (this.state.MouseDown) {
      this.setState({ MouseMove: true });
    }
  };

  OnDragPolygon = () => {
    this.setState({ MouseDrag: true });
  };

  OnMouseUp = (e) => {
    let polygon = this.props.Polygon;

    if (e.edge !== undefined) {
      let currentCoordinates = polygon.coordinates;

      let newLat = e.latLng.lat() * 2 - currentCoordinates[e.edge].lat;
      let newlng = e.latLng.lng() * 2 - currentCoordinates[e.edge].lng;

      let newVertex = { lat: newLat, lng: newlng };

      if (this.IndexOfCoordinate(currentCoordinates, newVertex.lat, newVertex.lng) === -1 && this.state.MouseDown && this.state.MouseMove) {
        currentCoordinates.splice(e.edge + 1, 0, newVertex);

        polygon.coordinates = currentCoordinates;
        this.props.OnChange(polygon);
      }
    }

    if (e.vertex !== undefined) {
      let newVertex = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      let currentCoordinates = polygon.coordinates;

      if (this.IndexOfCoordinate(currentCoordinates, newVertex.lat, newVertex.lng) === -1 && this.state.MouseDown && this.state.MouseMove) {
        currentCoordinates[e.vertex] = newVertex;

        polygon.coordinates = currentCoordinates;
        this.props.OnChange(polygon);
      }
    }

    if (e.edge === undefined && e.vertex === undefined && this.state.MouseDown && this.state.MouseDrag) {
      let currentCoordinates = polygon.coordinates;
      let latDifference = e.latLng.lat() - this.state.ClickedCoordinates.lat;
      let lngDifference = e.latLng.lng() - this.state.ClickedCoordinates.lng;

      for (let i = 0; i < currentCoordinates.length; i++) {
        currentCoordinates[i].lat = currentCoordinates[i].lat + latDifference;
        currentCoordinates[i].lng = currentCoordinates[i].lng + lngDifference;
      }

      polygon.coordinates = currentCoordinates;
      this.props.OnChange(polygon);
    }

    this.setState({ MouseMove: false, MouseDown: false, MouseDrag: false });
  };

  render = () => {
    return (
      <>
        <GooglePolygon
          onClick={this.props.OnClick}
          paths={this.props.Polygon.coordinates}
          options={{
            fillColor: this.props.Polygon.colorCoding.colorValue,
            fillOpacity: this.props.LightColor ? 0.5 : 0.2,
            strokeColor: this.props.Polygon.colorCoding.colorValue,
            strokeOpacity: 1,
            strokeWeight: this.props.LightColor ? 2 : 1,
            clickable: true,
            draggable: this.props.Polygon.isFocused,
            editable: this.props.Polygon.isFocused,
            geodesic: true,
            zIndex: 1,
          }}
          onMouseDown={this.OnMouseDown}
          onMouseMove={this.OnMouseMove}
          onMouseUp={this.OnMouseUp}
          onDrag={this.OnDragPolygon}
        ></GooglePolygon>
      </>
    );
  };
}
export default Polygon;
