import React, { PureComponent } from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { TableCell, TableSortLabel } from "@material-ui/core/";
import { AutoSizer, Column, Table } from "react-virtualized";
import Color from "../libraries/SupplyOnUiConstants";

const styles = () => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  tableRow: {
    cursor: "pointer",
  },
  tableRowHover: {
    "&:hover": {
      backgroundColor: Color.Grey2,
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: "initial",
  },
});

/*  Props:
        events:

            RowGetter
            OnRowClick: 

        properties:

            rowCount: length of used list
            columns: columns to display
            selectedRowIds: list of selected ids 
            
*/

class VirtualizedTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowIds: [],
    };
  }
  static defaultProps = {
    headerHeight: 33,
    rowHeight: 33,
  };

  GetRowClassName = ({ index }) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    });
  };

  // Render cell data within material-ui TableCell, optionally set cursor to initial if click handler was not supplied
  CellRenderer = ({ cellData }) => {
    const { classes, rowHeight, onRowClick } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={"left"}
      >
        {cellData}
      </TableCell>
    );
  };

  HeaderRenderer = ({ label, dataKey, sortBy, sortDirection }) => {
    const { headerHeight, classes } = this.props;
    return (
      <TableCell component="div" className={clsx(classes.tableCell, classes.flexContainer)} variant="head" style={{ height: headerHeight }} align={"left"}>
        {label}
        {sortBy === dataKey && <TableSortLabel active={true} direction={sortDirection.toLowerCase()} />}
      </TableCell>
    );
  };

  Sort = ({ sortBy, sortDirection }) => {
    this.props.SortList(sortBy, sortDirection);
  };

  render() {
    const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            height={height}
            width={width}
            rowHeight={rowHeight}
            sort={this.Sort}
            sortBy={this.props.sortBy}
            sortDirection={this.props.sortDirection}
            gridStyle={{
              direction: "inherit",
            }}
            headerHeight={headerHeight}
            className={classes.table}
            {...tableProps}
            rowClassName={this.GetRowClassName}
          >
            {columns.map(({ dataKey, ...other }, index) => {
              return (
                <Column
                  key={dataKey}
                  headerRenderer={(headerProps) =>
                    this.HeaderRenderer({
                      ...headerProps,
                      columnIndex: index,
                    })
                  }
                  className={classes.flexContainer}
                  cellRenderer={this.CellRenderer}
                  dataKey={dataKey}
                  {...other}
                />
              );
            })}
          </Table>
        )}
      </AutoSizer>
    );
  }
}
export default withStyles(styles)(VirtualizedTable);
