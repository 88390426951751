import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";

class CISUserAccountService {
  static GetUserAccounts = () => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_CorporateIdentityService_URL + "/useraccount", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: RiskManagementKeycloak.Access_Token,
        },
      }).then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return null;
        }
      });
    });
  };

  static GetUserAccountByIdKey = (SmaID) => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_CorporateIdentityService_URL + "/useraccount/" + SmaID + "?fetchFromPortal=true", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: RiskManagementKeycloak.Access_Token,
        },
      }).then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return null;
        }
      });
    });
  };
}
export default CISUserAccountService;
