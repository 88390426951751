import { Button, ButtonVariant, TextArea, TextField } from "@supplyon/ui-components";
import React, { Component } from "react";
import { Check } from "@material-ui/icons";
import Color from "../libraries/SupplyOnUiConstants";
import ColorCodingService from "../services/ColorCodingService";
import { DownloadRiskLayerJson } from "../libraries/DownloadHelper";

/*  Props: 
        events:
 
            OnSave:   raises the save functionality of the parent
            OnChange: raised when a parameter are changed and do the changes in the injected object in the parent
            OnDelete: raised the delete functionality of the parent
            OnCancel: raised when the process are canceled
*/

class CustomRiskLayerFormEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsRaised: false,
      Item: {},
      ColorCodings: [],
      HasChanges: false,
    };
  }

  componentDidUpdate = () => {
    if (this.state.ColorCodings === undefined || this.state.ColorCodings.length === 0) {
      this.FetchColorCodings();
    }
  };

  FetchColorCodings = () => {
    ColorCodingService.GetAllColorCodings().then((result) => {
      this.setState({ ColorCodings: result });
    });
  };

  CurrentComponentChanged = () => {
    this.setState({ HasChanges: true });
  };

  GetHasChanges = () => {
    return this.state.HasChanges;
  };

  ColorCodingOnClick = (colorCodingKey) => {
    if (this.state.Item.colorCoding.colorCodingKey !== colorCodingKey) {
      const colorCoding = this.state.ColorCodings.find((colorCoding) => colorCoding.colorCodingKey === colorCodingKey);

      let item = Object.assign({}, this.state.Item);
      item.colorCoding = colorCoding;
      this.setState({ Item: item });
      this.props.OnChange(item);
    }
  };

  Raise = (item) => {
    const noValidColorCoding = !this.state.ColorCodings.some((color) => color.colorCodingKey === item.colorCoding.colorCodingKey);
    if (noValidColorCoding && this.state.ColorCodings.length) {
      item.colorCoding = this.state.ColorCodings[0];
    }
    this.setState({ IsRaised: true, Item: item });
  };

  Collapse = () => {
    this.setState({ IsRaised: false, Item: {}, HasChanges: false });
  };

  RiskNameOnChange = (newValue) => {
    let item = this.state.Item;
    item.name = newValue;
    this.setState({ Item: item }, () => {
      this.props.OnChange(this.state.Item);
    });
    this.CurrentComponentChanged();
  };

  RiskDescriptionOnChange = (newValue) => {
    let item = this.state.Item;
    item.description = newValue;
    this.setState({ Item: item }, () => {
      this.props.OnChange(this.state.Item);
    });
    this.CurrentComponentChanged();
  };

  render = () => {
    return (
      this.state.IsRaised && (
        <>
          <div className="CustomRiskLayerFormEdit_Node">
            <div className="CustomRiskLayerFormEdit_Header">
              <label className="CustomRiskLayerFormEdit_HeaderLabel">Risk layer</label>
            </div>
            <div className="CustomRiskLayerFormEdit_Body">
              <TextField className="CustomRiskLayerFormEdit_TxtRiskName" disableClearButton={false} helperText="" label="Risk layer name" maxLength={40} onChange={this.RiskNameOnChange} placeholder="Enter Text" required={true} value={this.state.Item.name} />
              <TextArea className="CustomRiskLayerFormEdit_TxtRiskDescription" disableValidationIcon={true} helperText="" label="Risk layer description" maxLength={120} onChange={this.RiskDescriptionOnChange} placeholder="Enter Text" required={true} value={this.state.Item.description} />
              <div className="CustomRiskLayerFormEdit_ColorCodingBar">
                <div className="CustomRiskLayerFormEdit_ColorCodingLabelContainer">
                  <label className="CustomRiskLayerFormEdit_ColorCodingLabel">Color*</label>
                </div>
                {this.state.ColorCodings.map((colorCoding) => (
                  <div className="CustomRiskLayerFormEdit_ColorCodingButton" style={{ backgroundColor: colorCoding.colorValue }} onClick={() => this.ColorCodingOnClick(colorCoding.colorCodingKey)}>
                    {this.state.Item.colorCoding.colorCodingKey === colorCoding.colorCodingKey && <Check className="CustomRiskLayerFormEdit_CheckIcon"></Check>}
                  </div>
                ))}
              </div>
            </div>
            <div className="CustomRiskLayerFormEdit_Footer">
              <div className="CustomRiskLayerFormEdit_FooterButtonBar">
                <Button
                  onClick={() => {
                    this.props.OnCancel(this.state.HasChanges);
                  }}
                  className="CustomRiskLayerFormEdit_FooterButton"
                  variant={ButtonVariant.Page}
                >
                  Close
                </Button>
                <Button onClick={this.props.OnDelete} className="CustomRiskLayerFormEdit_FooterButton" variant={ButtonVariant.Page}>
                  Delete
                </Button>
                <Button onClick={() => DownloadRiskLayerJson([this.state.Item])} className="CustomRiskLayerFormEdit_FooterButton" variant={ButtonVariant.Page}>
                  Download
                </Button>
                <Button onClick={this.props.OnSave} className="CustomRiskLayerFormEdit_FooterButton" variant={ButtonVariant.PageHighlight}>
                  Save
                </Button>
              </div>
            </div>
          </div>
          <style jsx>
            {`
              .CustomRiskLayerFormEdit_DownloadIcon {
                margin-right: 10px;
                width: 18px;
                height: 20px;
              }
              .CustomRiskLayerFormEdit_CheckIcon {
                width: 18px;
                height: 18px;
                color: white;
                left: 48%;
                top: 55%;
                position: relative;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
              }
              .CustomRiskLayerFormEdit_ColorCodingLabelContainer {
                margin-bottom: 10px;
              }
              .CustomRiskLayerFormEdit_ColorCodingLabel {
                font-size: 12px;
                font-weight: bold;
              }
              .CustomRiskLayerFormEdit_ColorCodingButton {
                width: 20px;
                height: 20px;
                border-radius: 10px;
                margin-right: 20px;
                float: left;
              }
              .CustomRiskLayerFormEdit_ColorCodingButton:hover {
                border: 1px solid ${Color.Blue1};
              }
              .CustomRiskLayerFormEdit_ColorCodingBar {
                position: relative;
                width: 90%;
                height: 60px;
                left: 50%;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                top: 15px;
              }
              .CustomRiskLayerFormEdit_TxtRiskDescription {
                width: 90%;
                left: 50%;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                top: 20px;
              }
              .CustomRiskLayerFormEdit_TxtRiskName {
                width: 90%;
                left: 50%;
                transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                top: 20px;
              }
              .CustomRiskLayerFormEdit_FooterButton {
                margin-left: 20px;
              }
              .CustomRiskLayerFormEdit_FooterButtonBar {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);
              }
              .CustomRiskLayerFormEdit_Body {
                height: 237px;
                width: 100%;
              }
              .CustomRiskLayerFormEdit_Footer {
                height: 64px;
                width: 100%;
                border-top: 1px solid ${Color.Grey3};
                line-height: 64px;
                bottom: 0;
                position: absolute;
              }
              .CustomRiskLayerFormEdit_Header {
                height: 64px;
                width: 100%;
                border-bottom: 1px solid ${Color.Grey3};
                line-height: 64px;
              }
              .CustomRiskLayerFormEdit_HeaderLabel {
                font-size: 14px;
                font-weight: bold;
                margin-left: 25px;
              }
              .CustomRiskLayerFormEdit_Node {
                width: 600px;
                height: 400px;
                border-radius: 5px;
                background-color: white;
                position: absolute;
                top: 92px;
                left: 40px;
                z-index: 50;
                box-shadow: 0px 0px 2px ${Color.Grey4};
              }
            `}
          </style>
        </>
      )
    );
  };
}
export default CustomRiskLayerFormEdit;
