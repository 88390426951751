import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";

class SurveyService {
  static GetAllSurveys = () => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/survey", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
      }).then((response) => response.json());
    });
  };

  static SaveSurvey = (survey) => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/survey", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
        body: JSON.stringify(survey),
      }).then((response) => response.json());
    });
  };

  static UpdateSurvey = (survey) => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/survey", {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
        body: JSON.stringify(survey),
      }).then((response) => response.json());
    });
  };

  static DeleteSurvey = (surveyId) => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + `/api/survey/${surveyId}`, {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
      }).then((response) => {
        if (!response.ok) {
          return false;
        }
        return true;
      });
    });
  };
}
export default SurveyService;
