import RiskManagementKeycloak from "../libraries/RiskManagementKeycloak";

class QuestionnaireService {
  static GetAllQuestionnaires = () => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/questionnaire", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
      }).then((response) => response.json());
    });
  };

  static SaveQuestionnaire = (questionnaire) => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/questionnaire", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
        body: JSON.stringify(questionnaire),
      }).then((response) => response.json());
    });
  };

  static UpdateQuestionnaire = (questionnaire) => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + "/api/questionnaire", {
        method: "PUT",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
        body: JSON.stringify(questionnaire),
      }).then((response) => response.json());
    });
  };
  
  static DeleteQuestionnaire = (questionnaireId) => {
    return RiskManagementKeycloak.RefreshToken().then(() => {
      return fetch(process.env.REACT_APP_SERVER_URL + `/api/questionnaire/${questionnaireId}`, {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Keycloak_Token: RiskManagementKeycloak.Access_Token,
          Keycloak_UserID: RiskManagementKeycloak.User_Id,
          User_Roles: RiskManagementKeycloak.UserRoles,
          Spin_ID: RiskManagementKeycloak.Spin_Id,
          Sma_ID: RiskManagementKeycloak.Sma_Id,
          Authorization: RiskManagementKeycloak.Access_Token,
        },
      }).then((response) => {
        if (!response.ok) {
          return false;
        }
        return true;
      });
    });
  };
}
export default QuestionnaireService;
